const getDateTimeFromValue = require("./getDateTimeFromValue");

function extractDatesLabel(dates) {
  let dates_label = "";
  if (dates && dates.length > 0) {
    let start_date = getDateTimeFromValue(dates[0]);
    let end_date = dates[1] ? getDateTimeFromValue(dates[1]) : null;
    if (!end_date) {
      dates_label = start_date.toFormat("D");
    } else {
      if (start_date.toFormat("D") === end_date.toFormat("D")) {
        dates_label = `${start_date.toFormat("DDDD t")} - ${end_date.toFormat("t")}`;
      } else if (start_date.toFormat("y") === end_date.toFormat("y")) {
        dates_label = `${start_date.toFormat("cccc, LLLL d t")} - ${end_date.toFormat(
          "cccc, LLLL d t, y"
        )}`;
      } else {
        dates_label = `${start_date.toFormat("cccc, LLLL d t, y")} - ${end_date.toFormat(
          "cccc, LLLL d t, y"
        )}`;
      }
    }
  }
  return dates_label;
}
export default extractDatesLabel;
