import React from "react";
import {
  Button,
  CircularProgress,
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormHelperText,
} from "@material-ui/core";
import {
  AuthContext,
  fetchAuthenticatedContent,
} from "@parallelpublicworks/understory-auth";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { SingleContext } from "../../layouts/SingleBody";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Alert from "@material-ui/lab/Alert";
import ComputerIcon from "@material-ui/icons/Computer";
import { SiteContext } from "../../layouts/Main";
import { handleEmail } from "../../utils";

const useStyles = makeStyles((theme) => ({
  form: {
    textAlign: "center",
  },
  button: {
    width: "100%",
  },
  input: {
    marginBottom: theme.spacing(2),
  },
  alert: {
    display: "block",
  },
  deleteBtn: {
    width: "auto",
    marginTop: theme.spacing(2),
    marginLeft: "auto",
    marginRight: "auto",
  },
  formControl: {
    textAlign: "left",
    "& legend": {
      marginBottom: theme.spacing(1),
    },
    "& p": {
      marginBottom: theme.spacing(1),
    },
    "& > div": {
      marginBottom: theme.spacing(1),
    },
  },
}));

function MediaUpload({ title, description }) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const [, dispatch] = React.useContext(AuthContext);
  const [error, setError] = React.useState(null);
  const [msg, setMsg] = React.useState(null);
  const [accessGroup, setAccessGroup] = React.useState([]);

  const fileInput = React.useRef();
  const { singleId, singleType, singleLeadEmail, singleTitle, singleSlug } =
    React.useContext(SingleContext);
  const { userData, rolesData, adminEmail, drupalUrl, siteName, siteUrl, accentColor } =
    React.useContext(SiteContext);

  const field_key_name =
    singleType && singleType.includes("--") ? singleType.split("--")[1] : null;

  function onAttachmentChange(e) {
    setError(null);
    if (e.target.files && e.target.files[0]) {
      if (e.target.files[0].size > 20971520) {
        setMsg("File exceeds 20MB");
        setError(true);
        if (fileInput.current) {
          fileInput.current.value = null;
        }
      } else {
        let blob = window.URL.createObjectURL(e.target.files[0]);
        setFile({
          blob: blob,
          name: e.target.files[0].name,
          filemime: e.target.files[0].type,
        });
      }
    } else if (fileInput.current) {
      setMsg("Invalid file");
      setError(true);
      fileInput.current.value = null;
    }
  }

  async function onSubmit(e) {
    e.preventDefault();
    setLoading(true);
    if (file) {
      let entityData = {
        data: {
          attributes: { name: file.name, field_filemime: file.filemime },
          type: "media--private_document",
          relationships: {},
        },
      };
      entityData.data.relationships[`field_${field_key_name}`] = {
        data: [{ type: singleType, id: singleId }],
      };

      // create media entity
      try {
        let resp = await fetchAuthenticatedContent(
          dispatch,
          `media/private_document`,
          "post",
          entityData
        );

        if (resp?.data?.id && file.blob) {
          // create file data
          let filePreBlob = await fetch(file.blob);

          let fileBlob = await filePreBlob.blob();
          let fileData = new File([fileBlob], file.name);
          // set relationship

          let fileResp;
          try {
            fileResp = await fetchAuthenticatedContent(
              dispatch,
              `media/private_document/${resp.data.id}/field_media_file_1`,
              "POST",
              fileData,
              {
                Accept: "application/vnd.api+json",
                "Content-Type": "application/octet-stream",
                "Content-Disposition": `file; filename="${file.name}"`,
              }
            );
            if (!fileResp) {
              throw new Error("Could not save file on private document entity");
            } else {
              if (accessGroup.length) {
                let patchEntityData = {
                  data: {
                    id: resp.data.id,
                    type: entityData.data.type,
                    relationships: {
                      field_access_group: {
                        data: accessGroup.map((ruuid) => ({
                          type: "user_role--user_role",
                          id: ruuid,
                        })),
                      },
                    },
                  },
                };
                let patched = await fetchAuthenticatedContent(
                  dispatch,
                  `media/private_document/${resp.data.id}`,
                  "PATCH",
                  patchEntityData
                );
                if (!patched) {
                  throw new Error(
                    "Could not save access group on private document entity"
                  );
                }
              }
            }
          } catch (err) {
            console.error("COULDNT FETCH BLOB", err.message);
            let mediaDeleteResp = await fetchAuthenticatedContent(
              dispatch,
              `media/private_document/${resp.data.id}`,
              "DELETE",
              {
                "Content-Type": "application/vnd.api+json",
              }
            );
            console.warn("deleting media ref", resp.data, mediaDeleteResp);
          }

          if (!fileResp) {
            throw new Error("Failed to upload file");
          } else {
            const email_data = [];
            let single_url = `${siteUrl}${singleSlug}`;
            let file_name = file.name;
            let file_url = `${siteUrl}/download?media=${resp.data.id}`;
            let media_backend_url = `${drupalUrl}/media/${resp.data.attributes.drupal_internal__mid}/edit`;

            email_data.push(
              `<h1 style="text-align: center; color: #000000; line-height: 1.5">New upload</h1>`
            );

            email_data.push(
              `<p>A new resource <strong>${file_name}</strong> was uploaded to <a href="${single_url}">${singleTitle}</a>.</p>`
            );

            email_data.push(
              `<p>You can <a href="${file_url}">download</a>, <a href="${media_backend_url}">edit or remove</a> this resource.</p>`
            );

            const email_to = singleLeadEmail ? singleLeadEmail : adminEmail;
            await handleEmail(
              drupalUrl,
              email_to,
              `New upload at ${singleTitle}`,
              email_data,
              adminEmail,
              siteName,
              siteUrl,
              null,
              null,
              accentColor
            ).catch((err) => {
              console.error(err);
            });
            setMsg("File uploaded");
            setError(false);
          }
        } else {
          throw new Error();
        }
      } catch (error) {
        setMsg(error.message ? error.message : "Error uploading");
        setError(true);
      }
    }
    setFile(null);
    setLoading(false);
  }

  React.useEffect(() => {
    if (error !== null && fileInput.current) {
      fileInput.current.value = null;
    } else if (error === null) {
      setMsg(null);
    }
  }, [error]);

  if (!field_key_name || userData === null) return <CircularProgress />;

  if (
    userData === false ||
    (userData &&
      (!userData.relationships[`field_${field_key_name}`] ||
        userData.relationships[`field_${field_key_name}`].data))
  ) {
    let can_upload = false;
    if (
      userData &&
      userData.relationships &&
      userData.relationships[`field_${field_key_name}`] &&
      userData.relationships[`field_${field_key_name}`].data
    ) {
      const my_groups = Array.isArray(
        userData.relationships[`field_${field_key_name}`].data
      )
        ? userData.relationships[`field_${field_key_name}`].data
        : [userData.relationships[`field_${field_key_name}`].data];

      const match_group = my_groups.find((gr) => gr.id === singleId);
      can_upload = typeof match_group !== "undefined";
    }

    if (!can_upload) {
      return <></>;
    }
  }

  function handleRolesChange(event) {
    if (event.target.checked) {
      setAccessGroup([...accessGroup, event.target.name]);
    } else {
      let accss = [...accessGroup];
      let index = accss.indexOf(event.target.name);
      if (index > -1) {
        accss.splice(index, 1);
      }
      setAccessGroup(accss);
    }
  }

  return (
    <>
      <h2>{title}</h2>
      {typeof description === "string" ? (
        <div dangerouslySetInnerHTML={{ __html: description }}></div>
      ) : (
        <></>
      )}
      {error === true && (
        <span className={classes.alert}>
          <Alert severity="error">{msg ? msg : "Error uploading"}</Alert>
        </span>
      )}
      {error === false && (
        <span className={classes.alert}>
          <Alert severity="success">{msg ? msg : "File uploaded"}</Alert>
        </span>
      )}

      <form onSubmit={onSubmit} className={classes.form}>
        {file === null && (error === null || error === true) && (
          <label>
            <Button
              type="button"
              component="div"
              variant="contained"
              color="default"
              className={classes.button}
              startIcon={<ComputerIcon />}
            >
              Select File
            </Button>
            <input
              className={classes.input}
              id="media-upload-button"
              accept=".doc,.docx,.pdf,.ppt,.pptx,.xls,.xlsx,.jpg,.jpeg,.png,.gif"
              type="file"
              name="media_upload"
              onChange={onAttachmentChange}
              ref={(r) => (fileInput.current = r)}
              style={{ position: "fixed", left: "-9999em" }}
            />
          </label>
        )}
        {loading && <CircularProgress />}
        {file !== null && !loading && (
          <>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">Access Group:</FormLabel>
              <FormHelperText>
                Select the user roles that will be able to see your document. If no role
                is selected, all authenticated users will be able to see it.
              </FormHelperText>
              <FormGroup>
                {rolesData
                  .filter(
                    (r) =>
                      !r.name.toLowerCase().includes("admin") &&
                      !r.name.toLowerCase().includes("anony") &&
                      !r.name.toLowerCase().includes("auth")
                  )
                  .map((r) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={accessGroup.includes(r.id)}
                          onChange={handleRolesChange}
                          name={r.id}
                        />
                      }
                      label={r.name}
                    />
                  ))}
              </FormGroup>
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              startIcon={<CloudUploadIcon />}
              className={classes.button}
              disabled={loading}
              type="submit"
            >
              Upload File
            </Button>
          </>
        )}
        {file !== null && !loading && (
          <Button
            color="default"
            className={classes.deleteBtn}
            onClick={() => {
              setFile(null);
              setMsg(null);
            }}
          >
            Cancel
          </Button>
        )}
      </form>
    </>
  );
}

export default MediaUpload;
