import React from "react";
import Main from "./Main";
import { AuthContextProvider } from "@parallelpublicworks/understory-auth";
import Definitions from "../../../../src/layout/Definitions";

function PageWrap({ element, props }) {
  return (
    <AuthContextProvider>
      <Definitions>
        <Main {...props}>{element}</Main>
      </Definitions>
    </AuthContextProvider>
  );
}

export default PageWrap;
