function checkViewPermissions(access_group, userData) {
  if (typeof window === "undefined" || !userData) {
    return false;
  }
  if (access_group && Array.isArray(access_group) && userData) {
    if (typeof userData.roles === "undefined") return false;
    if (access_group.length === 0) return true;
    return access_group.filter((value) => userData.roles.includes(value)).length > 0;
  }
  return true;
}
export default checkViewPermissions;
