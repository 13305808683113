import React, { useRef } from "react";
import { StaticQuery, graphql } from "gatsby";
import { Login } from "../components";
import {
  fetchAuthenticatedContent,
  AuthContext,
  handleLogout,
} from "@parallelpublicworks/understory-auth";
import { flattenNodes, navDataFormat, isPrivatePath } from "../utils";
import Default from "./Default";
import Private from "./Private";
import nodesConfig from "../../../../nodesConfig.json";
import { isIframe } from "../utils";

export const SiteContext = React.createContext();

function Main({ children, location, path }) {
  const [modalURL, setModalURL] = React.useState(null);
  const [contactModalOpen, setContactModalOpen] = React.useState(false);
  const [contactModalTitle, setContactModalTitle] = React.useState("Contact Lead");
  const [userData, setUserData] = React.useState(null);
  const appBar = useRef(null);
  const authContext = React.useContext(AuthContext);
  const [{ isAuthenticated }, dispatch] = authContext;

  const is_frame = isIframe();

  React.useEffect(() => {
    (async function () {
      if (isAuthenticated) {
        const root_data = await fetchAuthenticatedContent(dispatch, "/");
        if (!root_data?.meta?.links?.me?.meta?.id) return;
        const user_data = await fetchAuthenticatedContent(
          dispatch,
          "user/user/" + root_data.meta.links.me.meta.id
        );
        if (!user_data) return;
        const roles =
          user_data.data?.relationships?.roles?.data &&
          Array.isArray(user_data.data.relationships.roles.data) &&
          user_data.data.relationships.roles.data.length > 0
            ? user_data.data.relationships.roles.data.map((r) => r.id)
            : [];
        setUserData({ ...user_data.data, roles });
      } else {
        setUserData(false);
      }
    })();
  }, [isAuthenticated, dispatch]);

  async function logout(e) {
    e.preventDefault();
    const logged_out = await handleLogout(authContext);
    if (logged_out) {
      setUserData(null);
    }
  }

  return (
    <StaticQuery
      query={graphql`
        query {
          mainMenu: allMenuItems(
            filter: { menu_name: { eq: "main" } }
            sort: { fields: weight, order: ASC }
          ) {
            edges {
              node {
                menu_name
                url
                title
                id
                weight
                childrenMenuItems {
                  title
                  url
                  id
                  weight
                  route {
                    parameters {
                      node
                    }
                  }
                }
                parent {
                  id
                }
                route {
                  parameters {
                    node
                  }
                }
              }
            }
          }
          secondaryMenu: allMenuItems(
            filter: { menu_name: { eq: "tools" } }
            sort: { fields: weight, order: ASC }
          ) {
            edges {
              node {
                menu_name
                url
                title
                id
                weight
                parent {
                  id
                }
                route {
                  parameters {
                    node
                  }
                }
              }
            }
          }
          footerMenu: allMenuItems(
            filter: { menu_name: { eq: "footer" } }
            sort: { fields: weight, order: ASC }
          ) {
            edges {
              node {
                menu_name
                url
                title
                id
                weight
                childrenMenuItems {
                  title
                  url
                  id
                  weight
                  route {
                    parameters {
                      node
                    }
                  }
                }
                parent {
                  id
                }
                route {
                  parameters {
                    node
                  }
                }
              }
            }
          }
          privateMenu: allMenuItems(
            filter: { menu_name: { eq: "account" } }
            sort: { fields: weight, order: ASC }
          ) {
            edges {
              node {
                menu_name
                url
                title
                id
                weight
                childrenMenuItems {
                  title
                  url
                  id
                  weight
                  route {
                    parameters {
                      node
                    }
                  }
                }
                parent {
                  id
                }
                route {
                  parameters {
                    node
                  }
                }
              }
            }
          }
          entity_refs: allEntityRef {
            edges {
              node {
                id
                slug
                nid
                type
              }
            }
          }
          vocabularies: allVocabularyRef {
            edges {
              node {
                id
                name
                weight
                taxonomy
                description
              }
            }
          }
          content_type_refs: allContentTypeRef {
            edges {
              node {
                id
                name
                type
              }
            }
          }
          alert_banners: allAlertBannerRef {
            edges {
              node {
                id
                name
                show_alert
                private_alert
                description
                severity
              }
            }
          }
          roles: allRoleRef {
            edges {
              node {
                id
                name
              }
            }
          }
          footer: allFootRef {
            edges {
              node {
                id
                texts {
                  value
                }
              }
            }
          }
          site {
            siteMetadata {
              title
              drupalUrl
              url
              apiBase
              privateRoute
              allowRegistration
              adminEmail
              accentColor
            }
          }
        }
      `}
      render={(data) => {
        const allNodeRefs = flattenNodes(data.entity_refs);
        const navData = navDataFormat(data.mainMenu, allNodeRefs);
        const secondaryNavData = navDataFormat(data.secondaryMenu, allNodeRefs);
        const footerNavData = navDataFormat(data.footerMenu, allNodeRefs);
        const privateNavData = navDataFormat(data.privateMenu, allNodeRefs);
        const privateRoute = data.site.siteMetadata
          ? data.site.siteMetadata.privateRoute
          : null;

        const isPrivate = isPrivatePath(location.pathname);

        const siteName = data.site.siteMetadata ? data.site.siteMetadata.title : "";
        const adminEmail = data.site.siteMetadata
          ? data.site.siteMetadata.adminEmail
          : "";
        const accentColor = data.site.siteMetadata
          ? data.site.siteMetadata.accentColor
          : "#000000";
        const allowRegistration =
          data.site.siteMetadata && data.site.siteMetadata.allowRegistration
            ? parseInt(data.site.siteMetadata.allowRegistration) !== 0
            : false;

        const drupalUrl = data.site.siteMetadata
          ? data.site.siteMetadata.drupalUrl
          : null;
        const siteUrl = data.site.siteMetadata ? data.site.siteMetadata.url : null;
        const apiBase = data.site.siteMetadata ? data.site.siteMetadata.apiBase : null;
        const allVocabularyRefs = flattenNodes(data.vocabularies, [
          "id",
          "name",
          "weight",
          "taxonomy",
          "description",
        ]);
        const allContentTypeRefs = flattenNodes(
          data.content_type_refs,
          ["id", "name", "type"],
          "object"
        );
        const alertBanners =
          data.alert_banners &&
          Array.isArray(data.alert_banners.edges) &&
          data.alert_banners.edges.length > 0
            ? data.alert_banners.edges.map(({ node }) => ({
                ...node,
              }))
            : null;

        const rolesData =
          data.roles && Array.isArray(data.roles.edges) && data.roles.edges.length > 0
            ? data.roles.edges.map(({ node }) => ({
                ...node,
              }))
            : null;

        let footerData =
          data.footer.edges &&
          data.footer.edges[0] &&
          data.footer.edges[0].node &&
          data.footer.edges[0].node.texts
            ? data.footer.edges[0].node.texts
            : null;

        return (
          <SiteContext.Provider
            value={{
              navData,
              secondaryNavData,
              privateNavData,
              footerNavData,
              location,
              path,
              modalURL,
              setModalURL,
              allNodeRefs,
              allVocabularyRefs,
              allContentTypeRefs,
              privateRoute,
              setUserData,
              userData,
              nodesConfig,
              drupalUrl,
              siteUrl,
              apiBase,
              footerData,
              siteName,
              adminEmail,
              allowRegistration,
              logout,
              accentColor,
              contactModalOpen,
              setContactModalOpen,
              contactModalTitle,
              setContactModalTitle,
              alertBanners,
              rolesData,
              appBar,
            }}
          >
            {isPrivate && (!isAuthenticated && !is_frame) && (
              <Default path={location.pathname}>
                <Login />
              </Default>
            )}
            {isPrivate && (isAuthenticated || is_frame) && <Private>{children}</Private>}
            {!isPrivate && <Default path={location.pathname}>{children}</Default>}
          </SiteContext.Provider>
        );
      }}
    />
  );
}

export default Main;
