import React from "react";
import { StaticQuery, graphql } from "gatsby";
export const DefinitionsContext = React.createContext();

function Definitions({ children }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          field_tags: allTermRef(
            filter: { type: { eq: "taxonomy_term--tags" } }
            sort: { fields: title }
          ) {
            edges {
              node {
                id
                tid
                title
                type
              }
            }
          }
          field_agency: allEntityRef(
            filter: { type: { eq: "node--agency" } }
            sort: { fields: title }
          ) {
            edges {
              node {
                id
                nid
                title
                type
              }
            }
          }
        }
      `}
      render={(data) => {
        const graphqlFieldsConfig = {
          field_tags: { kind: "taxonomy_term" },
          field_agency: { kind: "node" },
        };
        return (
          <DefinitionsContext.Provider
            value={{
              graphqlData: data,
              graphqlFieldsConfig,
            }}
          >
            {children}
          </DefinitionsContext.Provider>
        );
      }}
    />
  );
}

export default Definitions;
