async function jsonRequest(drupalUrl, endpoint, method, payload) {
  const url = `${drupalUrl}/${endpoint}`;
  const init = {
    method: method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  };
  return fetch(url, init);
}
export default jsonRequest;
