import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
const reduce = 0.96;

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    boxSizing: "border-box",
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
    "&.root-container": {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    maxWidth: theme.breakpoints.values.xl * reduce,
    [theme.breakpoints.down("lg")]: {
      maxWidth: theme.breakpoints.values.lg * reduce,
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: theme.breakpoints.values.md * reduce,
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: theme.breakpoints.values.sm * reduce,
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
    },
  },
}));

function Container({ children, className = "", root = false }) {
  const classes = useStyles();
  return (
    <div className={`${classes.container} ${className} ${root && "root-container"}`}>
      {children}
    </div>
  );
}

export default Container;
