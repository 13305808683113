module.exports = function getEntityImage(e, _entities, drupalUrl) {
  let image;
  if (
    e.relationships &&
    e.relationships.field_image &&
    e.relationships.field_image.data &&
    e.relationships.field_image.data.id
  ) {
    let file;
    if (typeof _entities[e.relationships.field_image.data.id] !== "undefined") {
      const media_rel = _entities[e.relationships.field_image.data.id];
      if (
        media_rel &&
        media_rel.relationships &&
        media_rel.relationships.field_media_image &&
        media_rel.relationships.field_media_image.data &&
        typeof _entities[media_rel.relationships.field_media_image.data.id] !==
          "undefined"
      ) {
        if (media_rel.relationships.field_media_image.data.meta) {
          image = { ...media_rel.relationships.field_media_image.data.meta };
        }
        file = _entities[media_rel.relationships.field_media_image.data.id];
      }
    }
    if (
      image &&
      file &&
      file.attributes &&
      file.attributes.uri &&
      file.attributes.uri.url
    ) {
      const src = `${drupalUrl}${file.attributes.uri.url}`;
      const alt = image.alt;
      const caption = image.caption;
      const format = file.attributes.uri.url.split(".").pop();
      image = { ...image, src, format, alt, caption };
    }
  }
  return image;
};
