import React from "react";
import { Link } from "gatsby";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { RelationChips } from "../";
import theme from "../../../theme";

const useStyles = makeStyles((theme) => ({
  card: {
    border: `${theme.palette.grey.dark} 1px solid`,
    "&.single-column": {
      display: "flex",
    },
    "&.style-no_border": {
      borderTop: "none",
      borderLeft: "none",
      borderRight: "none",
      borderRadius: 0,
      "& .MuiCardContent-root": {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
  date: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey["900"],
  },
  listItem: {
    display: "block",
    "& > *": {
      display: "block",
      "&:not(:first-child)": {
        marginTop: theme.spacing(2),
      },
    },
  },
  caption: {
    fontSize: "0.8em",
    opacity: 0.7,
    marginTop: theme.spacing(1),
  },
  heading: {
    "&.MuiTypography-h2": {
      fontSize: "130%",
    },
    "&.MuiTypography-h3": {
      fontSize: "110%",
    },
    "& a": {
      color: theme.palette.link.main,
      textDecoration: "none",
      borderBottom: "1px dotted transparent",
      "&:focus, &:active, &:hover": {
        color: theme.palette.link.hover,
        // textDecoration: "underline",
        borderBottomColor: theme.palette.primary.dark,
      },
    },
    "& small": {
      display: "block",
      fontFamily: theme.typography.fontFamily,
      fontSize: "0.76em",
      marginTop: theme.spacing(1),
    },
    "& b": {
      display: "block",
      maxWidth: theme.spacing(7),
      height: theme.spacing(0.5),
      backgroundColor: (props) => props.underline,
      borderRadius: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
  },
  media: {
    height: 0,
    paddingTop: "30%",
    "&.size-sm": {
      paddingTop: "20%",
    },
    "&.size-lg": {
      paddingTop: "50%",
    },
    "&.fit-contain": {
      backgroundSize: "contain",
    },
    "&.fit-center": {
      backgroundSize: "auto 86%",
      backgroundPosition: "center center",
    },
    "&.single-column": {
      width: "50%",
      paddingTop: "12.5%",
      "&.size-sm": {
        paddingTop: "8.35%",
      },
      "&.size-lg": {
        paddingTop: "25%",
      },
    },
  },
}));

export default function CardComponent({
  id,
  title,
  subtitle,
  image,
  imageAlt,
  imageSize = "lg",
  imageFit = "cover",
  slug,
  type,
  summary,
  tableMode,
  headingType = "h2",
  taxonomies,
  entities_rel,
  date,
  showLink,
  singleColumn,
  cardStyle,
  captions,
  isSidebar,
  color,
}) {
  const underline =
    color && theme.palette[color] && !isSidebar ? theme.palette[color].main : null;
  const classes = useStyles({
    underline,
  });
  if (image === null) image = "/default.jpg";
  function Wrapper({ children }) {
    if (tableMode) {
      return (
        <div className={`${classes.listItem} style-${cardStyle ? cardStyle : "default"}`}>
          {children}
        </div>
      );
    } else {
      return (
        <Card
          elevation={0}
          className={`${classes.card} ${singleColumn ? "single-column" : ""} style-${
            cardStyle ? cardStyle : "default"
          }`}
        >
          {children}
        </Card>
      );
    }
  }

  function ContentWrapper({ children }) {
    if (tableMode) {
      return <>{children}</>;
    } else {
      return <CardContent>{children}</CardContent>;
    }
  }

  // function ActionsWrapper({ children }) {
  //   if (tableMode) {
  //     return <>{children}</>;
  //   } else {
  //     return <CardActions>{children}</CardActions>;
  //   }
  // }

  const link_attrs = {};
  if (showLink) {
    link_attrs.component = Link;
    link_attrs.to = slug;
  } else {
    link_attrs.component = "span";
  }

  return (
    <Wrapper>
      {typeof image !== "undefined" && !tableMode && (
        <CardMedia
          {...link_attrs}
          className={`${classes.media} size-${imageSize} fit-${imageFit} ${
            singleColumn ? "single-column" : ""
          }`}
          image={typeof image === "string" ? image : image.src}
          title={
            typeof imageAlt === "string"
              ? imageAlt
              : typeof image !== "string"
              ? image.alt
              : null
          }
        />
      )}
      <ContentWrapper>
        <Typography
          gutterBottom
          variant={headingType}
          component={headingType}
          className={`${classes.heading} ${isSidebar ? "is-sidebar" : ""}`}
        >
          {showLink ? <Link to={slug}>{title}</Link> : <span>{title}</span>}
          {underline && <b></b>}
          {subtitle && <small> {subtitle}</small>}
        </Typography>
        {typeof date !== "undefined" && date && (
          <Typography
            variant="body2"
            color="textSecondary"
            component="time"
            className={classes.date}
          >
            {date}
          </Typography>
        )}
        {typeof summary !== "undefined" && (
          <Typography variant="body1" color="textSecondary" component="p">
            {summary}
          </Typography>
        )}
        <RelationChips rels={captions} />
      </ContentWrapper>
      {/* {showLink && (
        <ActionsWrapper>
          <Button size="small" color="primary" component={Link} to={slug}>
            Learn More
          </Button>
        </ActionsWrapper>
      )} */}
    </Wrapper>
  );
}
