module.exports = function getPathFromEntity(nid, entity_keys, entities) {
  let node_ref = entity_keys.find((uuid) => {
    const item = entities.find((ent) => ent.id === uuid);
    if (item && parseInt(item.attributes.drupal_internal__nid) === parseInt(nid)) {
      return true;
    } else {
      return false;
    }
  });
  if (node_ref) {
    const item = entities.find((ent) => ent.id === node_ref);
    return item ? item.slug : null;
  } else {
    return null;
  }
};
