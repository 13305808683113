const { DateTime } = require("luxon");

module.exports = function getDateTimeFromValue(value) {
  let value_length = value.toString().length
  let value_iso = value_length <= 12 ? // UTC format has 25 digits,Epoch Unix has 10
  (new Date(value * 1000)).toISOString()
  : value
  if (!value) return DateTime.now();
  return typeof value === "string" && value.includes("-")
    ? DateTime.fromISO(value)
    : DateTime.fromISO(value_iso)
};
