import React from "react";
import { Typography } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
const useStyles = makeStyles((theme) => ({
  pills: {
    "&.style-pills": {
      "& > *": {
        margin: theme.spacing(0, 1, 1, 0),
      },
    },
  },
}));

function Pills({ children, style = "pills" }) {
  const classes = useStyles();
  if (style === "pills") {
    return <div className={`${classes.pills} style-pills`}>{children}</div>;
  } else {
    return <Typography>{children}</Typography>;
  }
}

export default Pills;
