function extractBlocks(_n, block_type, entities) {
  const blocks = [];
  if (_n.type === block_type) {
    const block = { ..._n };
    blocks.push(block);
  }
  return blocks;
}

module.exports = function extractBlocksByType(relationships, block_type, entities) {
  let nodes = [];
  if (
    relationships &&
    relationships.field_section_main &&
    relationships.field_section_main.data.length > 0
  ) {
    nodes = nodes.concat(relationships.field_section_main.data);
  }
  if (
    relationships &&
    relationships.field_section_closing &&
    relationships.field_section_closing.data.length > 0
  ) {
    nodes = nodes.concat(relationships.field_section_closing.data);
  }
  if (
    relationships &&
    relationships.field_section_sidebar &&
    relationships.field_section_sidebar.data.length > 0
  ) {
    nodes = nodes.concat(relationships.field_section_sidebar.data);
  }
  let data = [];
  if (entities) {
    for (const _nref of nodes) {
      const _n = typeof entities[_nref.id] !== "undefined" ? entities[_nref.id] : null;
      if (_n) {
        if (_n.type === "node--row") {
          if (
            _n.relationships &&
            _n.relationships.field_row_columns &&
            _n.relationships.field_row_columns.data &&
            _n.relationships.field_row_columns.data.length > 0
          ) {
            for (const _cref of _n.relationships.field_row_columns.data) {
              const _c =
                typeof entities[_cref.id] !== "undefined" ? entities[_cref.id] : null;
              if (_c) {
                if (
                  _c.relationships &&
                  _c.relationships.field_column_blocks &&
                  _c.relationships.field_column_blocks.data &&
                  _c.relationships.field_column_blocks.data.length > 0
                ) {
                  for (const _bref of _c.relationships.field_column_blocks.data) {
                    const _b =
                      typeof entities[_bref.id] !== "undefined"
                        ? entities[_bref.id]
                        : null;
                    if (_b) {
                      data = data.concat(extractBlocks(_b, block_type, entities));
                    }
                  }
                }
              }
            }
          }
        } else if (_n.type === "node--sidebar_block") {
          if (
            _n.relationships &&
            _n.relationships.field_sidebar_components &&
            _n.relationships.field_sidebar_components.data &&
            _n.relationships.field_sidebar_components.data.length > 0
          ) {
            for (const _bref of _n.relationships.field_sidebar_components.data) {
              const _b =
                typeof entities[_bref.id] !== "undefined" ? entities[_bref.id] : null;
              if (_b) {
                data = data.concat(extractBlocks(_b, block_type, entities));
              }
            }
          }
        } else {
          data = data.concat(extractBlocks(_n, block_type, entities));
        }
      }
    }
  }
  return data;
};
