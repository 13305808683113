import React from "react";
import { Link, navigate } from "gatsby";
import { Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import PersonIcon from "@material-ui/icons/Person";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import { SiteContext } from "../../../layouts/Main";

function NavButtons({
  isPrivate,
  classes,
  searchQuery,
  setSearchQuery,
  atSearchPage,
  userHandleClick,
}) {
  const { privateRoute, userData } = React.useContext(SiteContext);
  /* eslint-disable jsx-a11y/no-autofocus */
  if (isPrivate) {
    if (!userData) return <></>;
    return (
      <>
        <Button
          className={classes.userButton}
          variant="contained"
          color="default"
          size="small"
          startIcon={<PersonIcon />}
          aria-controls="user-menu"
          aria-haspopup="true"
          onClick={userHandleClick}
        >
          <span className="name">{userData.attributes.display_name}</span>
        </Button>
      </>
    );
  } else {
    return (
      <div className={classes.privateActions}>
        {searchQuery === null ? (
          <>
            {privateRoute && privateRoute !== "" && (
              <IconButton aria-label="login" component={Link} to={privateRoute}>
                <PersonIcon />
              </IconButton>
            )}
            {!atSearchPage && (
              <IconButton aria-label="search" onClick={() => setSearchQuery("")}>
                <SearchIcon />
              </IconButton>
            )}
          </>
        ) : !atSearchPage ? (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (searchQuery.length > 0) {
                navigate(`/search?q=${searchQuery}&size=n_1000_n`);
              }
              setSearchQuery(null);
            }}
          >
            <FormControl variant="filled" className={classes.searchField}>
              <Input
                placeholder="Search..."
                type="text"
                name="q"
                autoFocus
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onBlur={(e) => {
                  if (searchQuery.length === 0) {
                    setSearchQuery(null);
                  }
                }}
                endAdornment={
                  <InputAdornment position="end">
                    {searchQuery.length > 0 && (
                      <IconButton
                        aria-label="clear search query"
                        onClick={(e) => setSearchQuery(null)}
                      >
                        <CloseIcon />
                      </IconButton>
                    )}
                    <IconButton aria-label="search" type="submit">
                      {searchQuery.length === 0 ? <CloseIcon /> : <SearchIcon />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </form>
        ) : (
          <></>
        )}
      </div>
    );
  }
  /* eslint-enable jsx-a11y/no-autofocus */
}

export default NavButtons;
