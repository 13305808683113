import React from "react";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import makeStyles from "@material-ui/core/styles/makeStyles";
const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    zIndex: 20,
  },
  content: {
    padding: 0,
    background: "transparent !important",
  },
  embed: {
    display: "block",
    width: 1100,
    [theme.breakpoints.down("lg")]: {
      width: 960,
    },
    [theme.breakpoints.down("md")]: {
      width: 720,
    },
    [theme.breakpoints.down("sm")]: {
      width: 540,
    },
    [theme.breakpoints.down("xs")]: {
      width: 360,
    },
    "&.type-video": {
      paddingTop: "56.5%",
      position: "relative",
      zIndex: 19,
      "& iframe": {
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        left: 0,
      },
    },
    "&.type-image": {
      "& img": {
        width: "100%",
        height: "auto",
        display: "block",
      },
    },
  },
}));

// https://gist.github.com/yangshun/9892961
function parseVideo(url) {
  if (!url) {
    return {
      type: null,
      id: null,
    };
  }
  url.match(
    /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(&\S+)?/
  );
  let type = null;
  if (RegExp.$3.indexOf("youtu") > -1) {
    type = "youtube";
  } else if (RegExp.$3.indexOf("vimeo") > -1) {
    type = "vimeo";
  }
  return {
    type,
    id: RegExp.$6,
  };
}

function isImageURL(url) {
  return typeof url === "string" ? url.match(/\.(jpeg|jpg|gif|png)$/) != null : false;
}

function getVideoEmbed(id, type) {
  if (type === "youtube") {
    return `<iframe width="640" height="424" src="https://www.youtube.com/embed/${id}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
  } else if (type === "vimeo") {
    return `<iframe src="https://player.vimeo.com/video/${id}?h=c21f3d7c80&title=0&byline=0&portrait=0" width="640" height="424" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>`;
  } else {
    return null;
  }
}

export default function Modal({ modalURL, setModalURL }) {
  const classes = useStyles();

  const [modalOpen, setModalOpen] = React.useState(modalURL !== null);
  const [modalEmbed, setModalEmbed] = React.useState(null);
  const [embedType, setEmbedType] = React.useState(null);

  React.useEffect(() => {
    if (modalOpen === true) {
      const is_image = isImageURL(modalURL);
      if (is_image) {
        setModalEmbed(`<img src="${modalURL}" />`);
        setEmbedType("image");
      } else {
        const video_info = parseVideo(modalURL);
        const video_embed = video_info.id
          ? getVideoEmbed(video_info.id, video_info.type)
          : null;
        setModalEmbed(video_embed);
        if (video_embed) {
          setEmbedType("video");
        } else {
          setEmbedType(null);
        }
      }
    } else {
      setModalEmbed(null);
      setEmbedType(null);
    }
  }, [modalURL, modalOpen]);

  React.useEffect(() => {
    setModalOpen(modalURL !== null);
  }, [modalURL]);

  const handleClose = () => {
    setModalURL(null);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="modal-dialog-title"
      open={modalOpen}
      maxWidth="lg"
    >
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
      <MuiDialogContent className={classes.content}>
        <div
          className={`${classes.embed} type-${embedType}`}
          dangerouslySetInnerHTML={{ __html: modalEmbed }}
        ></div>
      </MuiDialogContent>
    </Dialog>
  );
}
