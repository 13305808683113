import React, { useState } from "react";
import { Paper } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  image: {
    display: "block",
    width: "100%",
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
  },
  paper: {
    display: "block",
    paddingTop: "55%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    WebkitBackgroundSize: "cover",
    "&.portrait": {
      paddingTop: "136%",
    },
    "&.square": {
      paddingTop: "100%",
    },
    "&.full": {
      paddingTop: "0%",
      "& img": {
        display: "block",
        width: "100%",
        height: "auto",
      },
    },
    "& img": {
      display: "none",
    },
    "&:after": {
      display: "none !important",
    },
  },
  caption: {
    display: "block",
    fontSize: "0.86em",
    textAlign: "left",
    opacity: 0.6,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function ImageComponent({ src, alt, orientation = "landscape", href, caption, onClick }) {
  const classes = useStyles();
  const [elevation, setElevation] = useState(href ? 1 : 0);

  let attrs = {};
  if (href) {
    attrs = {
      href: href,
      target: "_blank",
      rel: "noreferrer",
      component: "a",
      onFocus: (e) => setElevation(10),
      onBlur: (e) => setElevation(10),
      onMouseEnter: (e) => setElevation(10),
      onMouseLeave: (e) => setElevation(1),
    };
  }
  if (onClick) {
    attrs.onClick = function (e) {
      e.preventDefault();
      onClick(e);
    };
  }

  return (
    <figure className={`${classes.image} image`}>
      <Paper
        {...attrs}
        elevation={elevation}
        className={`${classes.paper} ${orientation} image-block`}
        style={{ backgroundImage: `url(${src})` }}
      >
        <img src={src} alt={alt} />
      </Paper>
      {caption && <figcaption className={classes.caption}>{caption}</figcaption>}
    </figure>
  );
}

export default ImageComponent;
