import React from "react";
import { DateTime } from "luxon";
import { ListEntities, Card, VCard, Row } from "../../components";
import { SiteContext } from "../Main";
import {
  extractLinkData,
  checkViewPermissions,
  extractDatesLabel,
  getDateTimeFromValue,
} from "../../utils";
import { SingleContext } from "../SingleBody";

function extractRelData(entities, prop_key) {
  let rel_data = [];
  let rel_data_obj = {};
  for (const entity of entities) {
    if (entity[prop_key]) {
      for (let [type, { label, terms, nodes }] of Object.entries(entity[prop_key])) {
        const rel_type = typeof terms !== "undefined" ? "terms" : "nodes";
        if (typeof rel_data_obj[type] === "undefined") {
          rel_data_obj[type] = { type, label };
          rel_data_obj[type].items =
            rel_type === "terms"
              ? terms.map((itm) => ({ id: itm.id, title: itm.title, type: itm.type }))
              : nodes.map((itm) => ({ id: itm.id, title: itm.title, type: itm.type }));
        } else {
          rel_data_obj[type].items =
            rel_type === "terms"
              ? [
                  ...rel_data_obj[type].items,

                  ...terms.map((itm) => ({
                    id: itm.id,
                    title: itm.title,
                    type: itm.type,
                  })),
                ]
              : [
                  ...rel_data_obj[type].items,

                  ...nodes.map((itm) => ({
                    id: itm.id,
                    title: itm.title,
                    type: itm.type,
                  })),
                ];
        }
      }
    }
  }
  for (let [type, data] of Object.entries(rel_data_obj)) {
    const unique_uuids = Array.isArray(data.items)
      ? [
          ...new Set(
            data.items.filter((itm) => typeof itm.title === "string").map((itm) => itm.id)
          ),
        ]
      : null;
    rel_data_obj[type].items = Array.isArray(data.items)
      ? unique_uuids.map((uuid) => data.items.find((itm) => itm.id === uuid))
      : null;
  }
  for (const type of Object.keys(rel_data_obj)) {
    rel_data.push(rel_data_obj[type]);
  }
  return rel_data;
}

function BlockList({
  type,
  relationships,
  attributes,
  listedEntities,
  fullWidth,
  isSidebar,
}) {
  const { allContentTypeRefs, userData, nodesConfig } = React.useContext(SiteContext);
  const { mainRelationships } = React.useContext(SingleContext);
  if (!relationships || !listedEntities) return <></>;

  const mode =
    attributes && attributes.field_entities_list_mode
      ? attributes.field_entities_list_mode
      : "grid";

  const tableMode = mode === "list";
  const showLink =
    attributes && attributes.field_entities_list_show_link
      ? attributes.field_entities_list_show_link
      : false;
  const hideImages =
    attributes && attributes.field_entities_list_hide_images
      ? attributes.field_entities_list_hide_images
      : false;

  const limit =
    attributes && typeof attributes.field_entities_list_limit === "number"
      ? attributes.field_entities_list_limit
      : 0;

  const moreLink =
    attributes && attributes.field_entities_list_more_link
      ? extractLinkData(attributes.field_entities_list_more_link)
      : null;

  const paginationMode =
    attributes && attributes.field_entities_list_paging_mode
      ? attributes.field_entities_list_paging_mode
      : null;

  const sortBy =
    attributes && typeof attributes.field_entities_list_sort_by === "string"
      ? attributes.field_entities_list_sort_by
      : null;

  const sortOrder =
    attributes && typeof attributes.field_entities_list_sort_order === "string"
      ? attributes.field_entities_list_sort_order
      : "desc";

  const filterByDateType =
    attributes && typeof attributes.field_entities_list_by_date === "string"
      ? attributes.field_entities_list_by_date
      : null;

  const filterByPastRange =
    attributes && attributes.field_entities_list_exclude_old
      ? parseInt(attributes.field_entities_list_exclude_old)
      : 0;

  const showFilters =
    attributes && attributes.field_entities_list_show_filters ? true : false;

  const showPastFutureFilter =
    attributes && attributes.field_show_past_future_filter ? true : false;

  const imageSize =
    attributes && attributes.field_banner_size ? attributes.field_banner_size : "lg";

  const imageFit =
    attributes && attributes.field_banner_fit ? attributes.field_banner_fit : "cover";

  const cardStyle =
    attributes && attributes.field_card_style ? attributes.field_card_style : null;

  const main_cols =
    attributes && attributes.field_columns
      ? 12 / parseInt(attributes.field_columns)
      : null;

  let filtered;
  if (
    relationships.field_entities_list_by_specific &&
    relationships.field_entities_list_by_specific.data &&
    Array.isArray(relationships.field_entities_list_by_specific.data) &&
    relationships.field_entities_list_by_specific.data.length > 0
  ) {
    const filtered_uuids = relationships.field_entities_list_by_specific.data.map(
      (e) => e.id
    );
    filtered = filtered_uuids
      .map((uuid) => listedEntities.find((light_e) => light_e.id === uuid))
      .filter((light_e) => typeof light_e !== "undefined");
  } else if (attributes && attributes.field_entities_ulist_all) {
    if (
      relationships.field_entities_ulist_by_specific &&
      relationships.field_entities_ulist_by_specific.data &&
      Array.isArray(relationships.field_entities_ulist_by_specific.data) &&
      relationships.field_entities_ulist_by_specific.data.length > 0
    ) {
      const filtered_uuids = relationships.field_entities_ulist_by_specific.data.map(
        (e) => e.id
      );
      filtered = filtered_uuids
        .map((uuid) => listedEntities.find((light_e) => light_e.id === uuid))
        .filter((light_e) => typeof light_e !== "undefined");
      // filtered = relationships.field_entities_ulist_by_specific.data;
    } else {
      filtered = listedEntities.filter((light_e) => light_e.type === "user--user");
    }
  } else {
    if (
      relationships.field_entities_list_by_types &&
      relationships.field_entities_list_by_types.data &&
      Array.isArray(relationships.field_entities_list_by_types.data) &&
      relationships.field_entities_list_by_types.data.length > 0
    ) {
      const my_filter = {};
      const ctypes = relationships.field_entities_list_by_types.data
        .map((ctype_rel) => allContentTypeRefs[ctype_rel.id])
        .filter((ctype) => typeof ctype !== "undefined");
      if (ctypes && ctypes.length > 0) {
        my_filter.by_types = ctypes.map((ct) => ct.type);
      }
      filtered = listedEntities.filter((light_e) => {
        let matches = false;
        if (my_filter.by_types) {
          matches = my_filter.by_types.includes(light_e.type);
        }
        return matches;
      });
    } else if (
      relationships.field_content_type &&
      relationships.field_content_type.data
    ) {
      const ctype = relationships.field_content_type.data;
      const rel_field_name = `field_${ctype.split("node--")[1]}`;
      if (
        typeof mainRelationships[rel_field_name] !== "undefined" &&
        mainRelationships[rel_field_name].data
      ) {
        const rel_data = Array.isArray(mainRelationships[rel_field_name].data)
          ? mainRelationships[rel_field_name].data.map((r) => r.id)
          : [mainRelationships[rel_field_name].data.id];
        filtered = listedEntities.filter((light_e) => {
          return rel_data.includes(light_e.id);
        });
      }
    }
  }

  if (filtered) {
    //pick entities based on date config options
    // -- duplicated logic from gatsby-node
    if (filterByDateType || filterByPastRange) {
      filtered = filtered.filter((entity) => {
        if (entity.date_iso) {
          let is_valid = false;
          const now = DateTime.now();
          const months = filterByPastRange ? filterByPastRange : 0;
          const past_range = months ? now.minus({ months }) : null;
          const e_time = getDateTimeFromValue(entity.date_iso);
          if (filterByDateType) {
            is_valid = filterByDateType === "future" ? now < e_time : now > e_time;
          }
          if (past_range) {
            is_valid =
              e_time > past_range &&
              ((filterByDateType === "past" && now > e_time) || !filterByDateType);
          }
          return is_valid;
        } else {
          return false;
        }
      });
    }

    filtered = filtered.filter((entity) => {
      return entity.access_group
        ? checkViewPermissions(entity.access_group, userData)
        : true;
    });
  }

  function Wrapper({ children }) {
    if (fullWidth) {
      return <Row fullWidth={true}>{children}</Row>;
    } else {
      return <>{children}</>;
    }
  }

  function CardWrapper(props) {
    const config = nodesConfig.find((cn) => cn.type === props.type);
    const format =
      config && config.options?.excerpt?.format ? config.options.excerpt.format : "card";
    if (format === "vcard") {
      const type =
        config && config.options?.excerpt?.type ? config.options.excerpt.type : "user";
      return <VCard {...props} type={type} picture={props.image} />;
    } else {
      return <Card {...props} />;
    }
  }

  if (!filtered) return <></>;

  let terms;
  let nodes;
  if (showFilters) {
    terms = extractRelData(filtered, "terms");
    nodes = extractRelData(filtered, "nodes");
  }
  if (sortBy) {
    switch (sortBy) {
      case "alphabetically":
        filtered = filtered.sort(function (a, b) {
          const a_title = a.sortField ? a.sortField : a.title;
          const b_title = b.sortField ? b.sortField : b.title;
          const A = a_title.toUpperCase();
          const B = b_title.toUpperCase();
          if (sortOrder === "asc") {
            return A < B ? -1 : A > B ? 1 : 0;
          } else {
            return A > B ? -1 : A < B ? 1 : 0;
          }
        });
        break;
      default:
        if (filterByDateType) {
          filtered = filtered.sort((a, b) => {
            const a_sort_by = a[sortBy] ? a[sortBy] : a.date_iso;
            const b_sort_by = b[sortBy] ? b[sortBy] : b.date_iso;
            if (sortOrder === "asc") {
              return getDateTimeFromValue(a_sort_by) > getDateTimeFromValue(b_sort_by)
                ? 1
                : -1;
            } else {
              return getDateTimeFromValue(a_sort_by) < getDateTimeFromValue(b_sort_by)
                ? 1
                : -1;
            }
          });
        } else {
          const now = DateTime.now();
          const filtered_future = filtered
            .filter((a) => {
              const a_sort_by = a[sortBy] ? a[sortBy] : a.date_iso;
              return getDateTimeFromValue(a_sort_by) > now;
            })
            .sort((a, b) => {
              const a_sort_by = a[sortBy] ? a[sortBy] : a.date_iso;
              const b_sort_by = b[sortBy] ? b[sortBy] : b.date_iso;
              return getDateTimeFromValue(a_sort_by) > getDateTimeFromValue(b_sort_by)
                ? 1
                : -1;
            });
          const filtered_past = filtered
            .filter((a) => {
              const a_sort_by = a[sortBy] ? a[sortBy] : a.date_iso;
              return getDateTimeFromValue(a_sort_by) < now;
            })
            .sort((a, b) => {
              const a_sort_by = a[sortBy] ? a[sortBy] : a.date_iso;
              const b_sort_by = b[sortBy] ? b[sortBy] : b.date_iso;
              return getDateTimeFromValue(a_sort_by) < getDateTimeFromValue(b_sort_by)
                ? 1
                : -1;
            });

          filtered = filtered_future.concat(filtered_past);
        }
        break;
    }
  }
  filtered = filtered.sort((a, b) => {
    if (a.sticky && !b.sticky) {
      return -1;
    } else if (b.sticky && !a.sticky) {
      return 1;
    }
    return 0;
  });

  let imageAttrs = {};

  return (
    <Wrapper>
      <ListEntities
        tableMode={tableMode}
        filters={showFilters}
        dateFilter={showPastFutureFilter}
        limit={limit}
        paginationMode={paginationMode}
        moreLink={moreLink}
        main_cols={main_cols}
        terms={terms}
        nodes={nodes}
      >
        {filtered.map((entity) => {
          const config = nodesConfig.find((cn) => cn.type === entity.type);
          if (!hideImages) {
            imageAttrs.image = entity.image ? entity.image : null;
            imageAttrs.imageSize = imageSize ? imageSize : "lg";
            imageAttrs.imageFit = imageFit ? imageFit : "cover";
          }
          const dates_label = config.showDate ? extractDatesLabel(entity.dates) : "";

          return (
            <CardWrapper
              {...entity}
              tableMode={tableMode}
              showLink={showLink}
              key={entity.id}
              {...imageAttrs}
              date={dates_label.length > 0 ? dates_label : null}
              singleColumn={filtered.length === 1 && !showFilters}
              cardStyle={cardStyle}
              isSidebar={isSidebar}
              headingType={isSidebar ? "h3" : "h2"}
            />
          );
        })}
      </ListEntities>
    </Wrapper>
  );
}

export default BlockList;
