import React from "react";
import { Link } from "gatsby";

import { grey } from "@material-ui/core/colors";
import AppBar from "@material-ui/core/AppBar";
import { Container } from "../../";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Typography from "@material-ui/core/Typography";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import NavDesktop from "./NavDesktop";
import NavMobile from "./NavMobile";
import NavButtons from "./NavButtons";
import { slugify } from "../../../utils";
import { Hidden } from "@material-ui/core";
import { SiteContext } from "../../../layouts/Main";
import makeStyles from "@material-ui/core/styles/makeStyles";
import theme from "../../../../theme";

const drawerWidth = 280;
const headerOffset = 160;

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  brand: {
    flex: 1,
    position: "relative",
    zIndex: 4,
    "& a": {
      color: "inherit",
      textDecoration: "none",
      display: "block",
      position: "relative",
      paddingTop: theme.spacing(1.2),
      paddingBottom: theme.spacing(1.5),
      height: 96,
      [theme.breakpoints.down("md")]: {
        height: 70,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        textAlign: "center",
      },
      "& img": {
        height: "100%",
        width: "auto",
        [theme.breakpoints.down("md")]: {
          transform: "translateX(-1rem)",
        },
      },
    },
  },
  toolbar: {
    padding: 0,
  },
  toolbarContainer: {
    display: "flex !important",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: 110,
    [theme.breakpoints.down("md")]: {
      minHeight: 70,
    },
    "& > .tool": {
      minWidth: 100,
      "& .MuiIconButton-label": {
        color: "initial",
      },
      "&.r": {
        marginRight: -16,
      },
    },
  },
  toolbarRight: {
    display: "block",
    "& .MuiList-root": {
      "& + .MuiList-root": {
        paddingTop: 0,
      },
    },
  },
  searchField: {
    width: 220,
    [theme.breakpoints.down("sm")]: {
      width: 100,
    },
    "& .MuiInputAdornment-root": {
      marginRight: -5,
      "& .MuiButtonBase-root": {
        marginRight: -10,
      },
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: (props) => `${props.appBar.foreground} !important`,
    },
    "& .MuiInputBase-input": {
      color: (props) => props.appBar.foreground,
      "&::placeholder": {
        color: (props) => props.appBar.foreground,
      },
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 101,
    backgroundColor: (props) => props.appBar.background,
    background: (props) =>
      props.appBar.gradient ? props.appBar.gradient : props.appBar.background,
    height: 110,
    "& .MuiSvgIcon-root": {
      color: (props) => props.appBar.foreground,
    },
    "& .MuiButton-contained": {
      "& .MuiSvgIcon-root": {
        color: (props) => props.appBar.background,
      },
    },
    [theme.breakpoints.down("md")]: {
      height: 70,
    },
    "&.isPrivate": {
      backgroundColor: (props) => props.appBarPrivate.background,
      background: (props) =>
        props.appBarPrivate.gradient
          ? props.appBarPrivate.gradient
          : props.appBarPrivate.background,
      "& .MuiSvgIcon-root": {
        color: (props) => props.appBarPrivate.foreground,
      },
      "& .MuiButton-contained": {
        "& .MuiSvgIcon-root": {
          color: (props) => props.appBarPrivate.background,
        },
      },
    },
  },
  appBarSubmenu: {
    zIndex: theme.zIndex.drawer + 102 + " !important",
  },
  navList: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    "& > *": {
      display: "block",
      "& .MuiSvgIcon-root.expand": {
        display: "inline",
        fontSize: "1.1rem",
      },
    },
  },
  secondaryNavList: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingBottom: 0,
    marginBottom: 0,
    position: "relative",
    minHeight: 70,
    "& .sep": {
      display: "block",
      width: "150%",
      height: 1,
      backgroundImage:
        "linear-gradient(90deg, hsla(0, 0%, 100%, 0) 28%, rgba(33, 63, 101, 0.21))",
      position: "absolute",
      bottom: 0,
      right: 0,
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    "& a": {
      opacity: 0.7,
      "& .MuiListItem-root": {
        // paddingTop: 0,
        // paddingBottom: 0,
      },
      "& .MuiTypography-body1": {
        fontSize: "1em",
      },
    },
  },
  linkText: {
    textDecoration: "none",
    color: (props) => props.appBar.foreground,
    border: "none",
    background: "transparent",
    padding: 0,
    fontFamily: theme.typography.fontFamily,
    fontSize: "0.9em",
    textTransform: "uppercase",
    "&:disabled": {
      opacity: 0.5,
      filter: "grayscale(1)",
      "& .MuiButtonBase-root": {
        cursor: "wait",
      },
    },
    "&.isPrivate": {
      color: (props) => props.appBarPrivate.foreground,
    },
    "& .MuiTypography-body1": {
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  ListItem: {
    padding: 0,
  },
  ListItemText: {
    padding: 0,
    "& a": {
      textDecoration: "none",
      color: "inherit",
      display: "block",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    "&.nested a": {
      paddingLeft: theme.spacing(4),
      color: grey["600"],
    },
  },
  submenuItem: {
    padding: 0,
  },
  submenuLink: {
    textDecoration: "none",
    color: "inherit",
    display: "block",
    width: "100%",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    paddingTop: 100,
    [theme.breakpoints.down("md")]: {
      paddingTop: 70,
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  IconButton: {
    width: 70,
    height: 70,
    marginRight: -theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      width: 50,
      height: 50,
    },
  },
  privateActions: {
    marginBottom: theme.spacing(1),
    "& > *": {
      marginLeft: theme.spacing(1),
    },
  },
  userButton: {
    "& span.name": {
      display: "inline-block",
      textTransform: "none",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      [theme.breakpoints.down("sm")]: {
        maxWidth: 120,
      },
      [theme.breakpoints.down("xs")]: {
        maxWidth: 70,
      },
    },
  },
}));

const getAnchorElements = (navData) => {
  const elements = {};
  for (const item of navData) {
    if (item.children) {
      let id = slugify(item.title);
      elements[id] = null;
      elements[`drawer-submenu-${id}`] = null;
    }
  }
  return elements;
};

function Navbar({ navData, secondaryNavData, isPrivate, path, siteName }) {
  const appBarColors = {
    appBar: {
      background: theme.palette.appBar.background,
      foreground: theme.palette.appBar.foreground,
      gradient: theme.palette.appBar.gradient,
    },
    appBarPrivate: {
      background: theme.palette.appBarPrivate.background,
      foreground: theme.palette.appBarPrivate.foreground,
      gradient: theme.palette.appBarPrivate.gradient,
    },
  };

  const classes = useStyles({
    ...appBarColors,
  });
  const [anchorElements, setAnchorElements] = React.useState([]);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [noJs, setNoJs] = React.useState(true);
  const [searchQuery, setSearchQuery] = React.useState(null);
  const atSearchPage = path && path.includes("/search");

  const hasMenus = navData.length > 0 || secondaryNavData.length > 0;

  const { logout, appBar } = React.useContext(SiteContext);
  React.useEffect(() => {
    setNoJs(false);
  }, []);

  const [userAnchorEl, setUserAnchorEl] = React.useState(null);

  const userHandleClick = (event) => {
    setUserAnchorEl(event.currentTarget);
  };

  const userHandleClose = () => {
    setUserAnchorEl(null);
  };

  const handleMenuClick = (e) => {
    const elmKey = e.currentTarget.getAttribute("aria-controls");
    const isFromDrawer = elmKey.includes("drawer");
    if (isFromDrawer) {
      if (Boolean(anchorElements[elmKey])) {
        setAnchorElements(getAnchorElements(navData));
      } else {
        setAnchorElements({ ...getAnchorElements(navData), [elmKey]: e.currentTarget });
      }
    } else {
      setAnchorElements({ ...getAnchorElements(navData), [elmKey]: e.currentTarget });
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleAnchorLink = (path) => {
    if (typeof window !== "undefined") {
      let target = document.querySelector(`${path}`);
      if (target) {
        let offsetPosition = target.offsetTop - headerOffset;
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    }
  };

  const handleLinkClick = (e, path) => {
    if (path && path.charAt(0) === "#" && typeof window !== "undefined") {
      e.preventDefault();

      handleAnchorLink(path);
    }
  };

  return (
    <>
      <AppBar
        className={`${classes.appBar} ${isPrivate ? "isPrivate" : ""}`}
        ref={appBar}
      >
        <Toolbar className={classes.toolbar}>
          <Container className={classes.toolbarContainer} root>
            <Hidden lgUp>
              <div className="tool l">
                {hasMenus && (
                  <IconButton
                    edge="start"
                    className={classes.menuButton}
                    color="inherit"
                    aria-label="menu"
                    onClick={handleDrawerToggle}
                  >
                    <MenuIcon />
                  </IconButton>
                )}
              </div>
            </Hidden>

            <Typography variant="h6" color="inherit" className={classes.brand}>
              <Link to="/">
                <img src={isPrivate ? "/logo-private.png" : "/logo.png"} alt={siteName} />
              </Link>
            </Typography>

            <Hidden lgUp>
              <div className="tool r">
                <NavButtons
                  isPrivate={isPrivate}
                  classes={classes}
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  atSearchPage={atSearchPage}
                  userHandleClick={userHandleClick}
                />
              </div>
            </Hidden>
            {hasMenus ? (
              <NavDesktop
                getAnchorElements={getAnchorElements}
                setAnchorElements={setAnchorElements}
                anchorElements={anchorElements}
                handleMenuClick={(e) => handleMenuClick(e)}
                handleAnchorLink={handleAnchorLink}
                handleLinkClick={handleLinkClick}
                navData={navData}
                secondaryNavData={secondaryNavData}
                classes={classes}
                noJs={noJs}
                isPrivate={isPrivate}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                atSearchPage={atSearchPage}
                userHandleClick={userHandleClick}
              />
            ) : (
              <Hidden lgDown>
                <NavButtons
                  isPrivate={isPrivate}
                  classes={classes}
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  atSearchPage={atSearchPage}
                  userHandleClick={userHandleClick}
                />
              </Hidden>
            )}
          </Container>
        </Toolbar>
        <Menu
          id="user-menu"
          anchorEl={userAnchorEl}
          keepMounted
          open={Boolean(userAnchorEl)}
          onClose={userHandleClose}
        >
          <MenuItem onClick={userHandleClose} component={Link} to={`/user`}>
            Edit Profile
          </MenuItem>
          <MenuItem onClick={userHandleClose} component={Link} to="/password-reset">
            Change Password
          </MenuItem>
          <MenuItem
            onClick={(e) => {
              userHandleClose(e);
              logout(e);
            }}
          >
            Logout
          </MenuItem>
        </Menu>
      </AppBar>
      {hasMenus && (
        <NavMobile
          anchorElements={anchorElements}
          handleMenuClick={(e) => handleMenuClick(e)}
          mobileOpen={mobileOpen}
          setMobileOpen={setMobileOpen}
          navData={navData}
          secondaryNavData={secondaryNavData}
          classes={classes}
          handleDrawerToggle={handleDrawerToggle}
          isPrivate={isPrivate}
        />
      )}
    </>
  );
}

export default Navbar;
