import React from "react";
import { Avatar, Button, Input, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import Badge from "@material-ui/core/Badge";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
  TextField,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
} from "@material-ui/core";
import { SiteContext } from "../../layouts/Main";

const useStyles = makeStyles((theme) => ({
  button: {
    width: "100%",
  },
  media_input: {
    marginLeft: "auto",
    marginRight: "auto",
    width: `${theme.spacing(18)}px !important`,
  },
  alert: {
    display: "block",
  },
  upload_button: {
    padding: 0,
    width: theme.spacing(8),
    height: theme.spacing(8),
    borderRadius: "50%",
    "& .MuiButton-label": {
      display: "block",
      "& .MuiButton-startIcon": {
        margin: 0,
        width: theme.spacing(8),
        height: theme.spacing(8),
        display: "block",
        "& .MuiSvgIcon-root": {
          display: "block",
          height: theme.spacing(8),
          marginLeft: "auto",
          marginRight: "auto",
        },
      },
    },
  },
  form_group: {
    textAlign: "left",
    "&.dependant-field": {
      marginLeft: theme.spacing(3),
      width: `calc(100% - ${theme.spacing(3)}px)`,
    },
  },
  select: {
    // display: "none",
    "& .MuiSelect-selectMenu": {
      whiteSpace: "initial",
      textOverflow: "inherit",
    },
  },
  input: {
    marginBottom: theme.spacing(2),
  },
  file: {
    marginTop: theme.spacing(2),
    display: "block",
  },
  thumbnail: {
    width: theme.spacing(18),
    height: theme.spacing(18),
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: theme.palette.grey.light,
  },
  mediauuid: {
    "& .MuiInputBase-root": {
      position: "fixed",
      left: "-9999em",
    },
  },
  informational: {
    overflow: "hidden",
    textAlign: "left",
    marginTop: theme.spacing(1),
    "& p, & li": {
      marginTop: 0,
      color: theme.palette.grey["600"],
    },
    "& > span": {
      display: "block",
    },
    "& > h4": {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
    },
  },
}));

function Field({
  name,
  form,
  disabled,
  options,
  multiple,
  accept,
  maxSize = 20971520,
  files,
  setFiles,
  media_type,
  is_relationship,
  kind,
  id,
  req,
  type,
  depends_on,
  depends_on_val,
  multiline,
  body,
  control_type,
  ...props
}) {
  const classes = useStyles();
  const fileInput = React.useRef();
  const [thumbnail, setThumbnail] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [msg, setMsg] = React.useState(null);
  const [file, setFile] = React.useState(null);
  const { drupalUrl } = React.useContext(SiteContext);

  let value = form.values ? form.values[name] : null;
  if (multiple && !value) {
    value = [];
  }

  const helperText =
    form.touched[name] && typeof form.errors[name] !== "undefined"
      ? form.errors[name]
      : null;

  const attrs = {
    name: name,
    disabled: form.isSubmitting || disabled,
    onChange: form.handleChange,
    value,
    error: form.touched[name] && typeof form.errors[name] !== "undefined",
    className: classes.form_group,
  };

  if (!multiple) {
    attrs.helperText = helperText;
  }

  if (type === "media") {
    attrs.className += ` ${classes.mediauuid}`;
  }

  if (!!depends_on) {
    attrs.className += " dependant-field";
  }

  if (multiple) {
    attrs.className += ` ${classes.select}`;
  }

  if (multiline) {
    attrs.multiline = true;
    attrs.rows = 4;
  }

  React.useEffect(() => {
    if (type === "media" && value && value !== "missing") {
      fetch(`${drupalUrl}/jsonapi/${media_type.replace("--", "/")}/${value}`, {
        method: "GET",
        headers: { Accept: "application/vnd.api+json" },
      })
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            throw new Error("Something went wrong");
          }
        })
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.attributes &&
            response.data.attributes.drupal_internal__mid
          ) {
            setThumbnail(
              `${drupalUrl}/media/${response.data.attributes.drupal_internal__mid}`
            );
          }
        })
        .catch(function (err) {
          console.error(err.message);
        });
    }
  }, [value]);

  React.useEffect(() => {
    if (file !== null && file.blob) {
      setThumbnail(file.blob);
      let new_files = { ...files };
      new_files[name] = file;
      setFiles(new_files);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file, name]);

  function onAttachmentChange(e) {
    setError(null);
    let reset_files = { ...files };
    if (typeof reset_files[name] !== "undefined") {
      delete reset_files[name];
    }
    setFiles(reset_files);
    if (e.target.files && e.target.files[0]) {
      if (e.target.files[0].size > maxSize) {
        setMsg(`File exceeds ${maxSize / 1024 / 1024}MB`);
        setError(true);
        if (fileInput.current) {
          fileInput.current.value = null;
        }
      } else {
        let blob = window.URL.createObjectURL(e.target.files[0]);
        setFile({
          blob: blob,
          name: e.target.files[0].name,
          filemime: e.target.files[0].type,
        });
      }
    } else if (fileInput.current) {
      setMsg("Invalid file");
      setError(true);
      fileInput.current.value = null;
    }
  }

  let ops = <></>;

  if (options) {
    if (!multiple) {
      attrs.select = true;
      attrs.SelectProps = {
        native: true,
        displayEmpty: true,
      };
    }

    ops = options.map(({ id, nid, tid, val, title }) => {
      if (!val) {
        val = nid ? nid : tid;
      }
      const checked =
        form.values && Array.isArray(form.values[name])
          ? form.values[name].indexOf(val) > -1
          : false;

      return multiple ? (
        <MenuItem key={id} value={val}>
          <Checkbox checked={checked} />
          <ListItemText primary={title} />
        </MenuItem>
      ) : (
        <option key={id} value={val}>
          {title}
        </option>
      );
    });
    if (!multiple) {
      ops.unshift(
        <option key="placeholder" value="">
          {""}
        </option>
      );
    }
  }

  const informational = [];
  if (body) {
    if (body.heading) {
      informational.push(
        <Typography key={`heading-${name}`} variant="h4">
          {body.heading}
        </Typography>
      );
    }
    if (body.value) {
      informational.push(
        <span
          key={`description-${name}`}
          dangerouslySetInnerHTML={{ __html: body.value }}
        ></span>
      );
    }
  }

  if (multiple) {
    return (
      <>
        <FormControl>
          <InputLabel>{props.label}</InputLabel>
          <Select
            multiple
            input={<Input />}
            renderValue={(selected) =>
              selected
                .map((val) => {
                  const op_lbl = options.find(({ nid, tid }) => {
                    const a_val = nid ? nid : tid;
                    return val === a_val;
                  });
                  return op_lbl ? op_lbl.title : "";
                })
                .join(", ")
            }
            {...attrs}
          >
            {ops}
          </Select>
          {helperText && <FormHelperText error>{helperText}</FormHelperText>}
        </FormControl>
        {informational.length > 0 && (
          <div className={classes.informational}>{informational}</div>
        )}
      </>
    );
  } else {
    return (
      <>
        {(options || (!options && control_type !== null)) && (
          <TextField type={options ? "number" : "text"} {...props} {...attrs}>
            {ops}
          </TextField>
        )}

        {error === true && (
          <span className={classes.alert}>
            <Alert severity="error">{msg ? msg : "Error uploading"}</Alert>
          </span>
        )}
        {type === "media" && (
          <div className={classes.media_input}>
            <Badge
              overlap="circular"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              badgeContent={
                <label>
                  <input
                    className={classes.input}
                    id="media-upload-button"
                    accept={accept}
                    type="file"
                    name="media_upload"
                    onChange={onAttachmentChange}
                    ref={(r) => (fileInput.current = r)}
                    style={{ position: "fixed", left: "-9999em" }}
                  />
                  <Button
                    variant="contained"
                    color="default"
                    className={classes.upload_button}
                    startIcon={<CameraAltIcon style={{ fontSize: 30 }} />}
                    disabled={form.isSubmitting}
                    component="span"
                  ></Button>
                </label>
              }
            >
              <Avatar src={thumbnail} className={classes.thumbnail} />
            </Badge>
          </div>
        )}
        {informational.length > 0 && (
          <div className={classes.informational}>{informational}</div>
        )}
      </>
    );
  }
}

export default Field;
