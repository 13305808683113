import React from "react";
import { Link } from "gatsby";
import { Button } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
const useStyles = makeStyles((theme) => {
  return {
    block: {
      display: "block",
      textAlign: "center",
      background: ({ color }) =>
        color && color !== "primary" && color !== "secondary"
          ? theme.palette[color].main
          : null,
    },
  };
});

function MoreButton({
  to,
  toLabel = "Learn More",
  variant,
  color = "primary",
  className,
  block,
  size,
}) {
  const classes = useStyles({
    color,
  });
  const is_external = to.includes("http");
  const component = is_external ? "a" : Link;
  const attrs = { component };
  if (color === "primary" || color === "secondary") {
    attrs.color = color;
  }
  attrs.target = is_external ? "_blank" : "_self";
  if (is_external) {
    attrs.rel = "noreferrer";
  }
  if (className) attrs.className = className;
  if (variant) {
    attrs.variant = variant;
    attrs.color = color;
  }
  if (block) {
    if (typeof attrs.className === "undefined") attrs.className = "";
    attrs.className += ` ${classes.block}`;
  }
  if (is_external) {
    attrs.href = to;
  } else {
    attrs.to = to;
  }
  if (size) {
    attrs.size = size;
  }
  return <Button {...attrs}>{toLabel}</Button>;
}

export default MoreButton;
