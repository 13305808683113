const fontFamily = "Gudea, Helvetica Neue, sans-serif";
const fontWeight = 400;
const fontWeightBold = 700;
const headings = {
  fontFamily: "Oswald, Helvetica Neue, Arial, sans-serif",
  fontWeight: 500,
  lineHeight: 1.3,
};

const fonts = {
  fontURLParam: "Gudea:ital,wght@0,400;0,700;1,400&family=Oswald:wght@500",
  fontFamily,
  fontWeight,
  fontWeightBold,
  headings
}

export default fonts