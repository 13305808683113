const slugify = require("./slugify");
const getTitleFromEntity = require("./getTitleFromEntity");

// node
//   .id
//   .attributes
//      .path
//        .alias
//   .title
module.exports = function getSlugFromEntity(node, config, privateRoute) {
  const url_alias =
    config && config.slugTitleField === "id"
      ? `/${node.id}`
      : node && node.attributes && node.attributes.path && node.attributes.path.alias
      ? node.attributes.path.alias
      : null;
  if (url_alias && url_alias === "/home") return { slug: "/", title: "Home" };
  const title = getTitleFromEntity(node, config);
  const name = !url_alias ? title : null;
  const alias = url_alias ? url_alias : name ? `/${slugify(name)}` : null;
  if (!alias) return null;
  let prepend = "";
  if (node.parentEntity) {
    let prepend_data = getSlugFromEntity(
      node.parentEntity,
      node.parentEntity.config,
      privateRoute
    );
    if (prepend_data) {
      prepend = prepend_data.slug ? prepend_data.slug : "";
    }
  }
  let identifier =
    !url_alias &&
    typeof config.slugIdentifierField !== "undefined" &&
    node.attributes[config.slugIdentifierField]
      ? node.attributes[config.slugIdentifierField]
      : null;
  let slug = `${prepend}${alias}`;
  if (identifier) slug += `-${identifier}`;
  if (config.slugPrepend) {
    slug = `${config.slugPrepend}${slug}`;
  }
  if (
    privateRoute &&
    config &&
    config.slugTitleField === "id" &&
    !slug.includes(privateRoute)
  ) {
    slug = `${privateRoute}${slug}`;
  }

  return { slug, title };
};
