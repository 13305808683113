import React, { useContext } from "react";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import GridOnIcon from "@material-ui/icons/GridOn";
import CropOriginalIcon from "@material-ui/icons/CropOriginal";
import VideoLabelIcon from "@material-ui/icons/VideoLabel";
import LinkIcon from "@material-ui/icons/Link";
import EditIcon from "@material-ui/icons/Edit";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { navigate } from "gatsby";
import { SiteContext } from "../../layouts/Main";

const useStyles = makeStyles((theme) => ({
  media: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    textDecoration: "none",
    fontSize: "1.1em",
    width: "100%",
    color: theme.palette.link.main,
    "& > span": {
      display: "flex",
      alignItems: "center",
      "& .title": {
        display: "block",
        wordBreak: "break-all",
      },
    },
    "& + .media-item": {
      paddingTop: theme.spacing(1),
      marginTop: theme.spacing(1),
      borderTop: `${theme.palette.grey.dark} 1px solid`,
    },
    "& .MuiSvgIcon-root": {
      fill: theme.palette.link.hover,
    },
    "&:focus, &:hover, &:active": {
      color: theme.palette.link.hover,
      textDecoration: "underline",
      "& .MuiSvgIcon-root": {
        fill: theme.palette.link.hover,
      },
    },
  },
  icon: {
    width: 30,
    height: 35,
    marginRight: theme.spacing(1),
    fill: theme.palette.dark.main,
  },
  chip: {
    textDecoration: "none",
    cursor: "pointer",
    marginLeft: theme.spacing(1),
  },
  edit: {
    marginLeft: theme.spacing(1),
    width: theme.spacing(3),
    "& .MuiSvgIcon-root": {
      verticalAlign: "middle",
      fill: theme.palette.link.main,
    },
  },
}));

function Media({ media }) {
  const classes = useStyles();
  const { location, userData } = useContext(SiteContext);

  return media.map((item) => {
    let Icon;
    switch (item.format) {
      case "pdf":
        Icon = PictureAsPdfIcon;
        break;
      case "jpg":
      case "png":
        Icon = CropOriginalIcon;
        break;
      case "ppt":
      case "pptx":
        Icon = VideoLabelIcon;
        break;
      case "xls":
      case "xlsx":
        Icon = GridOnIcon;
        break;
      case "url":
        Icon = LinkIcon;
        break;
      default:
        Icon = DescriptionOutlinedIcon;
        break;
    }
    const attrs = {};
    if (item.url.includes("http")) {
      attrs.rel = "noreferrer";
    }
    const owner = userData && userData.id === item.author;
    const extras = item.external ? { rel: "noopener noreferrer", target: "_blank" } : {};
    return (
      <a
        key={item.id}
        href={item.url}
        className={`${classes.media} media-item`}
        {...attrs}
        onClick={(e) => {
          if (!item.external) {
            e.preventDefault();
            navigate(item.url, { state: { referrer: location.pathname } });
          }
        }}
        {...extras}
      >
        <span>
          <Icon className={`${classes.icon} format-${item.format}`} />{" "}
          <span className="title">{item.title}</span>
        </span>
        {owner && !item.external && (
          <b className={classes.edit} title="Edit">
            <EditIcon fontSize="small" />
          </b>
        )}
      </a>
    );
  });
}

export default Media;
