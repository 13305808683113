function extractVideoData(node, media_field_name, drupalUrl, media_rel = null) {
  if (drupalUrl) {
    if (!media_rel && media_field_name && node) {
      media_rel =
        node && node.relationships && node.relationships[media_field_name]
          ? node.relationships[media_field_name].data
          : null;
    }
    if (media_rel) {
      const title = media_rel.title;
      let url =
        media_rel.attributes && media_rel.attributes.field_media_oembed_video
          ? media_rel.attributes.field_media_oembed_video
          : null;

      let thumbnail =
        media_rel.relationships &&
        media_rel.relationships.thumbnail &&
        media_rel.relationships.thumbnail.data &&
        media_rel.relationships.thumbnail.data.attributes
          ? {
              ...media_rel.relationships.thumbnail.data.meta,
              ...media_rel.relationships.thumbnail.data.attributes.uri,
            }
          : null;
      if (url && thumbnail) {
        thumbnail.url = `${drupalUrl}${thumbnail.url}`;
        return {
          title,
          url,
          thumbnail,
        };
      } else {
        return null;
      }
    } else {
      return null;
    }
  } else {
    return null;
  }
}
export default extractVideoData;
