import React from "react";
import { Link } from "gatsby";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Typography } from "@material-ui/core";
import { ListContext } from "../containers/ListEntities";
import { SiteContext } from "../../layouts/Main";

const useStyles = makeStyles((theme) => ({
  chips: {
    fontSize: "0.8em",
    opacity: 0.7,
    marginTop: theme.spacing(1),
    // textAlign: "left",
    "& > div": {
      lineHeight: 1.5,
      "& > *": {
        display: "inline",
        marginRight: theme.spacing(1),
        lineHeight: 1.5,
      },
      "& > a": {
        color: theme.palette.link.main,
        lineHeight: 1.5,
        "&:hover, &:active, &:focus": {
          color: theme.palette.link.hover,
          textDecoration: "none",
        },
      },
      "& + div": {
        marginTop: theme.spacing(1),
      },
    },
  },
}));

function RelationChips({ rels }) {
  const classes = useStyles();
  const listContext = React.useContext(ListContext);
  const siteContext = React.useContext(SiteContext);
  function refreshFilters(e, slug) {
    let query_string =
      slug && typeof slug === "string" && slug.includes("?")
        ? `?${slug.split("?")[1]}`
        : null;
    if (
      query_string &&
      listContext &&
      listContext.filtersForm &&
      listContext.filtersForm.current
    ) {
      e.preventDefault();
      if (typeof window !== "undefined") {
        let rect = listContext.filtersForm.current.getBoundingClientRect();
        let appbar_h =
          siteContext && siteContext.appBar && siteContext.appBar.current
            ? siteContext.appBar.current.offsetHeight
            : 100;
        let top = rect.top + document.documentElement.scrollTop - (appbar_h + 20);
        window.scrollTo({
          top,
          behavior: "smooth",
        });
        listContext.setFiltersQueryString(query_string);
        window.history.replaceState("", "", slug);
      }
    }
  }
  if (rels) {
    return (
      <div className={classes.chips}>
        {rels.map((rel) => {
          return (
            <div key={rel.name}>
              <strong>{`${rel.label}: `}</strong>
              {rel.items.map(({ id, title, slug }, i) => {
                const rel_lbl = `${title}${i === rel.items.length - 1 ? "." : ", "}`;
                return slug ? (
                  <Link
                    key={id}
                    component={Link}
                    to={slug}
                    onClick={(e) => refreshFilters(e, slug)}
                  >
                    <Typography component="span">{rel_lbl}</Typography>
                  </Link>
                ) : (
                  <span key={id}>{rel_lbl}</span>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  }
  return <></>;
}

export default RelationChips;
