import { extractFileData, checkViewPermissions } from "./";

function extractResourcesData(mediaNodes, userData, drupalUrl) {
  if (mediaNodes && Array.isArray(mediaNodes) && mediaNodes.length > 0) {
    const external_link_field_name = "field_external_link";
    return mediaNodes
      .map((media_rel) => {
        const { id, author, attributes, relationships } = media_rel;
        if (
          attributes &&
          attributes[external_link_field_name] &&
          attributes[external_link_field_name].uri
        ) {
          let format = /\.pdf$/.test(attributes[external_link_field_name].uri)
            ? "pdf link"
            : /\.doc$/.test(attributes[external_link_field_name].uri)
            ? "wordDoc Link"
            : "External Link";
          return {
            id,
            author,
            title: attributes.name,
            url: attributes[external_link_field_name].uri,
            format,
            external: true,
          };
        } else {
          const isPrivate = media_rel.type.includes("private");
          const file_field_name = isPrivate ? "field_media_file_1" : "field_media_file";
          const file = extractFileData(
            media_rel,
            file_field_name,
            drupalUrl,
            true,
            isPrivate
          );

          if (file) {
            const access_group =
              file.isPrivate &&
              relationships.field_access_group &&
              relationships.field_access_group.data &&
              Array.isArray(relationships.field_access_group.data)
                ? relationships.field_access_group.data.map((r) => r.id)
                : [];
            if (file.isPrivate && !checkViewPermissions(access_group, userData)) {
              return null;
            }

            return {
              id,
              author,
              title: attributes.name,
              url: file.src,
              format: file.format,
            };
          } else {
            return null;
          }
        }
      })
      .filter((m) => m !== null);
  }
  return [];
}
export default extractResourcesData;
