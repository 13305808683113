import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import theme from "../../../theme";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    "& + *:not(.spacer)": {
      marginTop: 0,
    },
  },
}));

function Spacer({ amount = 6 }) {
  const classes = useStyles();
  return (
    <div
      className={`${classes.root} spacer`}
      style={{
        height: theme.spacing(amount),
      }}
    />
  );
}

export default Spacer;
