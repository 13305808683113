import React from "react";
import { navigate } from "gatsby";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Container, Text, MoreButton } from "../";
import makeStyles from "@material-ui/core/styles/makeStyles";
import theme from "../../../theme";
import { searchFieldStyles } from "../../../theme";

const useStyles = makeStyles((theme) => ({
  subtitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    display: "block",
    fontSize: "1.2em",
    lineHeight: 1.5,
  },
  logo: {
    height: "auto",
    width: "70%",
    marginBottom: theme.spacing(3),
    maxWidth: 200,
    [theme.breakpoints.up("md")]: {
      maxWidth: 250,
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: 330,
    },
  },
  cta: {
    fontSize: "1.5em",
    // display: "inline-block",
    marginTop: 20,
    color: "white",
    textTransform: "none",
    textDecoration: "underline",
  },
  inner: {
    "& > div": {
      position: "relative",
      zIndex: 2,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: 560,
      [theme.breakpoints.up("lg")]: {
        maxWidth: 640,
      },
      [theme.breakpoints.up("xl")]: {
        maxWidth: 720,
      },
      [theme.breakpoints.up("xxl")]: {
        maxWidth: 880,
      },
    },
  },
  colorFilter: {
    position: "absolute",
    display: "block",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    "&.filter-1": {
      zIndex: 1,
      mixBlendMode: "color",
      opacity: "1",
    },
    "&.filter-2": {
      zIndex: 1,
      mixBlendMode: "multiply",
      opacity: "0.7",
    },
    "&.filter-3": {
      zIndex: 1,
      mixBlendMode: "darken",
      opacity: "0.6",
    },
    "&.filter-darken": {
      background: `linear-gradient(45deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.4) 50%, rgba(0,0,0,0.2) 100%)`,
      zIndex: 1,
      opacity: 1,
    },
  },
  wrapper: {
    overflow: "hidden",
    textAlign: "center",
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(7),
      paddingBottom: theme.spacing(7),
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
    "&.fgcolor-light": {
      color: "white",
      "& a": {
        color: "white",
      },
    },
    "& + .content-row": {
      borderTop: "#e0e0e0 3px solid",
    },
    "&.rounded": {
      borderRadius: theme.spacing(1),
    },
    "& + .title-bar, & + .banner": {
      marginTop: 0,
    },
    "&:last-child": {
      marginBottom: 0,
    },
    "&.transparent": {
      "& header": {
        textShadow: `1px 1px 2px rgba(0,0,0,0.5)`,
      },
    },
    "&.full-width": {
      "& + .title-bar": {
        marginTop: theme.spacing(-4),
      },
    },
  },
  wrapperContainer: {
    "&:not(.align-center) > *": {
      textAlign: "left",
    },
  },
  aside: {
    "&:last-child": {
      marginBottom: `0 !important`,
    },
    "& + .title-bar, & + .banner": {
      marginTop: 0,
    },
  },
  ...searchFieldStyles,
}));

function CallToAction({
  id,
  title,
  logo,
  subtitle,
  backgroundColor,
  backgroundImage,
  headingType = "h2",
  headingVariant = "h2",
  body,
  to,
  toLabel,
  rounded,
  search,
  searchPlaceholder,
  searchResultsPath,
  newsletter,
  newsletterPlaceholder,
  fullWidth,
}) {
  const [searchQuery, setSearchQuery] = React.useState(null);
  const newsletterQuery = null;
  const classes = useStyles();
  let bg_image = null;
  const bg_color_hex = backgroundColor ? theme.palette[backgroundColor].main : null;
  const logo_url = logo && logo.length > 0 ? logo : null;
  if (backgroundImage) {
    bg_image = backgroundImage;
  }
  // headingVariant = headingVariant || headingType;
  const inner_content = (
    <div
      key={id}
      className={`content-row call-to-action ${
        classes.wrapper
      } fgcolor-light align-center ${fullWidth ? "full-width" : ""} ${
        rounded ? "rounded" : ""
      } ${!backgroundColor ? "transparent" : ""}`}
      style={{
        backgroundImage: `url(${bg_image})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        position: "relative",
        backgroundColor: backgroundColor
          ? theme.palette[backgroundColor].main
          : "transparent",
        backgroundBlendMode: "luminosity",
      }}
    >
      <Container className={`${classes.wrapperContainer} align-center`}>
        <div className={classes.inner}>
          <div>
            <header>
              {title && (
                <Typography
                  variant={headingType === "h1" ? "h1" : headingVariant}
                  component={headingType}
                >
                  {logo_url === null ? (
                    title
                  ) : (
                    <img src={logo_url} alt={title} className={`${classes.logo}`} />
                  )}
                </Typography>
              )}

              {subtitle && (
                <Typography
                  variant="overline"
                  component="strong"
                  className={classes.subtitle}
                >
                  {subtitle}
                </Typography>
              )}
            </header>
            {body && <Text html={body} lineHeight="sm" />}
            {to && <MoreButton to={to} toLabel={toLabel} className={classes.cta} />}
            {typeof newsletter !== "undefined" && newsletter && (
              <form
                id="form-newsletter"
                className={classes.search}
                noValidate
                autoComplete="off"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <input
                  type="text"
                  placeholder={newsletterPlaceholder}
                  className={classes.searchInput}
                  value={newsletterQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.searchButton}
                  style={{
                    backgroundColor: backgroundColor
                      ? theme.palette[backgroundColor].main
                      : "none",
                  }}
                  type="submit"
                >
                  Subscribe
                </Button>
              </form>
            )}
            {typeof search !== "undefined" && search && (
              <form
                id="form-search"
                className={classes.search}
                noValidate
                autoComplete="off"
                onSubmit={(e) => {
                  e.preventDefault();
                  if (searchQuery && searchQuery.length > 0) {
                    navigate(`${searchResultsPath}?q=${searchQuery}&size=n_1000_n`);
                  }
                }}
              >
                <input
                  type="text"
                  placeholder={searchPlaceholder}
                  className={classes.searchInput}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.searchButton}
                  type="submit"
                  style={{
                    backgroundColor: backgroundColor
                      ? theme.palette[backgroundColor].main
                      : "none",
                  }}
                >
                  Search
                </Button>
              </form>
            )}
          </div>
          {bg_color_hex ? (
            <>
              <span
                className={`${classes.colorFilter} filter-1`}
                style={{ backgroundColor: bg_color_hex }}
              />
              <span
                className={`${classes.colorFilter} filter-2`}
                style={{ backgroundColor: bg_color_hex }}
              />
              <span
                className={`${classes.colorFilter} filter-3`}
                style={{ backgroundColor: bg_color_hex }}
              />
            </>
          ) : (
            <span className={`${classes.colorFilter} filter-darken`} />
          )}
        </div>
      </Container>
    </div>
  );

  return headingType === "h1" ? (
    <>{inner_content}</>
  ) : (
    <aside className={classes.aside}>{inner_content}</aside>
  );
}

export default CallToAction;
