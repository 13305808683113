import { slugify } from ".";

function extractAnchors(_n) {
  const anchors = [];
  if (_n.attributes) {
    if (
      (_n.type === "node--heading" && _n.attributes.field_heading_type === "h2") ||
      _n.type === "node--accordion_tab" ||
      _n.type === "node--highlight"
    ) {
      let anchor =
        (_n.type === "node--accordion_tab" &&
          _n.attributes &&
          _n.attributes.field_include_in_anchor_nav) ||
        _n.type !== "node--accordion_tab"
          ? {
              id: slugify(_n.attributes.title),
              title: _n.attributes.title,
            }
          : null;
      if (anchor) {
        anchors.push(anchor);
      }
    }
  }
  return anchors;
}

function extractAnchorsData(nodes) {
  let data = [];
  for (const _n of nodes) {
    if (_n.type === "node--row") {
      if (
        _n.relationships &&
        _n.relationships.field_row_columns &&
        _n.relationships.field_row_columns.data &&
        _n.relationships.field_row_columns.data.length > 0
      ) {
        for (const _c of _n.relationships.field_row_columns.data) {
          if (
            _c.relationships &&
            _c.relationships.field_column_blocks &&
            _c.relationships.field_column_blocks.data &&
            _c.relationships.field_column_blocks.data.length > 0
          ) {
            for (const _b of _c.relationships.field_column_blocks.data) {
              data = data.concat(extractAnchors(_b));
            }
          }
        }
      }
    } else {
      data = data.concat(extractAnchors(_n));
    }
  }
  return data;
}
export default extractAnchorsData;
