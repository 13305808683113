import findPathFromEntityId from "./findPathFromEntityId";

function navDataFormat(allMenuItems, allNodeRefs) {
  if (allMenuItems.edges && allMenuItems.edges.length > 0) {
    return allMenuItems.edges
      .filter(({ node }) => node.parent === null)
      .map(({ node }) => {
        let item = {
          title: node.title,
          weight: parseInt(node.weight),
        };
        if (node.childrenMenuItems && node.childrenMenuItems.length > 0) {
          item.children = node.childrenMenuItems.map((node) => {
            let path = node.url;
            if (node.route && node.route.parameters && node.route.parameters.node) {
              let matching_path = findPathFromEntityId(
                node.route.parameters.node,
                allNodeRefs
              );
              if (matching_path) path = matching_path;
            }
            return {
              title: node.title,
              weight: parseInt(node.weight),
              path,
            };
          });
        } else {
          let path = node.url;
          if (node.route && node.route.parameters && node.route.parameters.node) {
            let matching_path = findPathFromEntityId(
              node.route.parameters.node,
              allNodeRefs
            );
            if (matching_path) path = matching_path;
          }
          item.path = path;
        }
        return item;
      });
  } else {
    return [];
  }
}

export default navDataFormat;
