import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { SiteContext } from "../../layouts/Main";
import { SingleContext } from "../../layouts/SingleBody";
import { recaptchaSubmit, handleEmail } from "../../utils";
import { Field } from "../";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "block",
    "& .MuiFormControl-root": {
      width: "100%",
      marginBottom: theme.spacing(1),
    },
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
}));

const validationSchema = yup.object({
  mail: yup.string().required("Email is required").email("Enter a valid email address"),
  field_name: yup.string().min(2, "Add a valid name").required("Add your name"),
  field_question: yup
    .string()
    .min(2, "Add a valid question")
    .required("Add your question"),
});

function ContactModal() {
  const classes = useStyles();
  const {
    contactModalOpen,
    setContactModalOpen,
    contactModalTitle,
    drupalUrl,
    adminEmail,
    siteName,
    siteUrl,
    accentColor,
  } = React.useContext(SiteContext);
  const { singleLeadEmail, singleTitle } = React.useContext(SingleContext);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const initialValues = {
    mail: "",
    field_name: "",
    field_question: "",
  };
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [requestSent, setRequestSent] = React.useState(null);
  const [msg, setMsg] = React.useState(null);

  const handleClose = (e) => {
    // e.preventDefault();
    setContactModalOpen(false);
  };

  function handleError(msg, setSubmitting) {
    console.error("ERR", msg);
    if (setSubmitting) {
      setSubmitting(false);
    }
    setMsg(msg);
    setError(true);
    setLoading(false);
  }

  const form = useFormik({
    validationSchema,
    initialValues,
    onSubmit: async (values, { setSubmitting }) => {
      setError(null);
      setLoading(true);
      const valid = await recaptchaSubmit(executeRecaptcha, drupalUrl);
      if (!valid) {
        setError("Failed Recaptcha");
      } else {
        const email_data = [];

        email_data.push(
          `<h1 style="text-align: center; color: #000000; line-height: 1.5">${singleTitle}</h1>`
        );
        email_data.push(`<strong>Email:</strong> ${values["mail"]}`);
        email_data.push(`<strong>Name:</strong> ${values["field_name"]}`);
        email_data.push(`<strong>Question:</strong> ${values["field_question"]}`);
        const email_to = singleLeadEmail ? singleLeadEmail : adminEmail;
        try {
          await handleEmail(
            drupalUrl,
            email_to,
            `${contactModalTitle}: ${singleTitle}`,
            email_data,
            adminEmail,
            siteName,
            siteUrl,
            null,
            null,
            accentColor
          )
            .then(() => {
              setError(false);
              setRequestSent(true);
              setLoading(false);
            })
            .catch((err) => {
              handleError(err, setSubmitting);
            });
        } catch (error) {
          handleError(error, setSubmitting);
        }
      }
    },
  });

  return (
    <>
      <Dialog
        open={contactModalOpen}
        aria-labelledby="form-dialog-title"
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose(event);
          }
        }}
      >
        <DialogTitle id="form-dialog-title">{contactModalTitle}</DialogTitle>
        {requestSent && (
          <DialogContent>
            <Alert severity="success">
              <div>Your message has been sent. We'll get in touch soon.</div>
            </Alert>
          </DialogContent>
        )}
        {!requestSent && (
          <DialogContent className={classes.form}>
            {error === true && (
              <Alert severity="error" className={classes.alert}>
                {msg}
              </Alert>
            )}
            <Field
              form={form}
              label="Email Address *:"
              name="mail"
              type="email"
              disabled={loading}
            />
            <Field
              form={form}
              label="Name *:"
              name="field_name"
              type="text"
              disabled={loading}
            />
            <Field
              form={form}
              label="Question *:"
              name="field_question"
              type="text"
              multiline
              disabled={loading}
            />
          </DialogContent>
        )}

        <DialogActions>
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              <Button onClick={handleClose} color="default">
                {requestSent ? "Close" : "Cancel"}
              </Button>
              {!requestSent && (
                <Button onClick={form.handleSubmit} color="primary">
                  Submit
                </Button>
              )}
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ContactModal;
