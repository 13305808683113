import { Link } from "gatsby";
import React from "react";

function NavLink({ children, to, className = "", onClick }) {
  const target = to.includes("http") ? "_blank" : "_self";
  function Wrapper(props) {
    if (target === "_blank") {
      return (
        <a href={to} target="_blank" rel="noreferrer" className={className}>
          {children}
        </a>
      );
    } else {
      return (
        <Link to={to} className={className} onClick={onClick}>
          {children}
        </Link>
      );
    }
  }

  return <Wrapper>{children}</Wrapper>;
}

export default NavLink;
