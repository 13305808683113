import React from "react";
import { Link } from "gatsby";
import Typography from "@material-ui/core/Typography";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { Container } from "../";

import makeStyles from "@material-ui/core/styles/makeStyles";
import theme from "../../../theme";
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    overflow: "hidden",
    "&.align-center": {
      justifyContent: "center",
    },
  },
  header: {
    marginTop: 0,
    backgroundColor: (props) => (props.hasBackground ? "grey" : props.color),
    background: (props) => (props.gradient ? props.gradient : ""),
    backgroundSize: "cover",
    backgroundPosition: "center center",
    padding: theme.spacing(4, 0, 0, 0),
    position: "relative",
    backgroundBlendMode: (props) => (props.gradient ? "none" : "luminosity"),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2, 0, 0, 0),
    },
    "&.secondary-header": {
      paddingTop: theme.spacing(1.5),
      [theme.breakpoints.down("sm")]: {
        paddingTop: theme.spacing(1),
      },
    },
    "&.align-center": {
      paddingTop: theme.spacing(3),
      [theme.breakpoints.down("sm")]: {
        paddingTop: theme.spacing(2),
      },
    },
    "& > div": {
      position: "relative",
      zIndex: 2,
    },
    "& > span.color-filter": {
      position: "absolute",
      display: "block",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: (props) => props.color,
    },
    "& > span.color-filter-1": {
      zIndex: 1,
      mixBlendMode: "color",
      opacity: "1",
    },
    "& > span.color-filter-2": {
      zIndex: 1,
      mixBlendMode: "multiply",
      opacity: "0.7",
    },
    "& > span.color-filter-3": {
      zIndex: 1,
      mixBlendMode: "darken",
      opacity: "0.3",
    },
    "&.full-width": {
      "& + .banner": {
        marginTop: theme.spacing(-4),
      },
      "& + .call-to-action": {
        marginTop: theme.spacing(-4),
      },
    },
  },
  title: {
    color: (props) => props.foreground,
    display: "block",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(1),
    },
    "&.align-center": {
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down("sm")]: {
        marginBottom: theme.spacing(2),
      },
    },
    "&.secondary-title": {
      fontSize: "1.75em",
      marginBottom: theme.spacing(1.5),
      [theme.breakpoints.down("sm")]: {
        marginBottom: theme.spacing(1),
      },
      "& a": {
        textDecoration: "none",
        color: "inherit",
        "&:hover, &:active, &:focus": {
          textDecoration: "underline",
        },
      },
    },
  },
  back: {
    color: (props) => props.foreground,
    marginRight: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2),
    },
    "& span": {
      display: "inline-block",
      marginLeft: theme.spacing(1),
    },
  },
}));

function TitleBar({
  pageTitle,
  backPath,
  background,
  align = "left",
  headingType = "h1",
  fullWidth,
}) {
  const classes = useStyles({
    hasBackground: typeof background !== "undefined",
    color: theme.palette.titleBar.background,
    foreground: theme.palette.titleBar.foreground,
    gradient: theme.palette.titleBar.gradient,
  });

  const header_class = `${classes.header} align-${align} title-bar ${
    backPath ? "secondary-header" : ""
  } ${fullWidth ? "full-width" : ""}`;

  const page_title_dom = !backPath ? (
    pageTitle
  ) : (
    <Link to={backPath} title="Go Back" className={classes.back}>
      <KeyboardBackspaceIcon />
      <span>{pageTitle}</span>
    </Link>
  );

  const HeaderContent = ({ children }) => {
    return (
      <>
        {children}
        {typeof background !== "undefined" && (
          <>
            <span className="color-filter color-filter-1" />
            <span className="color-filter color-filter-2" />
            <span className="color-filter color-filter-3" />
          </>
        )}
      </>
    );
  };

  const HeaderWrapper = ({ children }) => {
    return backPath || headingType !== "h1" ? (
      <div
        className={header_class}
        style={background ? { backgroundImage: `url(${background})` } : {}}
      >
        <HeaderContent children={children} />
      </div>
    ) : (
      <header
        className={header_class}
        style={background ? { backgroundImage: `url(${background})` } : {}}
      >
        <HeaderContent children={children} />
      </header>
    );
  };

  return (
    <HeaderWrapper>
      <Container className={`${classes.container} align-${align}`} root>
        <Typography
          variant={headingType}
          component={backPath ? "span" : headingType}
          className={`${classes.title}  ${backPath && "secondary-title"}  align-${align}`}
        >
          {page_title_dom}
        </Typography>
      </Container>
    </HeaderWrapper>
  );
}

export default TitleBar;
