import React from "react";
import { SiteContext } from "./Main";
import { Navbar, Modal, Footer, AlertBanner } from "../components";
import { isIframe } from "../utils";

function Default({ children, path }) {
  const [closeAlert, setCloseAlert] = React.useState(false);
  const {
    navData,
    secondaryNavData,
    modalURL,
    setModalURL,
    siteName,
    footerNavData,
    footerData,
    alertBanners,
  } = React.useContext(SiteContext);

  const is_frame = isIframe();

  React.useEffect(() => {
    if(is_frame && typeof document !== 'undefined'){
      let root = document.getElementsByTagName( 'html' )[0];
      root.classList.add("is-iframe");
    }
  }, [])

  const publicAlert = alertBanners
    ? alertBanners.find((ab) => ab.show_alert === true && ab.private_alert !== true)
    : null;

  const handleClose = function () {
    setCloseAlert(true);
  };

  return (
    <>
      {!is_frame && <Navbar
        navData={navData}
        secondaryNavData={secondaryNavData}
        path={path}
        siteName={siteName}
      />}
      {publicAlert && closeAlert !== true && (
        <AlertBanner {...publicAlert} handleClose={handleClose} />
      )}
      <main role="main">{children}</main>
      <Modal modalURL={modalURL} setModalURL={setModalURL} />
      {!is_frame && <Footer footerData={footerData} footerNavData={footerNavData} />}
    </>
  );
}

export default Default;
