import React from "react";
import { MoreButton } from "..";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: "flex",
    flexWrap: "wrap",
    marginLeft: -theme.spacing(1),
    marginRight: -theme.spacing(1),
    "& > *": {
      // margin: theme.spacing(0, 0, 1),
      // flex: "1 1 auto",
      padding: theme.spacing(1, 1),
      flexBasis: 0,
      flexGrow: 1,
      "& > *": {
        display: "block",
        textAlign: "center",
      },
    },
    "&.single-col": {
      display: "block",
      // marginLeft: theme.spacing(1),
      // marginRight: theme.spacing(1),
      "& > *": {
        display: "block",
      },
    },
  },
}));

function Buttons({ primary, secondary, singleColumn }) {
  const classes = useStyles();

  if (!primary && !secondary) return <></>;
  return (
    <nav className={`${classes.buttons} ${singleColumn ? "single-col" : ""}`}>
      {primary && (
        <div>
          <MoreButton
            to={primary.to}
            toLabel={primary.label}
            variant="contained"
            color="primary"
            size="large"
          />
        </div>
      )}
      {secondary && (
        <div>
          <MoreButton
            to={secondary.to}
            toLabel={secondary.label}
            variant="outlined"
            color="primary"
            size="large"
          />
        </div>
      )}
    </nav>
  );
}

export default Buttons;
