import React from "react";
import { Link } from "gatsby";
import { Paper, Typography, TextField, InputAdornment, Button } from "@material-ui/core";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import { AuthContext, handleLogin } from "@parallelpublicworks/understory-auth";
import Alert from "@material-ui/lab/Alert";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { SiteContext } from "../../layouts/Main";
const useStyles = makeStyles((theme) => ({
  login: {
    padding: theme.spacing(4, 0),
    maxWidth: 420,
    margin: "0 auto",
  },
  title: {
    textAlign: "center",
  },
  paper: {
    padding: theme.spacing(2),
    "& > *:not(:first-child)": {
      marginTop: `${theme.spacing(2)}px !important`,
      width: "100%",
    },
  },
  links: {
    textAlign: "center",
    "& a": {
      display: "inline-block",
      margin: theme.spacing(0, 1),
      color: theme.palette.link.main,
      "&:hover, &:active, &:focus": {
        color: theme.palette.link.hover,
        textDecoration: "none",
      },
    },
  },
}));

function Login() {
  const classes = useStyles();
  const authContext = React.useContext(AuthContext);
  const [{ isAuthenticated }] = authContext;
  const [submitting, setSubmitting] = React.useState(false);
  const [error, setError] = React.useState(null);
  const { allowRegistration } = React.useContext(SiteContext);

  if (!isAuthenticated) {
    return (
      <div className={classes.login}>
        <Paper
          className={classes.paper}
          component="form"
          onSubmit={async (e) => {
            e.preventDefault();
            setError(null);
            setSubmitting(true);
            let loggedIn = await handleLogin(authContext, e);
            if (!loggedIn) {
              setSubmitting(false);
              setError("Please check your username and password and try again");
            }
          }}
        >
          <Typography variant="h3" className={classes.title}>
            Login
          </Typography>
          {error && <Alert severity="error">{error}</Alert>}
          <TextField
            label="Email:"
            name="username"
            variant="outlined"
            disabled={submitting}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MailOutlineIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="Password:"
            variant="outlined"
            name="password"
            type="password"
            disabled={submitting}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <VpnKeyIcon />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="secondary"
            size="large"
            type="submit"
            disabled={submitting}
          >
            Login
          </Button>
          <Typography className={classes.links}>
            {allowRegistration && <Link to="/register">Request account</Link>}
            <Link to="/password-reset">Forgot password?</Link>
          </Typography>
        </Paper>
      </div>
    );
  } else {
    return <></>;
  }
}

export default Login;
