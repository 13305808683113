function isIframe() {
    if(typeof window !== 'undefined'){
      if(window.self !== window.top){
        const backend_host = new URL(process.env.GATSBY_BACKEND_URL);
        const referrer_host = document.referrer ? new URL(document.referrer) : null;
        console.log(backend_host.host, ' === ', referrer_host?.host);
        return backend_host.host === referrer_host?.host;
      }
    }
    return false
  }
  
  export default isIframe