module.exports = function getCompoundEntityTitle(titleSchema, attributes) {
  let title = "";
  for (const ti in titleSchema) {
    if (Object.hasOwnProperty.call(titleSchema, ti)) {
      const [title_piece_field_name] = titleSchema[ti];
      if (
        typeof attributes[title_piece_field_name] === "string" &&
        attributes[title_piece_field_name].length > 0
      ) {
        const prev_ti = ti - 1;
        const prev_title_piece_conf =
          prev_ti >= 0 &&
          prev_ti < titleSchema.length &&
          Object.hasOwnProperty.call(titleSchema, prev_ti)
            ? titleSchema[prev_ti]
            : null;
        if (
          prev_title_piece_conf &&
          typeof attributes[prev_title_piece_conf[0]] === "string" &&
          attributes[prev_title_piece_conf[0]].length > 0
        ) {
          title += `${prev_title_piece_conf[1]}`;
        } else {
          title += " ";
        }
        title += `${attributes[title_piece_field_name]}`;
      }
    }
  }
  return title;
};
