module.exports = function extractFileData(
  media_rel,
  file_field_name,
  drupal_url,
  isDocument,
  isPrivate
) {
  if (typeof isPrivate === "undefined") {
    isPrivate = media_rel.type.includes("private");
  }

  const file =
    media_rel && media_rel.relationships && media_rel.relationships[file_field_name]
      ? media_rel.relationships[file_field_name].data
      : null;

  if (file && file.meta) {
    const src =
      isPrivate || isDocument
        ? `/download?media=${media_rel.id}`
        : `${drupal_url}${file?.attributes?.uri?.url || ""}`;
    const format =
      file.attributes && file.attributes.uri
        ? file.attributes.uri.url.split(".").pop()
        : null;
    const f = { ...file.meta, src, format, isPrivate };
    return f;
  } else {
    return null;
  }
};
