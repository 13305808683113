import React from "react";
import { Section, TitleBar, Banner } from "../../components";
import { extractMediaData } from "../../utils";

function HeadingSection({ title, parent, bannerNode, drupalUrl }) {
  const banner_image = extractMediaData(
    bannerNode,
    "field_banner_media_image",
    "field_media_image",
    drupalUrl
  );
  const banner_size =
    bannerNode && bannerNode.attributes ? bannerNode.attributes.field_banner_size : "sm";

  const search =
    bannerNode &&
    bannerNode.attributes &&
    bannerNode.attributes.field_display_search_form === true;
  const searchResultsPath =
    bannerNode && bannerNode.attributes.field_search_results_path
      ? bannerNode.attributes.field_search_results_path
      : "/search";
  const searchPlaceholder =
    bannerNode && bannerNode.attributes.field_search_placeholder
      ? bannerNode.attributes.field_search_placeholder
      : "Search this website...";

  return (
    <Section fullWidth>
      <TitleBar
        pageTitle={parent ? parent.title : title}
        backPath={parent ? parent.slug : null}
        fullWidth={false}
      />
      {banner_image && (
        <Banner
          image={banner_image.src}
          size={banner_size}
          search={search}
          searchPlaceholder={searchPlaceholder}
          searchResultsPath={searchResultsPath}
        />
      )}
    </Section>
  );
}

export default HeadingSection;
