function getInitialValues({
  initialValues,
  userData,
  nodeRelationshipsData,
  mediaFieldNames,
  relationshipFieldNames,
  relationshipFieldSchemas,
}) {
  if (userData) {
    for (const [field] of Object.entries(initialValues)) {
      if (mediaFieldNames.includes(field)) {
        // mimic media.id
        let media_data = userData?.relationships[field]
          ? userData?.relationships[field].data
          : null;
        if (media_data && media_data.id) {
          initialValues[field] = media_data.id;
        }
      } else if (
        relationshipFieldNames.includes(field) &&
        userData?.relationships[field] &&
        nodeRelationshipsData &&
        nodeRelationshipsData[field]
      ) {
        const field_config = relationshipFieldSchemas[field];
        const identifier_num_key = field_config.kind === "node" ? "nid" : "tid";
        const user_rel_data =
          Array.isArray(userData?.relationships[field].data) &&
          userData?.relationships[field].data.length > 0
            ? userData?.relationships[field].data
            : userData?.relationships[field].data
            ? [userData?.relationships[field].data]
            : null;
        if (Array.isArray(user_rel_data) && user_rel_data.length > 0) {
          let rel_uuid = field_config.multiple
            ? user_rel_data.map((u_rel_item) => u_rel_item.id)
            : user_rel_data[0].id;
          if (rel_uuid) {
            let rel_data_item = field_config.multiple
              ? nodeRelationshipsData[field].filter((item) => rel_uuid.includes(item.id))
              : nodeRelationshipsData[field].find((item) => item.id === rel_uuid);
            if (field_config.multiple) {
              if (rel_data_item.length > 0) {
                initialValues[field] = rel_data_item
                  .filter(
                    (a_rel_item) => typeof a_rel_item[identifier_num_key] !== "undefined"
                  )
                  .map((a_rel_item) => a_rel_item[identifier_num_key]);
              }
            } else {
              if (rel_data_item && rel_data_item[identifier_num_key]) {
                initialValues[field] = `${rel_data_item[identifier_num_key]}`;
              }
            }
          }
        }
      } else if (userData?.attributes[field]) {
        initialValues[field] = userData.attributes[field];
      }
    }
  }
  return initialValues;
}
export default getInitialValues;
