import React from "react";
import { Link } from "gatsby";
import { Avatar, Paper, Typography } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { RelationChips } from "../";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "&.is-sidebar": {
      marginTop: 0,
      display: "block",
      textAlign: "center",
      "& > div ": {
        textAlign: "left",
      },
    },
  },
  text: {
    "& a": {
      color: theme.palette.link.main,
      "&:hover, &:active, &:focus": {
        color: theme.palette.link.hover,
        textDecoration: "none",
      },
    },
  },
  paper: {
    padding: theme.spacing(2),
    minHeight: 160,
    border: `${theme.palette.grey.dark} 1px solid`,
    "&.type-user": {
      padding: theme.spacing(3, 2),
      textAlign: "center",
    },
    "& .MuiAvatar-root": {
      marginLeft: "auto",
      marginRight: "auto",
    },
    "&.type-company.has-logo": {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        display: "block",
        textAlign: "center",
      },
    },
  },
  heading: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: "1.4em",
    "& a": {
      color: theme.palette.link.main,
      textDecoration: "none",
      borderBottom: "1px dotted transparent",
      "&:focus, &:active, &:hover": {
        color: theme.palette.link.hover,
        // textDecoration: "underline",
        borderBottomColor: theme.palette.primary.dark,
      },
    },
    "&.type-company": {
      fontSize: "1.2em",
    },
  },
  logo: {
    width: 140,
    height: "auto",
    marginRight: theme.spacing(2.5),
    marginLeft: theme.spacing(0.5),
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      width: "80%",
      maxWidth: 140,
    },
    "&.is-sidebar": {
      margin: "20px 0",
      display: "block",
      width: "100%",
      maxWidth: 280,
    },
  },
  avatar: {
    marginBottom: theme.spacing(1),
    width: theme.spacing(12),
    height: theme.spacing(12),
    fontSize: "1.3em",
    "&.pic-format-square": {
      borderRadius: 0,
    },
    "&.size-sm": {
      width: theme.spacing(6),
      height: theme.spacing(6),
    },
    "&.size-md": {
      width: theme.spacing(8),
      height: theme.spacing(8),
    },
  },
  smallText: {
    fontSize: "86%",
  },
}));

function VCard({
  id,
  picture,
  initials,
  title,
  title_append,
  slug,
  jobTitle,
  companyName,
  phone,
  mail,
  website,
  website_label,
  address,
  headingType = "h2",
  type = "user",
  tableMode,
  taxonomies,
  entities_rel,
  isSidebar,
  captions,
  pic_format = "rounded",
  imageSize = "lg",
}) {
  const classes = useStyles();
  function Wrapper({ children }) {
    if (tableMode || isSidebar) {
      return (
        <div className={`${classes.root} ${isSidebar ? "is-sidebar" : ""}`}>
          {children}
        </div>
      );
    } else {
      return (
        <Paper
          elevation={0}
          className={`${classes.paper} type-${type} ${
            typeof picture !== "undefined" && picture !== null ? "has-logo" : ""
          }`}
        >
          {children}
        </Paper>
      );
    }
  }

  function Pic() {
    let attrs = {};
    if (typeof picture !== "undefined" && picture) {
      if (typeof picture === "string") {
        attrs.src = picture;
      } else {
        attrs.src = picture.src;
        attrs.alt = picture.alt;
      }
      if (type === "user") {
        if (typeof slug !== "undefined") {
          attrs.component = Link;
          attrs.to = slug;
        }
        return (
          <Avatar
            {...attrs}
            className={`${classes.avatar} pic-format-${pic_format} size-${imageSize}`}
          >
            {typeof initials !== "undefined" ? initials : ""}
          </Avatar>
        );
      } else {
        function LogoWrap({ children }) {
          if (typeof slug !== "undefined") {
            return <Link to={slug}>{children}</Link>;
          } else {
            return <>{children}</>;
          }
        }
        return (
          <LogoWrap>
            <img
              src={attrs.src}
              alt={attrs.alt}
              className={`${classes.logo} ${isSidebar ? "is-sidebar" : ""}`}
            />
          </LogoWrap>
        );
      }
    } else {
      if (type === "user") {
        return (
          <Avatar
            {...attrs}
            className={`${classes.avatar} pic-format-${pic_format} size-${imageSize}`}
          >
            {typeof initials !== "undefined" ? initials : ""}
          </Avatar>
        );
      } else {
        return <></>;
      }
    }
  }

  function Name() {
    if (!title) return <></>;
    return typeof slug === "undefined" ? (
      <Typography
        variant="body2"
        component={headingType}
        className={`${classes.heading} type-${type}`}
      >
        <span>{title}</span>
        {title_append && <span>{`, ${title_append}`}</span>}
      </Typography>
    ) : (
      <Typography
        variant="body2"
        component={headingType}
        className={`${classes.heading} type-${type}`}
      >
        <Link to={`${slug}`}>
          {title}
          {title_append && <span>{`, ${title_append}`}</span>}
        </Link>
      </Typography>
    );
  }

  function Tagline() {
    let tagline = `${typeof jobTitle !== "undefined" ? jobTitle : ""}${
      typeof companyName !== "undefined"
        ? `${typeof jobTitle !== "undefined" ? " - " : ""}${companyName}`
        : ""
    }`;
    if (tagline.length > 0)
      return (
        <Typography variant="body1" className={classes.smallText}>
          <em>{tagline}</em>
        </Typography>
      );
    return <></>;
  }

  function Address() {
    let address_string = "";
    if (address) {
      if (address.street) address_string += `${address.street} `;
      if (address.city) address_string += `<br /> ${address.city}`;
      if (address.state) address_string += `, ${address.state} `;
      if (address.zip) address_string += ` ${address.zip} `;
    }
    if (address_string.length > 0)
      return (
        <Typography variant="body1">
          <span
            className={classes.smallText}
            dangerouslySetInnerHTML={{ __html: address_string }}
          ></span>
        </Typography>
      );
    return <></>;
  }

  function Contact() {
    let contact = [];
    if (typeof mail !== "undefined")
      contact.push(
        <a key={mail} href={`mailto:${mail}`} rel="noreferrer">
          {mail}
        </a>
      );
    if (typeof phone !== "undefined" && typeof mail !== "undefined" && !isSidebar) {
      contact.push(<span key={`${mail}-sep`}>{" | "}</span>);
    }
    if (typeof phone !== "undefined") {
      if (isSidebar) {
        contact.push(
          <>
            <br />
            <span key={phone}>{phone}</span>
          </>
        );
      } else {
        contact.push(<span key={phone}>{phone}</span>);
      }
    }
    if (contact.length > 0)
      return (
        <Typography variant="body1" className={classes.smallText}>
          {contact}
        </Typography>
      );
    return <></>;
  }

  function Website() {
    if (typeof website !== "undefined") {
      return (
        <Typography variant="body1">
          <a href={`${website}`} target="_blank" rel="noreferrer">
            {website_label ? website_label : website}
          </a>
        </Typography>
      );
    }
    return <></>;
  }

  return (
    <Wrapper>
      <Pic />
      <div className={classes.text}>
        <Name />
        <Tagline />
        <Address />
        <Contact />
        <Website />
        <RelationChips rels={captions} />
      </div>
    </Wrapper>
  );
}

export default VCard;
