// import * as mandrill from "mandrill-api/mandrill";

export default async function handleEmail(
  drupalUrl,
  email_to,
  subject,
  email_data,
  admin_email,
  site_name,
  site_url,
  action_url,
  action_label,
  accent_color,
  attachments
) {
  return new Promise(function (resolve, reject) {
    const creds = btoa(
      `${process.env.BASIC_REST_USERNAME}:${process.env.BASIC_REST_PASSWORD}`
    );
    const url = `${drupalUrl}/handle_email/send?_format=json`;

    const extras = {};
    if (site_url) extras.site_url = site_url;
    if (action_url) extras.action_url = action_url;
    if (action_label) extras.action_label = action_label;
    if (accent_color) extras.accent_color = accent_color;
    if (attachments) {
      extras.attachments = attachments;
    }

    fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Basic ${creds}`,
      },
      body: JSON.stringify({
        subject,
        email_to,
        email_data,
        admin_email,
        site_name,
        ...extras,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          console.error("response", response);
          reject(`${response.status} - ${response.statusText}`);
        }
      })
      .then((response) => {
        if (response && response.data === "OK") {
          resolve();
        } else {
          reject("Something went wrong");
        }
      })
      .catch(function (err) {
        reject(err.message);
      });
  });
}
