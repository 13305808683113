import React from "react";
import { Blocks } from "./";

function SidebarSection({ nodes, contentTypes, taxonomies, listedEntities }) {
  if (!nodes) return <></>;
  return (
    <>
      {nodes.map((n) => {
        switch (n.type) {
          case "node--sidebar_block":
            return (
              <Blocks
                key={n.id}
                nodes={
                  n.relationships && n.relationships.field_sidebar_components
                    ? n.relationships.field_sidebar_components.data
                    : null
                }
                isSidebar
                contentTypes={contentTypes}
                taxonomies={taxonomies}
                listedEntities={listedEntities}
              />
            );
          default:
            return <></>;
        }
      })}
    </>
  );
}

export default SidebarSection;
