import React from "react";
import * as queryString from "query-string";
import {
  Grid,
  List,
  ListItem,
  TextField,
  Paper,
  Typography,
  Button,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import CloseIcon from "@material-ui/icons/Close";
import { MoreButton } from "../";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { SiteContext } from "../../layouts/Main";
import { slugify, getDateTimeFromValue } from "../../utils";
import { DateTime } from "luxon";

const useStyles = makeStyles((theme) => ({
  item: {
    "& > *": {
      height: "100%",
    },
  },
  filters: {
    backgroundColor: theme.palette.grey.main,
    overflow: "hidden",
    padding: theme.spacing(2),
    "& form": {
      display: "flex",
      alignItems: "center",
      "& .MuiIconButton-root": {
        marginLeft: theme.spacing(1),
        padding: 0,
        width: theme.spacing(6),
        height: theme.spacing(6),
      },
    },
  },
  formControl: {
    width: "100%",
    margin: 0,
  },
  list: {
    marginBottom: theme.spacing(4),
    "& li": {
      borderBottom: `${theme.palette.grey.dark} 1px solid`,
      [theme.breakpoints.down("md")]: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(3),
      },
      "&:last-child": {
        borderBottom: "none",
      },
    },
  },
  listRow: {
    alignItems: "center",
    "& > div ": {
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        paddingTop: "0 !important",
        paddingBottom: "0 !important",
      },
    },
  },
  nothing: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: theme.spacing(2),
    "& > *": {
      marginRight: theme.spacing(2),
    },
  },
  more: {
    textAlign: "center",
    marginTop: theme.spacing(2),
    "& .MuiButtonBase-root": {
      minWidth: 220,
      "& + .MuiButtonBase-root": {
        marginLeft: theme.spacing(1),
      },
    },
  },
}));

export const ListContext = React.createContext();

function ListEntities({
  children,
  terms,
  nodes,
  entityName = "Entry",
  spacing = 2,
  tableMode,
  filters,
  dateFilter,
  limit,
  paginationMode,
  moreLink,
  hideNoResultsNotice,
  main_cols,
}) {
  const classes = useStyles();
  const { location } = React.useContext(SiteContext);
  const initialChildren = Array.isArray(children)
    ? [...children]
    : children !== null
    ? [children]
    : [];
  const filtersForm = React.useRef(null);
  const [filtersQueryString, setFiltersQueryString] = React.useState(null);
  const [filteredChildren, setFilteredChildren] = React.useState(initialChildren);
  const [visibleChildren, setVisibleChildren] = React.useState(
    limit ? [...initialChildren].slice(0, limit) : initialChildren
  );
  const [noJS, setNoJs] = React.useState(true);
  const [manuallyChangedFilters, setManuallyChangedFilters] = React.useState(false);
  const [queryFilters, setQueryFilters] = React.useState(null);
  const [currentFilters, setCurrentFilters] = React.useState({});
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const taxonomies_select_data = !!terms
    ? terms.filter((_t) => Array.isArray(_t.items) && _t.items.length > 0)
    : [];
  const nodes_select_data = !!nodes
    ? nodes.filter((_n) => Array.isArray(_n.items) && _n.items.length > 0)
    : [];
  const indexable_props_for_keyword_search = [
    "title",
    "subtitle",
    "summary",
    "jobTitle",
    "mail",
    "phone",
    "captions",
    "search_index",
  ];

  React.useEffect(() => {
    setNoJs(false);
  }, []);

  React.useEffect(() => {
    const localChildren = Array.isArray(children)
      ? [...children]
      : children !== null
      ? [children]
      : null;

    if (Array.isArray(localChildren)) {
      setFilteredChildren(localChildren);
      if (typeof limit === "number")
        setVisibleChildren([...localChildren].slice(0, limit));
      else setVisibleChildren(localChildren);
    }
    setTotalPages(limit ? Math.ceil(children.length / limit) : 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children]);

  React.useEffect(() => {
    if (location && location.search) {
      setQueryFilters(queryString.parse(location.search));
    }
  }, [location]);

  React.useEffect(() => {
    if (filtersQueryString) {
      setQueryFilters(queryString.parse(filtersQueryString));
    }
  }, [filtersQueryString]);

  React.useEffect(() => {
    if (queryFilters) {
      let stringToFilters = {};
      if (typeof queryFilters["filter[]"] !== "undefined") {
        const q_filters = Array.isArray(queryFilters["filter[]"])
          ? queryFilters["filter[]"]
          : [queryFilters["filter[]"]];
        for (const filter_str of q_filters) {
          const [type, slugified_title] = filter_str.split("__");
          const available_items = getAvailableFilterItems(type);
          const item =
            available_items && Array.isArray(available_items.items)
              ? available_items.items.find(
                  ({ title }) => slugified_title === slugify(title)
                )
              : null;
          if (item && item.id) {
            stringToFilters[type] = item.id;
          }
        }
      }
      if (typeof queryFilters["date"] !== "undefined") {
        stringToFilters.date = queryFilters["date"];
      }
      setCurrentFilters(stringToFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryFilters]);

  React.useEffect(() => {
    if (children) {
      if (Object.keys(currentFilters).length === 0) {
        setFilteredChildren(children);
        setTotalPages(limit ? Math.ceil(children.length / limit) : 1);
        if (typeof window !== "undefined" && manuallyChangedFilters) {
          window.history.pushState(null, "", location.pathname);
          setQueryFilters(null);
        }
      } else {
        const has_keywords = typeof currentFilters.keywords !== "undefined";
        const now = DateTime.now();
        let filtered_by_relationships =
          Object.keys(currentFilters).length === 1 && has_keywords
            ? children
            : children.filter((child) => {
                return Object.keys(currentFilters).every((type) => {
                  if (type === "keywords") {
                    return true;
                  } else if (type === "date") {
                    const date_obj = getDateTimeFromValue(child.props.date_iso);
                    if (currentFilters[type] === "past") {
                      return date_obj < now;
                    } else {
                      return date_obj > now;
                    }
                  } else {
                    const filter_kind = type.includes("taxonomy") ? "terms" : "nodes";
                    if (
                      typeof child.props[filter_kind] !== "undefined" &&
                      typeof child.props[filter_kind][type] !== "undefined"
                    ) {
                      const rels = child.props[filter_kind][type];
                      const items = rels[filter_kind];
                      const contains_val = items.find(
                        (itm) => currentFilters[type] === itm.id
                      );
                      return typeof contains_val !== "undefined";
                    }
                    return false;
                  }
                });
              });

        if (has_keywords) {
          filtered_by_relationships = filtered_by_relationships.filter((child) => {
            let haystack = "";
            for (const prop_name of indexable_props_for_keyword_search) {
              let a_prop = child.props[prop_name];
              if (a_prop) {
                if (Array.isArray(a_prop)) {
                  a_prop = a_prop.join(" ");
                }
                haystack += ` ${a_prop}`;
              }
            }
            console.log("haystack??", haystack);
            return haystack
              .toLowerCase()
              .includes(`${currentFilters.keywords}`.toLowerCase());
          });
        }
        setTotalPages(limit ? Math.ceil(filtered_by_relationships.length / limit) : 1);
        setFilteredChildren(filtered_by_relationships);
        if (typeof window !== "undefined") {
          let newQueryString = [];
          for (const [a_filter_type, a_filter_uuid] of Object.entries(currentFilters)) {
            const a_filter_available_items = getAvailableFilterItems(a_filter_type);
            const a_filter_item =
              a_filter_available_items && Array.isArray(a_filter_available_items.items)
                ? a_filter_available_items.items.find(({ id }) => a_filter_uuid === id)
                : null;
            const a_filter_slugified_title = a_filter_item
              ? slugify(a_filter_item.title)
              : null;
            if (a_filter_slugified_title) {
              newQueryString.push(
                `filter[]=${a_filter_type}__${a_filter_slugified_title}`
              );
            }
          }
          if (typeof currentFilters.date !== "undefined") {
            newQueryString.push(`date=${currentFilters.date}`);
          }
          if (newQueryString.length > 0) {
            const qs = `?${newQueryString.join("&")}`;
            if (location.search !== qs) {
              window.history.pushState(null, "", `${location.pathname}${qs}`);
            }
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilters]);

  React.useEffect(() => {
    let visible = [];
    if (limit) {
      if (paginationMode === "numbers") {
        visible = filteredChildren.slice((currentPage - 1) * limit, currentPage * limit);
      } else {
        visible = filteredChildren.slice(0, currentPage * limit);
      }
    } else {
      visible = filteredChildren;
    }
    setVisibleChildren(visible);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredChildren, currentPage]);

  const has_show_more_button =
    paginationMode === "button" && totalPages > 1 && currentPage < totalPages;
  const display = tableMode ? "list" : "grid";

  let filter_cols_w_main = 6;
  let cols_count =
    taxonomies_select_data && nodes_select_data
      ? taxonomies_select_data.length + nodes_select_data.length
      : taxonomies_select_data || nodes_select_data
      ? taxonomies_select_data
        ? taxonomies_select_data.length
        : nodes_select_data.length
      : 1;
  if (dateFilter) {
    cols_count = cols_count + 1;
  }
  let filter_cols_w = Math.floor(6 / cols_count);

  if (cols_count > 3) {
    filter_cols_w_main = 12;
    filter_cols_w = Math.floor(12 / cols_count);
    if (filter_cols_w < 2) {
      filter_cols_w = 2;
    }
  }

  if (!main_cols) {
    if (children.length > 4) {
      const mod = children.length % 3;
      const widow = 3 - mod;
      if (widow) {
        main_cols = widow === 2 ? 6 : 4;
      }
    } else {
      switch (children.length) {
        case 2:
        case 4:
          main_cols = 6;
          break;
        case 3:
          main_cols = 4;
          break;
        default:
          main_cols = 12;
          break;
      }
    }
  }

  let main_cols_md = children.length > 1 ? 6 : 12;

  function getAvailableFilterItems(type) {
    return type.includes("taxonomy_term")
      ? taxonomies_select_data.find((t_data) => t_data.type === type)
      : nodes_select_data.find((n_data) => n_data.type === type);
  }

  function getFilters(val, type) {
    if (val && val.length > 0) {
      return {
        ...currentFilters,
        [type]: val,
      };
    } else {
      const new_filters = { ...currentFilters };
      if (typeof new_filters[type] !== "undefined") delete new_filters[type];
      return new_filters;
    }
  }

  if (
    (queryFilters &&
      typeof queryFilters["filter[]"] !== "undefined" &&
      Object.keys(currentFilters).length === 0) ||
    (noJS && filters)
  ) {
    return <CircularProgress />;
  }

  return (
    <ListContext.Provider
      value={{
        filtersForm,
        setFiltersQueryString,
      }}
    >
      {filters && (
        <>
          <Paper className={classes.filters} ref={filtersForm}>
            <form noValidate autoComplete="off" onSubmit={(e) => e.preventDefault()}>
              <Grid container spacing={spacing}>
                <Grid item lg={filter_cols_w_main}>
                  <TextField
                    id="outlined-basic"
                    label="Search..."
                    variant="outlined"
                    className={classes.formControl}
                    onChange={(e) => {
                      setCurrentFilters(getFilters(e.target.value, "keywords"));
                    }}
                    value={
                      typeof currentFilters.keywords !== "undefined"
                        ? currentFilters.keywords
                        : ""
                    }
                  />
                </Grid>
                {/* Tax and node filters here */}
                {taxonomies_select_data.map(({ type, label, items }) => {
                  return (
                    <Grid item lg={filter_cols_w} key={`filters-${type}`}>
                      <TextField
                        id={`filter-field-${type}`}
                        label={label}
                        variant="outlined"
                        className={classes.formControl}
                        select
                        SelectProps={{
                          native: true,
                        }}
                        onChange={(e) => {
                          setManuallyChangedFilters(true);
                          setCurrentFilters(getFilters(e.target.value, type));
                        }}
                        value={
                          typeof currentFilters[type] !== "undefined"
                            ? currentFilters[type]
                            : ""
                        }
                      >
                        <option value=""> </option>
                        {items
                          .sort((a, b) => (a.title < b.title ? -1 : 1))
                          .map((term) => {
                            return (
                              <option key={`option-tax-${term.id}`} value={term.id}>
                                {term.title}
                              </option>
                            );
                          })}
                      </TextField>
                    </Grid>
                  );
                })}
                {nodes_select_data.map(({ type, label, items }) => {
                  return (
                    <Grid item lg={filter_cols_w} key={`filters-${type}`}>
                      <TextField
                        id={`filter-field-${type}`}
                        label={label}
                        variant="outlined"
                        className={classes.formControl}
                        select
                        SelectProps={{
                          native: true,
                        }}
                        onChange={(e) => {
                          setManuallyChangedFilters(true);
                          setCurrentFilters(getFilters(e.target.value, type));
                        }}
                        value={
                          typeof currentFilters[type] !== "undefined"
                            ? currentFilters[type]
                            : ""
                        }
                      >
                        <option value=""> </option>
                        {items
                          .sort((a, b) => (a.title < b.title ? -1 : 1))
                          .map((term) => {
                            return (
                              <option key={`option-entity-${term.id}`} value={term.id}>
                                {term.title}
                              </option>
                            );
                          })}
                      </TextField>
                    </Grid>
                  );
                })}
                {dateFilter && (
                  <Grid item lg={filter_cols_w}>
                    <TextField
                      id={`filter-field-date`}
                      label="Filter by date"
                      variant="outlined"
                      className={classes.formControl}
                      select
                      SelectProps={{
                        native: true,
                      }}
                      onChange={(e) => {
                        setManuallyChangedFilters(true);
                        setCurrentFilters(getFilters(e.target.value, "date"));
                      }}
                      value={
                        typeof currentFilters["date"] !== "undefined"
                          ? currentFilters["date"]
                          : ""
                      }
                    >
                      <option value=""> </option>
                      <option value="future">Upcoming</option>
                      <option value="past">Past</option>
                    </TextField>
                  </Grid>
                )}
              </Grid>
              {Object.keys(currentFilters).length > 0 && (
                <IconButton
                  aria-label="Clear filters"
                  onClick={() => {
                    setManuallyChangedFilters(true);
                    setCurrentFilters({});
                  }}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </form>
          </Paper>
        </>
      )}
      {visibleChildren.length === 0 && !hideNoResultsNotice && (
        <div className={classes.nothing}>
          <Typography variant="h4" gutterBottom>
            No results
          </Typography>
        </div>
      )}
      {display === "grid" ? (
        <Grid container spacing={spacing}>
          {visibleChildren.map((child) => {
            return (
              <Grid
                item
                lg={main_cols}
                md={main_cols_md}
                key={child.props.id}
                className={classes.item}
              >
                {child}
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <List className={classes.list}>
          {visibleChildren.map((child) => {
            return <ListItem key={child.props.id}>{child}</ListItem>;
          })}
        </List>
      )}
      {paginationMode === "numbers" && totalPages > 1 && (
        <Pagination
          count={totalPages}
          size="large"
          onChange={(e, value) => {
            setCurrentPage(value);
            if (typeof document !== "undefined") {
              setTimeout(() => {
                var container = document.getElementById("___gatsby");
                container.scrollIntoView({ behavior: "smooth", block: "start" });
              }, 10);
            }
          }}
        />
      )}
      {(has_show_more_button || moreLink) && (
        <div className={classes.more}>
          {has_show_more_button && (
            <Button onClick={(e) => setCurrentPage(currentPage + 1)} variant="contained">
              Show more
            </Button>
          )}
          {moreLink && (
            <MoreButton
              to={moreLink.to}
              toLabel={moreLink.label}
              variant="contained"
              color="grey"
            />
          )}
        </div>
      )}
    </ListContext.Provider>
  );
}

export default ListEntities;
