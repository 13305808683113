function getNodeRelationshipsData({ graphqlData, relationshipFieldNames }) {
  let rdata = {};
  for (const node_key of relationshipFieldNames) {
    if (
      graphqlData &&
      graphqlData[node_key] &&
      graphqlData[node_key].edges &&
      graphqlData[node_key].edges.length > 0
    ) {
      rdata[node_key] = graphqlData[node_key].edges.map(({ node }) => ({
        ...node,
      }));
    }
  }
  return rdata;
}
export default getNodeRelationshipsData;
