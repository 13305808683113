import React from "react";
import ReactHtmlParser, { convertNodeToElement } from "react-html-parser";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Alert from "@material-ui/lab/Alert";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  alert: {
    textAlign: "center",
    "& a": {
      color: theme.palette.link.main,
      "&:hover, &:active, &:focus": {
        color: theme.palette.link.hover,
        textDecoration: "none",
      },
    },
  },
}));

function AlertBanner({ name, description, handleClose, severity }) {
  const classes = useStyles();
  return (
    <Alert onClose={handleClose} className={classes.alert} severity={severity || "info"}>
      <Typography component="div" className={classes.text}>
        <strong>{name}</strong> —{" "}
        {ReactHtmlParser(description, {
          transform: (node, index) => {
            if (node.type === "tag" && node.name === "p") {
              node.name = "span";
              return convertNodeToElement(node, index);
            }
          },
        })}
      </Typography>
    </Alert>
  );
}

export default AlertBanner;
