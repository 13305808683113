import React from "react";
import { Button } from "@material-ui/core";
import { Link } from "gatsby";
import makeStyles from "@material-ui/core/styles/makeStyles";
const useStyles = makeStyles((theme) => ({
  pill: {
    "&.no-link": {
      cursor: "default",
      color: theme.palette.primary.main,
    },
    "& .MuiButton-label": {
      textTransform: "none",
    },
    "&.block": {
      display: "block",
      width: "100%",
      textAlign: "left",
    },
  },
}));

function Pill({ title, slug, showLink, block, style, isLastChild }) {
  const classes = useStyles();
  if (style === "pills") {
    let attrs = {
      color: "primary",
      size: "large",
    };

    if (showLink) {
      attrs.component = Link;
      attrs.to = slug;
      attrs.variant = "contained";
    } else {
      attrs.variant = "outlined";
      attrs.disableFocusRipple = true;
      attrs.disableRipple = true;
    }
    return (
      <Button
        className={`${classes.pill} ${!showLink ? "no-link" : ""} ${
          block ? "block" : ""
        }`}
        {...attrs}
        size="sm"
        disabled={!showLink}
      >
        {title}
      </Button>
    );
  } else {
    const punc = isLastChild ? "." : ", ";
    return showLink ? (
      <>
        <Link to={slug}>{title}</Link>
        {punc}
      </>
    ) : (
      <>{`${title}${punc}`}</>
    );
  }
}

export default Pill;
