function getParent(entity, entities) {
  const parent_entity =
    entity.config.hierarchyField &&
    entity.relationships &&
    entity.relationships[entity.config.hierarchyField] &&
    entity.relationships[entity.config.hierarchyField].data &&
    typeof entities[entity.relationships[entity.config.hierarchyField].data.id] !==
      "undefined"
      ? entities[entity.relationships[entity.config.hierarchyField].data.id]
      : null;

  const parent = parent_entity
    ? {
        id: parent_entity.id,
        title: parent_entity.title,
        slug: parent_entity.slug,
        parent: parent_entity.parent,
      }
    : null;

  if (parent_entity) {
    parent.parent = getParent(parent_entity, entities);
  }
  return parent;
}

module.exports = function getAncestors(entity, entities) {
  return getParent(entity, entities);
};
