import React from "react";
// import { Link } from "gatsby";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MenuItem from "@material-ui/core/MenuItem";
import Hidden from "@material-ui/core/Hidden";
import Menu from "@material-ui/core/Menu";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { slugify } from "../../../utils";
import NavButtons from "./NavButtons";
import NavLink from "./NavLink";

function NavDesktop({
  setAnchorElements,
  getAnchorElements,
  classes,
  anchorElements,
  handleMenuClick,
  navData,
  secondaryNavData,
  handleAnchorLink,
  handleLinkClick,
  noJs,
  isPrivate,
  searchQuery,
  setSearchQuery,
  atSearchPage,
  userHandleClick,
}) {
  const handleClose = (e, path) => {
    setAnchorElements(getAnchorElements(navData));
    if (path && path.charAt(0) === "#") {
      e.preventDefault();
      handleAnchorLink(path);
    }
  };

  return (
    <div className={classes.toolbarRight}>
      <List
        component="nav"
        key="sec-nav"
        aria-labelledby="secondary navigation"
        className={classes.secondaryNavList}
      >
        {isPrivate && (
          <NavLink
            to="/"
            className={`${classes.linkText} button isPrivate`}
            onClick={(e) => handleLinkClick(e, "/")}
          >
            <ListItem button>
              <ListItemText primary="Back to public site" />
            </ListItem>
          </NavLink>
        )}
        {secondaryNavData.map(({ title, path }) => {
          if (path && searchQuery === null) {
            return (
              <NavLink
                to={path}
                key={title}
                className={`${classes.linkText} button ${isPrivate ? "isPrivate" : ""} `}
                onClick={(e) => handleLinkClick(e, path)}
              >
                <ListItem button>
                  <ListItemText primary={title} />
                </ListItem>
              </NavLink>
            );
          } else {
            return <></>;
          }
        })}
        <Hidden mdDown>
          <NavButtons
            isPrivate={isPrivate}
            classes={classes}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            atSearchPage={atSearchPage}
            userHandleClick={userHandleClick}
          />
        </Hidden>
        {!isPrivate && <span className="sep" />}
      </List>
      <List
        component="nav"
        key="prim-nav"
        aria-labelledby="main navigation"
        className={classes.navList}
      >
        {navData.map(({ title, path, children, weight }) => {
          let id = slugify(title);
          if (children) {
            return (
              <button
                key={id}
                id={`trigger-${id}`}
                className={`${classes.linkText} button ${isPrivate ? "isPrivate" : ""} `}
                onClick={handleMenuClick}
                aria-label={`Submenu of ${title}`}
                aria-controls={id}
                aria-haspopup="true"
                disabled={noJs}
              >
                <ListItem button>
                  <ListItemText primary={title} />
                  <ExpandMoreIcon className="expand" />
                </ListItem>
              </button>
            );
          } else if (path) {
            return (
              <NavLink
                to={path}
                key={title}
                className={`${classes.linkText} button ${isPrivate ? "isPrivate" : ""} `}
                onClick={(e) => handleLinkClick(e, path)}
              >
                <ListItem button>
                  <ListItemText primary={title} />
                </ListItem>
              </NavLink>
            );
          } else {
            return <></>;
          }
        })}
      </List>
      <>
        {navData
          .filter(({ children }) => typeof children !== "undefined")
          .map(({ title, children }) => {
            let id = slugify(title);
            return (
              <Menu
                id={id}
                key={id}
                anchorEl={anchorElements[id]}
                keepMounted
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElements[id])}
                getContentAnchorEl={null}
                onClose={(e) => handleClose(e)}
                className={classes.appBarSubmenu}
              >
                {children.map(({ title, path }) => (
                  <MenuItem key={title} className={classes.submenuItem}>
                    <NavLink
                      to={path}
                      className={classes.submenuLink}
                      onClick={(e) => handleClose(e, path)}
                    >
                      {title}
                    </NavLink>
                  </MenuItem>
                ))}
              </Menu>
            );
          })}
      </>
    </div>
  );
}

export default NavDesktop;
