const extractFileData = require("./extractFileData");

module.exports = function extractContactInfo(node, title, slug, drupal_url, entities) {
  const attributes = node && node.attributes ? node.attributes : null;
  if (!attributes) return null;
  const relationships = node && node.relationships ? node.relationships : null;
  const info = {
    id: attributes.id ? attributes.id : node.id,
    address: {},
    type: "company",
  };
  if (title) info.title = title;
  if (slug) info.slug = slug;
  if (attributes.field_cc_phone_number) info.phone = attributes.field_cc_phone_number;
  if (attributes.field_cc_email) info.mail = attributes.field_cc_email;
  if (attributes.field_cc_website) {
    info.website = attributes.field_cc_website.uri;
    info.website_label = attributes.field_cc_website.title
      ? attributes.field_cc_website.title
      : null;
  }
  if (attributes.field_cc_street_address)
    info.address.street = attributes.field_cc_street_address;
  if (attributes.field_cc_state) info.address.state = attributes.field_cc_state;
  if (attributes.field_cc_zipcode) info.address.zip = attributes.field_cc_zipcode;
  if (relationships) {
    if (relationships.field_cc_city && relationships.field_cc_city.data) {
      if (entities) {
        const city_e =
          typeof entities[relationships.field_cc_city.data.id] !== "undefined"
            ? entities[relationships.field_cc_city.data.id]
            : null;
        if (city_e) {
          info.address.city = city_e.title;
        }
      } else {
        info.address.city = relationships.field_cc_city.data.title;
      }
    }
    if (drupal_url) {
      let picture_rel_data = null;
      let picture_field_name = null;
      const file_field_name = "field_media_image";
      if (relationships.field_cc_logo && relationships.field_cc_logo.data) {
        picture_field_name = "field_cc_logo";
      } else if (relationships.field_cc_avatar && relationships.field_cc_avatar.data) {
        picture_field_name = "field_cc_avatar";
      } else if (relationships.field_image && relationships.field_image.data) {
        picture_field_name = "field_image";
      }
      if (picture_field_name) {
        picture_rel_data = relationships[picture_field_name].data;
      }
      if (picture_rel_data) {
        const picture_data = extractFileData(
          picture_rel_data,
          file_field_name,
          drupal_url
        );
        if (picture_data) {
          info.picture = picture_data;
        }
      }
    }
  }
  return info;
};
