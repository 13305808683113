import React from "react";

import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "gatsby";
import { excerptify } from "../../utils";

import makeStyles from "@material-ui/core/styles/makeStyles";
const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    "& a": {
      color: theme.palette.link.main,
      "&:hover, &:active, &:focus": {
        color: theme.palette.link.hover,
        textDecoration: "none",
      },
    },
    "& a, & span": {
      fontSize: "0.92em",
    },
  },
}));

function BreadcrumbsComponent({ ancestors, current }) {
  const classes = useStyles();
  return (
    <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
      {ancestors.map((_a) => (
        <Link color="inherit" to={_a.slug} key={_a.id}>
          {excerptify(_a.title)}
        </Link>
      ))}
      {current && (
        <Typography color="textPrimary" component="span">
          {excerptify(current)}
        </Typography>
      )}
    </Breadcrumbs>
  );
}

export default BreadcrumbsComponent;
