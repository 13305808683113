import React from "react";
import { navigate } from "gatsby";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Button } from "@material-ui/core";
import theme, { searchFieldStyles } from "../../../theme";

const useStyles = makeStyles((theme) => ({
  root: {
    // marginTop: 0,
    paddingTop: "25%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    WebkitBackgroundSize: "cover",
    position: "relative",
    "&.size-sm": {
      paddingTop: "16.7%",
    },
    "&.size-lg": {
      paddingTop: "50%",
    },
    // "& + .call-to-action": {
    //   marginTop: 0,
    // },
    "& + .title-bar": {
      marginTop: 0,
    },
    "& + .call-to-action": {
      marginTop: theme.spacing(-4),
    },
  },
  formContainer: {
    position: "absolute",
    top: "50%",
    left: 0,
    width: "100%",
    marginTop: -theme.spacing(3.5),
    "& form": {
      marginTop: 0,
    },
  },
  ...searchFieldStyles,
}));

function Banner({
  image,
  search,
  searchResultsPath,
  searchPlaceholder,
  size = "md",
  backgroundColor = "primary",
}) {
  const classes = useStyles();
  const [searchQuery, setSearchQuery] = React.useState(null);
  return (
    <div
      className={`${classes.root} size-${size} banner`}
      style={{ backgroundImage: `url(${image})` }}
    >
      {typeof search !== "undefined" && search && (
        <div className={classes.formContainer}>
          <form
            id="form-search"
            className={classes.search}
            noValidate
            autoComplete="off"
            onSubmit={(e) => {
              e.preventDefault();
              if (searchQuery && searchQuery.length > 0) {
                navigate(`${searchResultsPath}?q=${searchQuery}&size=n_1000_n`);
              }
            }}
          >
            <input
              type="text"
              placeholder={searchPlaceholder}
              className={classes.searchInput}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Button
              variant="contained"
              color="secondary"
              className={classes.searchButton}
              type="submit"
              style={{
                backgroundColor: backgroundColor
                  ? theme.palette[backgroundColor].main
                  : "none",
              }}
            >
              Search
            </Button>
          </form>
        </div>
      )}
    </div>
  );
}

export default Banner;
