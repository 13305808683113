async function fetchAnonymousContent(url, method = "GET", body = null, headers = null) {
  if (body && !(body instanceof File)) {
    body = JSON.stringify(body);
  }
  var init = {
    method: method,
    headers: {
      "Content-Type": "application/vnd.api+json",
    },
  };
  Object.assign(init.headers, headers);
  if (body) {
    init.body = body;
  }
  return fetch(url, init)
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Something went wrong");
      }
    })
    .then((response) => {
      if (response && response.data && response.data.id) {
        return response;
      } else {
        throw new Error("Error while requesting data");
      }
    })
    .catch(function (err) {
      console.error(err);
    });
}
export default fetchAnonymousContent;
