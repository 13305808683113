

const grey = {
  "50": "#fbfbfb",
  "100": "#f5f5f5",
  "200": "#eeeeee",
  "300": "#e0e0e0",
  "400": "#bdbdbd",
  "500": "#9e9e9e",
  "600": "#757575",
  "700": "#616161",
  "800": "#3b3b3b",
  "900": "#212121",
}

const primary = "#006699"
const primaryDark = "#003254"
const secondary = "#cc0066"
const yellow = "#ffdf88"
const orange = "#ffa367"

const palette = {
  purple: {
    main: "#6f3178",
  },
  deepPurple: {
    main: "#37236a",
  },  
  primary: {
    main: primary,
    dark: primaryDark,
  },
  secondary: {
    main: secondary,
  },
  yellow: {
    main: yellow,
  },
  orange: {
    main: orange,
  },
  grey: {
    ...grey,
    lighter: grey["100"],
    light: grey["200"],
    main: grey["200"],
    dark: grey["300"],
    darker: grey["400"],
  },
  dark: {
    main: grey["800"],
    darker: grey["900"]
  },
  light: {
    main: grey["100"],
  },
  link: {
    main: primary,
    hover: primaryDark
  },
  appBar: {
    background: "white",
    foreground: primary,

  },
  appBarPrivate: {
    background: primaryDark,
    foreground: "white",
  },
  titleBar: {
    background: secondary,
    foreground: "white",
  },
  footer: {
    background: primary,
    foreground: "white",
  },
  // headingsUnderline: orange,
};

export default palette;