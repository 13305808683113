import { CircularProgress } from "@material-ui/core";
import React from "react";
import { Media } from "../";
import { SingleContext } from "../../layouts/SingleBody";
import {
  AuthContext,
  fetchAuthenticatedContent,
} from "@parallelpublicworks/understory-auth";

function Resources({ media, autoFetch }) {
  /**
   * media: [
   *  {
   *    format: pdf, jpg, png, ppt, pptx, xls, xlsx, url, other
   *    url: external or relative
   *    author: uuid,
   *    id: uuid
   *    title: media title
   *  }
   * ]
   */

  const [mediaEntities, setMediaEntities] = React.useState(media);
  const [doneFetching, setDoneFetching] = React.useState(false);
  const [, dispatch] = React.useContext(AuthContext);
  const { singleId, singleType } = React.useContext(SingleContext);

  const field_key_name =
    singleType && singleType.includes("--") ? singleType.split("--")[1] : null;

  async function getRelatedMedia() {
    let fetchedEntities = await fetchAuthenticatedContent(
      dispatch,
      // `media/private_document?filter[field_${field_key_name}.id][operator]=IN&filter[field_${field_key_name}.id][value]=${singleId}`,
      "media/private_document",
      "get"
    );

    if (fetchedEntities && Array.isArray(fetchedEntities.data)) {
      const media_entities = fetchedEntities.data
        .filter(
          (m) =>
            m.relationships[`field_${field_key_name}`] &&
            Array.isArray(m.relationships[`field_${field_key_name}`].data) &&
            m.relationships[`field_${field_key_name}`].data
              .map((a_rel) => a_rel.id)
              .includes(singleId)
        )
        .filter((m) => !media.find((a_media) => a_media.id === m.id))
        .map((m) => {
          return {
            id: m.id,
            title: m.attributes.name,
            url: `/download?media=${m.id}`,
            author:
              m.relationships && m.relationships.uid && m.relationships.uid.data
                ? m.relationships.uid.data.id
                : null,
          };
        });
      setMediaEntities(media.concat(media_entities));
    }
  }

  React.useEffect(() => {
    if (autoFetch) {
      getRelatedMedia();
      setDoneFetching(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoFetch]);

  if (mediaEntities.length === 0 && !autoFetch) return <></>;
  if (mediaEntities.length === 0 && autoFetch && !doneFetching)
    return <CircularProgress />;
  return (
    <div>
      {/* {autoFetch && (
        <Alert severity="info" style={{ marginBottom: 10 }}>
          Recently uploaded resources may take a few minutes to become available
        </Alert>
      )} */}
      <Media media={mediaEntities} />
    </div>
  );
}

export default Resources;
