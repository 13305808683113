import React, { useState } from "react";
import { Paper } from "@material-ui/core";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import { Image } from "../";
import makeStyles from "@material-ui/core/styles/makeStyles";
const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    display: "block",
    "&:focus, &:hover, &:active": {
      "& .MuiSvgIcon-root": {
        transform: "scale(1.1)",
      },
    },
    "& figure": {
      margin: 0,
    },
  },
  icon: {
    height: "100%",
    position: "absolute",
    left: "50%",
    top: 0,
    fill: "#fff",
    width: 100,
    marginLeft: -50,
    "&.size-lg": {
      width: 150,
      marginLeft: -75,
    },
    [theme.breakpoints.down("lg")]: {
      width: "80px !important",
      marginLeft: "-40px !important",
    },
    [theme.breakpoints.down("sm")]: {
      width: "60px !important",
      marginLeft: "-30px !important",
    },
  },
}));

function Video({ thumbnail, url, orientation = "landscape", size = "md", onClick }) {
  const classes = useStyles();
  const [elevation, setElevation] = useState(1);
  if (!url) return <></>;
  return (
    <Paper
      className={`${classes.root} video-block`}
      component="a"
      href={url}
      target="_blank"
      rel="noreferrer"
      elevation={elevation}
      onFocus={(e) => setElevation(10)}
      onBlur={(e) => setElevation(10)}
      onMouseEnter={(e) => setElevation(10)}
      onMouseLeave={(e) => setElevation(1)}
      onClick={(e) => {
        e.preventDefault();
        if (onClick) onClick(e);
      }}
    >
      <PlayCircleOutlineIcon className={`${classes.icon} size-${size}`} />
      <Image src={thumbnail} orientation={orientation}></Image>
    </Paper>
  );
}

export default Video;
