import React from "react";
import { Grid } from "@material-ui/core";
import { Container } from "../";
import makeStyles from "@material-ui/core/styles/makeStyles";
const useStyles = makeStyles((theme) => ({
  col: {
    "&.col-3": {
      fontSize: "0.9em",
      lineHeight: 1.5,
    },
  },
  item: {
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(3),
      width: "100%",
    },
  },
  wrapper: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

function Row({ children, layout, spacing = 4, fullWidth = false, id }) {
  const classes = useStyles();
  if (!children || (Array.isArray(children) && children.length > 3)) return <></>;
  children = Array.isArray(children) ? children : [children];
  let col_sizes = [];
  if (children.length === 1) {
    col_sizes = [12];
  } else if (children.length === 2) {
    if (layout === "narrow-left") {
      col_sizes = [4, 8];
    } else if (layout === "narrow-right") {
      col_sizes = [8, 4];
    } else {
      col_sizes = [6, 6];
    }
  } else if (children.length === 3) {
    if (layout === "narrow-sides") {
      col_sizes = [3, 6, 3];
    } else {
      col_sizes = [4, 4, 4];
    }
  }

  return (
    <Container className={!fullWidth && classes.wrapper} root={fullWidth}>
      <Grid container spacing={spacing} className="row-grid-container">
        {children.map((node, i) => (
          <Grid
            item
            lg={col_sizes[i]}
            className={`${i > 0 ? classes.item : ""} ${classes.col} row-grid-item col-${
              col_sizes[i]
            }`}
            key={`${id}-${i}`}
          >
            {node}
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default Row;
