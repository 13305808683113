import React from "react";
import { SiteContext } from "./Main";
import { Navbar, Modal, Footer, AlertBanner } from "../components";
import { isIframe } from "../utils";

function Private({ children }) {
  const [closeAlert, setCloseAlert] = React.useState(false);

  const {
    privateNavData,
    modalURL,
    setModalURL,
    footerData,
    footerNavData,
    alertBanners,
  } = React.useContext(SiteContext);

  const is_frame = isIframe();

  const handleClose = function () {
    setCloseAlert(true);
  };

  const privateAlert = alertBanners
    ? alertBanners.find((ab) => ab.show_alert === true && ab.private_alert === true)
    : null;

  return (
    <div>
      {!is_frame && <Navbar navData={privateNavData} secondaryNavData={[]} isPrivate />}
      {privateAlert && closeAlert !== true && (
        <AlertBanner {...privateAlert} handleClose={handleClose} />
      )}
      <main role="main">{children}</main>
      <Modal modalURL={modalURL} setModalURL={setModalURL} />
      {!is_frame && <Footer footerData={footerData} footerNavData={footerNavData} />}
    </div>
  );
}

export default Private;
