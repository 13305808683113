import React from "react";
import {
  Heading,
  Text,
  Banner,
  Spacer,
  CallToAction,
  Image,
  Video,
  Resources,
  VCard,
  Buttons,
  Column,
  AccordionTab,
  MediaUpload,
  AnchorNav,
  Highlight,
  EmbedCode,
  Form,
  ContactButton,
  Gallery,
  PubMed,
} from "../../components";
import { Blocks } from ".";
import {
  extractMediaData,
  extractFileData,
  extractLinkData,
  extractVideoData,
  extractResourcesData,
  extractContactInfo,
  slugify,
} from "../../utils";
import { SiteContext } from "../Main";

function Block(node) {
  const { setModalURL, userData, drupalUrl } = React.useContext(SiteContext);
  const { id, type, title, attributes, relationships, fullWidth, isSidebar, data } = node;
  switch (type) {
    case "node--heading":
      return (
        <Heading
          id={slugify(title)}
          type={attributes ? attributes.field_heading_type : "h2"}
          isSidebar={isSidebar}
        >
          {title}
        </Heading>
      );
    case "node--text":
      return (
        <Text
          html={attributes && attributes.body ? attributes.body.processed : ""}
          isSidebar={isSidebar}
        />
      );
    case "node--spacer":
      return (
        <Spacer
          amount={attributes && attributes.field_amount ? attributes.field_amount : 6}
        />
      );
    case "node--image":
      const image = extractMediaData(
        node,
        "field_banner_media_image",
        "field_media_image",
        drupalUrl
      );
      return image ? (
        <Image
          src={image.src}
          orientation={
            attributes && attributes.field_image_orientation
              ? attributes.field_image_orientation
              : null
          }
          href={image.src}
          alt={image.alt}
          caption={image.title}
          onClick={(e) => {
            setModalURL(image.src);
          }}
        />
      ) : (
        <></>
      );
    case "node--video":
      const video = extractVideoData(node, "field_media_video", drupalUrl);
      return video ? (
        <Video
          thumbnail={video.thumbnail.url}
          url={video.url}
          onClick={(e) => {
            setModalURL(video.url);
          }}
        />
      ) : (
        <></>
      );
    case "node--gallery":
      let items = [];
      let cols = node?.attributes?.field_columns
        ? parseInt(node.attributes.field_columns)
        : null;
      if (
        node?.relationships?.field_media?.data &&
        Array.isArray(node.relationships.field_media.data)
      ) {
        items = node.relationships.field_media.data
          .map((itm) => {
            const item_data =
              itm.type === "media--image"
                ? extractFileData(itm, "field_media_image", drupalUrl)
                : extractVideoData(null, "field_media_video", drupalUrl, itm);
            if (item_data) {
              item_data.type = itm.type;
            }
            return item_data;
          })
          .filter((itm) => itm !== null && typeof itm !== "undefined");
      }
      return (
        <Gallery cols={cols}>
          {items.map((itm) => {
            return itm.type === "media--image" ? (
              <Image
                src={itm.src}
                orientation={
                  attributes && attributes.field_image_orientation
                    ? attributes.field_image_orientation
                    : null
                }
                href={itm.src}
                alt={itm.alt}
                caption={itm.title}
                onClick={(e) => {
                  setModalURL(itm.src);
                }}
              />
            ) : (
              <Video
                thumbnail={itm.thumbnail.url}
                url={itm.url}
                onClick={(e) => {
                  setModalURL(itm.url);
                }}
              />
            );
          })}
        </Gallery>
      );
    case "node--banner_image":
      const banner_image = extractMediaData(
        node,
        "field_banner_media_image",
        "field_media_image",
        drupalUrl
      );
      return banner_image ? (
        <Banner
          image={banner_image.src}
          size={attributes ? attributes.field_banner_size : "sm"}
          search={attributes && attributes.field_display_search_form === true}
          searchResultsPath={
            attributes.field_search_results_path
              ? attributes.field_search_results_path
              : "/search"
          }
          searchPlaceholder={
            attributes && attributes.field_search_placeholder
              ? attributes.field_search_placeholder
              : "Search this website..."
          }
        />
      ) : (
        <></>
      );
    case "node--buttons":
      const primary_button =
        attributes && attributes.field_primary_button
          ? extractLinkData(attributes.field_primary_button)
          : null;
      const secondary_button =
        attributes && attributes.field_secondary_button
          ? extractLinkData(attributes.field_secondary_button)
          : null;
      return primary_button || secondary_button ? (
        <Buttons
          primary={primary_button}
          secondary={secondary_button}
          singleColumn={isSidebar}
        />
      ) : (
        <></>
      );
    case "node--contact_button":
      return (
        <ContactButton
          label={
            attributes && attributes.field_button_label
              ? attributes.field_button_label
              : "Contact Lead"
          }
        />
      );
    case "node--media_upload":
      return (
        <MediaUpload
          key={id}
          description={attributes && attributes.body ? attributes.body.value : ""}
          title={attributes ? attributes.title : "Upload Resource"}
        />
      );
    case "node--call_to_action":
      const bg_image =
        relationships && relationships.field_background_image
          ? extractMediaData(
              node,
              "field_background_image",
              "field_media_image",
              drupalUrl
            )
          : null;
      const logo_image =
        relationships && relationships.field_logo
          ? extractMediaData(node, "field_logo", "field_media_image", drupalUrl)
          : null;
      const link =
        attributes && attributes.field_to ? extractLinkData(attributes.field_to) : null;

      return (
        <CallToAction
          id={id}
          fullWidth={fullWidth}
          title={title}
          subtitle={
            attributes && attributes.field_subtitle ? attributes.field_subtitle : null
          }
          body={
            attributes && attributes.body && attributes.body.processed
              ? attributes.body.processed
              : null
          }
          backgroundColor={
            attributes && attributes.field_background_color
              ? attributes.field_background_color
              : null
          }
          backgroundImage={bg_image ? bg_image.src : null}
          logo={logo_image ? logo_image.src : null}
          headingType={
            attributes && attributes.field_cta_heading_type
              ? attributes.field_cta_heading_type
              : "h3"
          }
          to={link ? link.to : null}
          toLabel={link ? link.label : null}
          rounded={!fullWidth}
          search={attributes && attributes.field_display_search_form === true}
          newsletter={attributes && attributes.field_display_newsletter_form === true}
          searchPlaceholder={
            attributes && attributes.field_search_placeholder
              ? attributes.field_search_placeholder
              : "Search this website..."
          }
          newsletterPlaceholder={
            attributes && attributes.field_newsletter_placeholder
              ? attributes.field_newsletter_placeholder
              : "Enter your email..."
          }
          searchResultsPath={
            attributes && attributes.field_search_results_path
              ? attributes.field_search_results_path
              : "/search"
          }
        />
      );
    case "node--resources":
      const resources =
        relationships && relationships.field_media_document
          ? extractResourcesData(
              relationships.field_media_document.data,
              userData,
              drupalUrl
            )
          : [];
      return (
        <Resources
          media={resources}
          autoFetch={attributes && attributes.field_auto_fetch === true}
        />
      );
    case "node--accordion_tab":
      const accordion_blocks =
        relationships && relationships.field_accordion_components
          ? relationships.field_accordion_components.data
          : null;
      return accordion_blocks ? (
        <AccordionTab title={title} id={slugify(title)}>
          <Column>
            <Blocks
              nodes={accordion_blocks}
              isSidebar={isSidebar}
              fullWidth={fullWidth}
            />
          </Column>
        </AccordionTab>
      ) : (
        <></>
      );
    case "node--contact_card":
      const info = extractContactInfo(
        node,
        node.attributes.field_cc_name,
        node.slug,
        drupalUrl
      );
      return info ? <VCard {...info} isSidebar={isSidebar} /> : <></>;
    case "node--anchor_links":
      return <AnchorNav data={data} />;
    case "node--embed_code":
      return <EmbedCode code={attributes.field_embed_code} />;
    case "node--form":
      return (
        <Form
          id={id}
          title={title}
          attributes={attributes}
          relationships={relationships}
        />
      );
    case "node--highlight":
      const high_link =
        attributes && attributes.field_to ? extractLinkData(attributes.field_to) : null;
      let thumb_video;
      let thumb_image;
      if (relationships.field_image && relationships.field_image.data) {
        thumb_video =
          relationships.field_image.data.type === "media--video"
            ? extractVideoData(null, null, drupalUrl, relationships.field_image.data)
            : null;
        thumb_image =
          relationships.field_image.data.type === "media--image"
            ? extractFileData(
                relationships.field_image.data,
                "field_media_image",
                drupalUrl
              )
            : null;
      }

      const thumbnail = thumb_video ? (
        <Video
          thumbnail={thumb_video.thumbnail.url}
          url={thumb_video.url}
          onClick={(e) => {
            setModalURL(thumb_video.url);
          }}
        />
      ) : thumb_image ? (
        <Image
          src={thumb_image.src}
          orientation={
            attributes && attributes.field_image_orientation
              ? attributes.field_image_orientation
              : null
          }
          href={thumb_image.src}
          alt={thumb_image.alt}
          caption={thumb_image.title}
          onClick={(e) => {
            setModalURL(thumb_image.src);
          }}
        />
      ) : (
        <></>
      );
      return (
        <Highlight
          title={attributes.title}
          to={high_link ? high_link.to : null}
          toLabel={high_link ? high_link.label : null}
        >
          {thumbnail}
          <Text html={attributes.body.processed} />
        </Highlight>
      );
    case "node--pubmed":
      let term = attributes.field_pubmed_search_term ? attributes.field_pubmed_search_term : ``;
      return <PubMed searchTerm={term} />
    default:
      return <></>;
  }
}

export default Block;
