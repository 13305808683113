import React from "react";

import { Link } from "gatsby";
import { useFormik } from "formik";
import * as yup from "yup";
import { Paper, Typography, Button } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Field } from "../";
import { recaptchaSubmit, jsonRequest } from "../../utils";
import { SiteContext } from "../../layouts/Main";
import makeStyles from "@material-ui/core/styles/makeStyles";

const validationSchema = yup.object({
  name: yup.string().required("Email is required").email("Enter a valid email address"),
  temp_pass: yup
    .string()
    .min(10, "Enter a valid temporary password")
    .required("Enter your temporary password"),
  new_pass: yup
    .string()
    .required("Enter a password")
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}$/,
      "8 characters minimum, include at least one number, one uppercase letter and one special character"
    ),
  new_pass_confirm: yup
    .string()
    .required("Passwords do not match")
    .oneOf([yup.ref("new_pass"), null], "Passwords do not match"),
});

const useStyles = makeStyles((theme) => ({
  login: {
    padding: theme.spacing(4, 0),
    maxWidth: 560,
    margin: "0 auto",
  },
  title: {
    textAlign: "center",
  },
  paper: {
    padding: theme.spacing(3),
    "& > *:not(:first-child)": {
      marginTop: `${theme.spacing(2)}px !important`,
      width: "100%",
    },
  },
  links: {
    textAlign: "center",
  },
}));

function SetNewPasswordForm({ initialMail = "" }) {
  const classes = useStyles();
  const { drupalUrl, privateRoute } = React.useContext(SiteContext);
  const [error, setError] = React.useState(null);
  const [allSet, setAllSet] = React.useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleSubmitRequest = async (values, setSubmitting, setError) => {
    setSubmitting(true);
    try {
      let payload = { ...values };
      delete payload.new_pass_confirm;
      const response = await jsonRequest(
        drupalUrl,
        "user/lost-password-reset?_format=json",
        "POST",
        payload
      );
      if (response.ok) {
        setAllSet(true);
      } else {
        let data = await response.json();
        throw new Error(data?.message || "Unable to set new password.");
      }
    } catch (err) {
      setSubmitting(false);
      setError(err.message);
    }
  };

  const setForm = useFormik({
    validationSchema,
    initialValues: {
      name: initialMail,
      temp_pass: "",
      new_pass: "",
      new_pass_confirm: "",
    },
    onSubmit: async (values, { setSubmitting }) => {
      setError(null);
      const valid = await recaptchaSubmit(executeRecaptcha, drupalUrl);
      if (!valid) {
        setError("Failed Recaptcha", drupalUrl);
      } else {
        await handleSubmitRequest(values, setSubmitting, setError);
      }
    },
  });

  if (!allSet) {
    return (
      <div className={classes.login}>
        <Paper className={classes.paper} component="form" onSubmit={setForm.handleSubmit}>
          {error ? (
            <Alert severity="error">{error}</Alert>
          ) : initialMail.length > 0 ? (
            <Alert severity="success">
              You should receive an email shortly with a temporary password
            </Alert>
          ) : (
            <Typography>
              Please use the temporary password sent to your email to reset your password{" "}
            </Typography>
          )}
          <Field
            form={setForm}
            label="Email Address:"
            name="name"
            variant="outlined"
            type="email"
          />
          <Field
            form={setForm}
            label="Temporary Password:"
            name="temp_pass"
            variant="outlined"
            type="password"
          />
          <Field
            form={setForm}
            label="New Password:"
            name="new_pass"
            variant="outlined"
            type="password"
          />
          <Field
            form={setForm}
            label="Confirm New Password:"
            name="new_pass_confirm"
            variant="outlined"
            type="password"
          />
          <Button
            variant="contained"
            color="secondary"
            size="large"
            type="submit"
            disabled={setForm.isSubmitting}
          >
            Set New Password
          </Button>
        </Paper>
      </div>
    );
  } else {
    return (
      <div className={classes.login}>
        <Paper className={classes.paper}>
          <Alert severity="success">
            You're all set. <Link to={privateRoute}>Login</Link>
          </Alert>
        </Paper>
      </div>
    );
  }
}

export default SetNewPasswordForm;
