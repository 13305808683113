import React from "react";

import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import { Container } from "../";
import NavLink from "./Navbar/NavLink";
import ReactHtmlParser from "react-html-parser";

import makeStyles from "@material-ui/core/styles/makeStyles";
const useStyles = makeStyles((theme) => ({
  footer: {
    padding: theme.spacing(6, 0, 4),
    overflow: "hidden",
    backgroundColor: theme.palette.footer.background,
    color: theme.palette.footer.foreground,
    "& a": {
      color: theme.palette.footer.foreground,
    },
    "& p, & h6, & a": {
      fontSize: "0.9em",
      lineHeight: 1.4,
      marginTop: 0,
    },
  },
  cols: {
    "& > *": {
      [theme.breakpoints.down("sm")]: {
        paddingBottom: "0 !important",
      },
    },
  },
  subLinkText: {
    display: "block",
    fontSize: "0.9em",
    "& .MuiListItemText-root": {
      display: "inline-block",
      verticalAlign: "middle",
    },
  },
  linkText: {
    display: "inline-block",
    alignItems: "center",
    paddingRight: theme.spacing(3),
    fontSize: "0.9em",
    "& .MuiListItemText-root": {
      display: "inline-block",
      verticalAlign: "middle",
    },
  },
  content: {
    "& > *:not(:first-child)": {
      marginTop: theme.spacing(1),
      marginBottom: 0,
    },
  },
  submenus: {
    "& nav": {
      marginTop: theme.spacing(2),
    },
    "& .MuiListItemText-root": {
      flex: "none",
    },
  },
  footerNavList: {
    display: "block",
    paddingLeft: 0,
    width: "100%",
    marginTop: theme.spacing(4),
    paddingTop: theme.spacing(2),
    position: "relative",
    "& b": {
      width: "100%",
      display: "block",
      height: 1,
      backgroundColor: theme.palette.footer.foreground,
      opacity: 0.2,
      position: "absolute",
      top: 0,
      left: 0,
    },
  },
}));

function Footer({ footerData, footerNavData }) {
  const classes = useStyles();
  let submenus = footerNavData.filter((nv) => typeof nv.children !== "undefined");
  let links = footerNavData.filter((nv) => typeof nv.path !== "undefined");
  let cols = footerData ? footerData.filter((d) => d.value && d.value.length > 0) : [];
  cols = cols.map((d, i) => ({
    id: `col-${i}`,
    content: <>{ReactHtmlParser(d.value)}</>,
    className: classes.content,
    width: 3,
  }));

  if (submenus.length > 0) {
    cols = cols.concat(
      submenus.map((submenu, i) => ({
        className: classes.content,
        id: `sm-${i}`,
        width: 3,
        content: (
          <div key={`sm-${i}`}>
            <Typography variant="h6">{submenu.title}</Typography>
            <nav>
              {submenu.children.map(({ title, path }) => (
                <NavLink to={path} key={title} className={classes.subLinkText}>
                  <ListItemText primary={title} />
                </NavLink>
              ))}
            </nav>
          </div>
        ),
      }))
    );
  }
  if (cols.length === 0 && links.length === 0) {
    return <></>;
  }
  return (
    <footer className={classes.footer}>
      <Container root>
        {cols.length > 0 && (
          <Grid container spacing={6} className={classes.cols}>
            {cols.map((c) => (
              <Grid item key={c.id} xs={12} md={c.width} className={c.className}>
                {c.content}
              </Grid>
            ))}
          </Grid>
        )}
        <nav className={classes.footerNavList}>
            <b></b>
            <NavLink to="" className={classes.linkText}>
                <ListItemText primary={`© ${new Date().getFullYear()} All Rights Reserved`} />
            </NavLink>
            {links.length > 0 && links.map(({ title, path }) => (
              <NavLink to={path} key={title} className={classes.linkText}>
                <ListItemText primary={title} />
              </NavLink>
            ))}
          </nav>
      </Container>
    </footer>
  );
}

export default Footer;
