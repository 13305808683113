import React from "react";
import { useFormik } from "formik";
import { Paper, Button, CircularProgress } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {
  slugify,
  recaptchaSubmit,
  getNodeRelationshipsData,
  handleEmail,
} from "../../utils";
import Field from "./Field";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import * as yup from "yup";
import { SiteContext } from "../../layouts/Main";
import { DefinitionsContext } from "../../../../../src/layout/Definitions";
import { SingleContext } from "../../layouts/SingleBody";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1, 3, 3),
    textAlign: "center",
    "& > *": {
      marginTop: theme.spacing(2),
      width: "100%",
    },
  },
  button: {
    marginTop: theme.spacing(4),
    maxWidth: 480,
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

function FormComponent({
  fields,
  initialValues,
  attributes,
  relationships,
  relationshipFieldSchemas,
  validationSchema,
  relationshipFieldNames,
  nodeRelationshipsData,
}) {
  const classes = useStyles();
  const { drupalUrl, adminEmail, siteName, siteUrl, accentColor } =
    React.useContext(SiteContext);
  const { singleId, singleNid, singleType, singleTitle, singleSlug } =
    React.useContext(SingleContext);
  const [loading, setLoading] = React.useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [error, setError] = React.useState(null);
  const [msg, setMsg] = React.useState(null);
  const [submissionsClosed, setSubmissionsClosed] = React.useState(false);
  const submitLabel = attributes.field_form_submit_label
    ? attributes.field_form_submit_label
    : "Submit";
  const closed_msg = "This form is now closed as it reached its limit of submissions";
  const response_heading = `<h1 style="text-align: center; color: #000000; line-height: 1.5">${singleTitle}</h1>`;
  const default_response_msg =
    "Thank you for your submission! We have received your information.";
  const titlefields = ["field_fname", "field_lname"];
  const mailfields = ["field_email", "mail"];
  const unpatchableAttributes = ["mail"];
  const limit_submissions = attributes.field_form_limit_submissions;

  const email_to = attributes.field_form_recipient_email;
  const save_to =
    relationships.field_form_save_to_content_type &&
    relationships.field_form_save_to_content_type.data &&
    relationships.field_form_save_to_content_type.data.attributes &&
    relationships.field_form_save_to_content_type.data.attributes.drupal_internal__type
      ? {
          type: `node--${relationships.field_form_save_to_content_type.data.attributes.drupal_internal__type}`,
          name: relationships.field_form_save_to_content_type.data.attributes.name,
          endpoint: `node/${relationships.field_form_save_to_content_type.data.attributes.drupal_internal__type}`,
        }
      : null;

  function getSubmissionsCount() {
    return new Promise(function (resolve, reject) {
      const creds = btoa(
        `${process.env.BASIC_REST_USERNAME}:${process.env.BASIC_REST_PASSWORD}`
      );
      const url = `${drupalUrl}/get_submissions_count/${save_to.type.replace(
        "node--",
        ""
      )}|${singleNid}`;

      fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Basic ${creds}`,
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            console.error("response", `${response.status} - ${response.statusText}`);
            reject();
          }
        })
        .then((response) => {
          if (response && typeof response.data !== "undefined") {
            resolve(response.data);
          } else {
            reject();
          }
        })
        .catch(function () {
          reject();
        });
    });
  }

  function handleError(msg, setSubmitting) {
    console.error("ERR", msg);
    if (setSubmitting) {
      setSubmitting(false);
    }
    setMsg(msg);
    setError(true);
    setLoading(false);
  }

  async function checkSubmissionsClosed() {
    if (save_to && limit_submissions) {
      const submissions_count = await getSubmissionsCount();
      const is_closed =
        submissions_count && submissions_count >= limit_submissions ? true : false;
      return is_closed;
    }
    return false;
  }

  React.useEffect(() => {
    (async () => {
      try {
        const closed = await checkSubmissionsClosed();
        setSubmissionsClosed(closed);
      } catch (error) {
        setSubmissionsClosed(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (submissionsClosed) {
      setError(true);
      setMsg(closed_msg);
    }
  }, [submissionsClosed]);

  const form = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues,
    onSubmit: async (values, { setSubmitting }) => {
      setError(null);
      setLoading(true);

      const valid = await recaptchaSubmit(executeRecaptcha, drupalUrl);
      if (!valid) {
        setMsg("Failed Recaptcha");
        setError(true);
      } else {
        setSubmitting(true);
        let subject = "";
        for (const a_title_field_name of titlefields) {
          if (values[a_title_field_name]) {
            subject += ` ${values[a_title_field_name]}`;
          }
        }
        let applicant_email;
        for (const a_mail_field_name of mailfields) {
          if (values[a_mail_field_name]) {
            applicant_email = values[a_mail_field_name];
            break;
          }
        }
        if (subject.length === 0 && applicant_email) {
          subject = applicant_email;
        }
        subject =
          save_to && save_to.name
            ? `${save_to.name} by ${subject} at: ${singleTitle}`
            : email_to
            ? `Message from ${subject}`
            : subject;

        const form_data = {
          data: {
            attributes: { title: subject },
            relationships: {
              field_related_entity: {
                data: {
                  id: singleId,
                  type: singleType,
                },
              },
            },
          },
        };
        const email_read_more_label = attributes.field_learn_more_label
          ? attributes.field_learn_more_label.replace("TITLE", singleTitle)
          : "Click here to learn more";
        const email_read_more_url = `${siteUrl}${singleSlug}`;
        const email_data = [];
        if (save_to) {
          form_data.data.type = save_to.type;
        }
        for (const [field, value] of Object.entries(values)) {
          if (value) {
            const _field_conf = email_to ? fields.find((f) => f.name === field) : null;
            if (relationshipFieldNames.includes(field)) {
              if (
                Array.isArray(nodeRelationshipsData[field]) &&
                nodeRelationshipsData[field].length > 0
              ) {
                const field_config = relationshipFieldSchemas[field];
                const identifier_num_key = field_config.kind === "node" ? "nid" : "tid";
                const rel_data_item = field_config.multiple
                  ? nodeRelationshipsData[field].filter((item) =>
                      value.includes(parseInt(item[identifier_num_key]))
                    )
                  : nodeRelationshipsData[field].find(
                      (item) => parseInt(item[identifier_num_key]) === parseInt(value)
                    );
                const rel_data =
                  field_config.multiple && rel_data_item.length > 0
                    ? rel_data_item.map((rel_d) => ({
                        id: rel_d.id,
                        type: rel_d.type,
                      }))
                    : typeof rel_data_item !== "undefined"
                    ? { id: rel_data_item.id, type: rel_data_item.type }
                    : null;

                if (rel_data) {
                  form_data.data.relationships[field] = { data: rel_data };
                }
                if (email_to) {
                  const rel_email_data =
                    field_config.multiple && rel_data_item.length > 0
                      ? rel_data_item.map((rel_d) => rel_d.title)
                      : typeof rel_data_item !== "undefined"
                      ? [rel_data_item.title]
                      : null;
                  if (rel_email_data) {
                    email_data.push(
                      `<strong>${_field_conf.label.replaceAll(
                        " *",
                        ""
                      )}:</strong> ${rel_email_data.join(", ")}`
                    );
                  }
                }
              }
            } else {
              if (!unpatchableAttributes.includes(field)) {
                form_data.data.attributes[field] = value;
              }
              if (email_to) {
                const read_val =
                  _field_conf.options &&
                  _field_conf.options.length > 0 &&
                  _field_conf.options.find((op) => op.val === value)
                    ? _field_conf.options.find((op) => op.val === value).title
                    : value;
                email_data.push(
                  `<strong>${_field_conf.label.replaceAll(
                    " *",
                    ""
                  )}:</strong> ${read_val}`
                );
              }
            }
          }
        }
        try {
          const closed = await checkSubmissionsClosed();
          setSubmissionsClosed(closed);
          if (closed) {
            throw new Error(closed_msg);
          } else {
            if (email_to) {
              await handleEmail(
                drupalUrl,
                email_to,
                subject,
                [response_heading].concat(email_data),
                adminEmail,
                siteName,
                siteUrl,
                null,
                null,
                accentColor
              )
                .then(() => {
                  if (!save_to) {
                    setMsg(
                      attributes.field_response_message &&
                        attributes.field_response_message.value
                        ? attributes.field_response_message.value
                        : default_response_msg
                    );
                    setError(false);
                  }
                })
                .catch((err) => {
                  handleError(err, setSubmitting);
                });
            }
            if (save_to) {
              const creds = btoa(
                `${process.env.BASIC_AUTH_USERNAME}:${process.env.BASIC_AUTH_PASSWORD}`
              );
              fetch(`${drupalUrl}/jsonapi/${save_to.endpoint}`, {
                method: "POST",
                headers: {
                  "Content-type": "application/vnd.api+json",
                  Authorization: `Basic ${creds}`,
                },
                body: JSON.stringify(form_data),
              })
                .then((response) => {
                  if (response.ok) {
                    return response.json();
                  } else {
                    throw new Error("Something went wrong");
                  }
                })
                .then((response) => {
                  if (response && response.data && response.data.id) {
                    setMsg(
                      attributes.field_response_message &&
                        attributes.field_response_message.value
                        ? attributes.field_response_message.value
                        : default_response_msg
                    );
                    setError(false);
                  } else {
                    throw new Error("Error while saving your information");
                  }
                })
                .catch(function (err) {
                  handleError(err.message, setSubmitting);
                });
            }

            if (applicant_email && save_to) {
              const applicant_subject = attributes.field_response_subject
                ? attributes.field_response_subject.replace("TITLE", singleTitle)
                : `Thank you for registering to: ${singleTitle}`;
              const msg_header = [response_heading];
              msg_header.push(
                attributes.field_response_message &&
                  attributes.field_response_message.value
                  ? attributes.field_response_message.value
                  : default_response_msg
              );

              const applicant_message = msg_header.concat(email_data);

              handleEmail(
                drupalUrl,
                applicant_email,
                applicant_subject,
                applicant_message,
                adminEmail,
                siteName,
                siteUrl,
                email_read_more_url,
                email_read_more_label,
                accentColor
              );
            }
          }
        } catch (error) {
          handleError(error.message, setSubmitting);
        }
      }
    },
  });
  if (error === false) {
    return (
      <Alert severity="success">
        <div dangerouslySetInnerHTML={{ __html: msg }} />
      </Alert>
    );
  }
  if (fields.length > 0) {
    return (
      <>
        {error === true && <Alert severity="error">{msg}</Alert>}
        <Paper className={classes.paper} component="form" onSubmit={form.handleSubmit}>
          {fields.map((field) => {
            let can_render = true;
            if (field.depends_on !== null) {
              if (!field.depends_on_val) {
                can_render = !!form.values[field.depends_on];
              } else {
                can_render = field.depends_on_val.includes(form.values[field.depends_on]);
              }
            }
            return can_render ? (
              <Field
                key={field.id}
                form={form}
                {...field}
                disabled={loading || submissionsClosed}
              />
            ) : (
              <span key={field.id}></span>
            );
          })}
          {loading ? (
            <CircularProgress />
          ) : (
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              className={classes.button}
              disabled={submissionsClosed}
            >
              {submitLabel}
            </Button>
          )}
        </Paper>
      </>
    );
  }
  return <></>;
}

function Form({ attributes, relationships }) {
  const { graphqlFieldsConfig, graphqlData } = React.useContext(DefinitionsContext);

  const [formConfig, setFormConfig] = React.useState(null);

  React.useEffect(() => {
    const initialValues = {};
    const validation_config = {};
    const relationshipFieldSchemas = {};
    let nodeRelationshipsData;

    const fields =
      relationships.field_form_elements && relationships.field_form_elements.data
        ? relationships.field_form_elements.data
            .filter((fd) => typeof fd.attributes !== "undefined")
            .map((field_data) => {
              const req = field_data.attributes.field_form_elm_required === true;
              const label = `${field_data.attributes.title}${req ? " *" : ""}`;
              const field_name = field_data.attributes.field_form_elm_field_mapping
                ? field_data.attributes.field_form_elm_field_mapping
                : `field_${slugify(label).replaceAll("-", "_")}`;
              let ops_text =
                field_data.attributes.field_form_elm_select_ops_text !== null
                  ? field_data.attributes.field_form_elm_select_ops_text.split("\r\n")
                  : null;

              const is_options_from_text =
                field_data.attributes.field_form_elm_select_ops_text !== null;
              const is_relationship =
                field_data.attributes.field_form_elm_select_ops_gql !== null;
              const is_select = is_relationship || is_options_from_text;
              const kind = graphqlFieldsConfig[field_name]
                ? graphqlFieldsConfig[field_name].kind
                : null;

              const type = !is_select
                ? field_data.attributes.field_form_elm_data_type
                : null;
              const multiple =
                is_select && field_data.attributes.field_form_elm_is_multiple === true;

              const depends_on = field_data.attributes.field_form_elm_depends_on;
              const depends_on_val = field_data.attributes.field_form_elm_depends_on_val;

              const control_type = field_data.attributes.field_form_elm_control_type;
              const multiline = control_type === "textarea";

              const body = field_data.attributes.body
                ? {
                    value: field_data.attributes.body.value,
                    heading: field_data.attributes.body.summary,
                  }
                : null;

              return {
                id: field_data.id,
                label,
                type,
                kind,
                name: field_name,
                multiple,
                multiline,
                req,
                is_relationship,
                ops_text,
                depends_on,
                depends_on_val,
                body,
                control_type,
              };
            })
        : [];
    for (const field of fields) {
      initialValues[field.name] = field.options ? (field.multiple ? [] : null) : "";
      if (field.is_relationship) {
        relationshipFieldSchemas[field.name] = {
          multiple: field.multiple,
          kind: field.kind,
        };
      }
      if (field.req) {
        let _y = field.is_relationship
          ? yup.number().required(`${field.label} is required`)
          : yup
              .string()
              .when({
                is: () => field.type === "email",
                then: yup
                  .string()
                  .email("Invalid email address")
                  .required(`${field.label} is required`),
              })
              .when({
                is: () => field.type === "phone",
                then: yup
                  .string()
                  .min(10, "Invalid phone number")
                  .matches(
                    /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s-]?[\0-9]{3}[\s-]?[0-9]{4}$/g,
                    "Use only numbers"
                  )
                  .required(`${field.label} is required`),
                otherwise: yup
                  .string()
                  .min(2, `${field.label} invalid!!`)
                  .required(`${field.label} is required`),
              });

        let valSchema = null;

        if (field.depends_on !== null) {
          valSchema = _y.concat(
            yup[field.is_relationship ? "number" : "string"]().when(
              field.depends_on,
              (current_depends_on_val, schema) => {
                let val = false;
                if (Array.isArray(field.depends_on_val)) {
                  if (field.depends_on_val.length === 0) val = true;
                  if (field.depends_on_val.includes(current_depends_on_val)) val = true;
                }
                if (val) return schema.required("Field required");
                return schema.notRequired();
              }
            )
          );
        } else valSchema = _y;

        validation_config[field.name] = valSchema;
      }
    }
    const validationSchema = yup.object(validation_config);
    const relationshipFieldNames = Object.keys(relationshipFieldSchemas);
    nodeRelationshipsData = getNodeRelationshipsData({
      graphqlData,
      relationshipFieldNames,
    });
    for (const i in fields) {
      if (Object.hasOwnProperty.call(fields, i)) {
        if (fields[i].is_relationship && nodeRelationshipsData[fields[i].name]) {
          fields[i].options = nodeRelationshipsData[fields[i].name];
        } else if (Array.isArray(fields[i].ops_text) && fields[i].ops_text.length > 0) {
          fields[i].options = fields[i].ops_text.map((op_txt) => {
            const id = slugify(op_txt);
            const val = op_txt.includes("|") ? op_txt.split("|")[0] : op_txt;
            const title = op_txt.includes("|") ? op_txt.split("|")[1] : op_txt;
            return {
              val,
              title,
              id,
            };
          });
        }
      }
    }
    setFormConfig({
      fields,
      initialValues,
      relationshipFieldSchemas,
      validationSchema,
      relationshipFieldNames,
      nodeRelationshipsData,
    });
  }, [graphqlData, graphqlFieldsConfig, relationships]);

  if (!formConfig) {
    return <CircularProgress />;
  }

  return (
    <FormComponent
      attributes={attributes}
      relationships={relationships}
      {...formConfig}
    />
  );
}

export default Form;
