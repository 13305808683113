import React from "react";
import { Paper, Typography } from "@material-ui/core";
import { Container } from "../";
import makeStyles from "@material-ui/core/styles/makeStyles";
const useStyles = makeStyles((theme) => ({
  unauthorized: {
    margin: theme.spacing(3),
    padding: theme.spacing(3),
    textAlign: "center",
  },
}));

function Unauthorized() {
  const classes = useStyles();
  return (
    <Container>
      <Paper className={classes.unauthorized}>
        <Typography variant="h1">Unauthorized</Typography>
      </Paper>
    </Container>
  );
}

export default Unauthorized;
