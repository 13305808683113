import React from "react";
import { Blocks } from ".";
import { Column, Row } from "../../components";

function BlockRow({ relationships, fullWidth, anchorsData }) {
  if (
    relationships &&
    relationships.field_row_columns &&
    relationships.field_row_columns.data &&
    relationships.field_row_columns.data.length > 0
  ) {
    return (
      <Row>
        {relationships.field_row_columns.data.map((n) =>
          n.relationships &&
          n.relationships.field_column_blocks &&
          n.relationships.field_column_blocks.data &&
          n.relationships.field_column_blocks.data.length > 0 ? (
            <Column>
              <Blocks
                fullWidth={fullWidth}
                nodes={n.relationships.field_column_blocks.data}
                anchorsData={anchorsData}
              />
            </Column>
          ) : (
            <></>
          )
        )}
      </Row>
    );
  } else {
    return <></>;
  }
}

export default BlockRow;
