/* eslint-disable import/prefer-default-export, react/prop-types */
import "./src/index.scss";
import "./src/fontello/css/fontello.css";
import React from "react";
import TopLayout from "./src/layouts/TopLayout";
import PageWrap from "./src/layouts/PageWrap";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

export const wrapRootElement = ({ element }) => {
  return <TopLayout>{element}</TopLayout>;
};

export const wrapPageElement = ({ element, props }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_RECAPTCHA_SITE_KEY}>
      <PageWrap element={element} props={props} />
    </GoogleReCaptchaProvider>
  );
};
