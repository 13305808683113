import React, { useEffect } from "react";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Link } from "gatsby";

import makeStyles from "@material-ui/core/styles/makeStyles";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "white",
  },
}));

function TabNav({ data, path }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  //
  useEffect(() => {
    let tab_index = 0;
    for (const i in data) {
      if (path.includes(data[i].path)) {
        tab_index = parseInt(i);
        break;
      }
    }
    setValue(tab_index);
  }, [data, path]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        {data.map((item) => (
          <Tab key={item.title} label={item.title} component={Link} to={item.path} />
        ))}
      </Tabs>
    </div>
  );
}

export default TabNav;
