function findPathFromEntityId(nid, allNodeRefs) {
  let node_ref = allNodeRefs.find((item) => parseInt(item.nid) === parseInt(nid));
  if (node_ref) {
    return node_ref.slug;
  } else {
    return null;
  }
}

export default findPathFromEntityId;
