import React from "react";
import { Grid } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  gallery: {
    "& figure.image": {
      marginTop: 0,
    },
  },
}));

function Gallery({ children, spacing = 1, id, cols = null }) {
  const classes = useStyles();
  if (!Array.isArray(children)) {
    children = [children];
  }
  let col_w;
  if (cols === null) {
    col_w = 3;
    if (children.length <= 4) {
      switch (children.length) {
        case 4:
        case 2:
          col_w = 6;
          break;
        case 3:
          col_w = 4;
          break;
        default:
          col_w = 12;
          break;
      }
    } else {
      for (const c of [3, 4]) {
        if (!(children.length % c)) {
          col_w = 12 / c;
          break;
        }
      }
    }
  } else {
    col_w = 12 / cols;
  }
  return (
    <div className={classes.gallery}>
      <Grid container spacing={spacing}>
        {children.map((node, i) => (
          <Grid item md={col_w} sm={6} xs={6} key={`${id}-${i}`}>
            {node}
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default Gallery;
