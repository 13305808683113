import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Paper, Typography, Button, CircularProgress } from "@material-ui/core";
import {
  AuthContext,
  fetchAuthenticatedContent,
} from "@parallelpublicworks/understory-auth";
import Alert from "@material-ui/lab/Alert";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Field } from "../";
import { recaptchaSubmit, getNodeRelationshipsData, getInitialValues } from "../../utils";
import { SiteContext } from "../../layouts/Main";
import makeStyles from "@material-ui/core/styles/makeStyles";
const useStyles = makeStyles((theme) => ({
  login: {
    padding: theme.spacing(4, 0),
    maxWidth: 560,
    margin: "0 auto",
  },
  title: {
    textAlign: "center",
  },
  paper: {
    padding: theme.spacing(3),
    "& > *:not(:first-child)": {
      marginTop: `${theme.spacing(2)}px !important`,
      width: "100%",
    },
  },
  links: {
    textAlign: "center",
  },
}));

const validationSchema = yup.object({
  mail: yup.string().required("Email is required").email("Enter a valid email address"),
  field_name: yup.string().min(2, "Add a valid name").required("Add your name"),
  field_last_name: yup
    .string()
    .min(2, "Add a valid last name")
    .required("Add your last name"),
  field_job_title: yup
    .string()
    .min(2, "Add a valid job title")
    .required("Add your job title"),
  field_phone: yup
    .string()
    .min(10, "Add a valid phone number")
    .matches(
      /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s-]?[\0-9]{3}[\s-]?[0-9]{4}$/g,
      "Use only numbers"
    )
    .required("Add your work phone"),
  field_agency: yup
    .number()
    .typeError("Select your agency")
    .required("Select your agency"),
});

const relationshipFieldSchemas = {
  field_agency: { multiple: false, kind: "node" },
  field_work_group: { multiple: true, kind: "node" },
  field_expertise: { multiple: true, kind: "taxonomy_term" },
};
const relationshipFieldNames = Object.keys(relationshipFieldSchemas);

const unpatchableAttributes = ["mail"];

const mediaFieldSchemas = {
  field_image: {
    accept: ".jpg,.jpeg,.png,.gif",
    media_type: "media--image",
    maxSize: 2097152,
    file_field_name: "field_media_image",
  },
};
const mediaFieldNames = Object.keys(mediaFieldSchemas);

function Register({
  title,
  onSubmit,
  requestSent,
  graphqlData,
  info,
  submitLabel = "Register",
  method = "POST",
}) {
  const classes = useStyles();
  const { drupalUrl, userData, allowRegistration } = React.useContext(SiteContext);
  const [, dispatch] = React.useContext(AuthContext);

  const [error, setError] = React.useState(null);
  const [files, setFiles] = React.useState({});
  const nodeRelationshipsData = getNodeRelationshipsData({
    graphqlData,
    relationshipFieldNames,
  });
  const { executeRecaptcha } = useGoogleReCaptcha();
  const initial_schema = {
    mail: "",
    field_image: "",
    field_name: "",
    field_last_name: "",
    field_job_title: "",
    field_phone: "",
    field_agency: null,
    field_work_group: [],
    field_expertise: [],
  };
  const initialValues = getInitialValues({
    initialValues: initial_schema,
    userData,
    nodeRelationshipsData,
    mediaFieldNames,
    relationshipFieldNames,
    relationshipFieldSchemas,
  });
  async function saveFile(field_config, file) {
    let media_endpoint = field_config.media_type.replace("--", "/");
    let entityData = {
      data: {
        attributes: { name: file.name },
        type: field_config.media_type,
      },
    };
    try {
      let mediaResp = await fetchAuthenticatedContent(
        dispatch,
        media_endpoint,
        "post",
        entityData
      );
      if (mediaResp?.data?.id && file.blob) {
        // create file data
        let filePreBlob = await fetch(file.blob);

        let fileBlob = await filePreBlob.blob();
        let fileData = new File([fileBlob], file.name);
        // set relationship

        try {
          const creds = btoa(
            `${process.env.BASIC_AUTH_USERNAME}:${process.env.BASIC_AUTH_PASSWORD}`
          );
          let fileResp = await fetch(
            `${drupalUrl}/jsonapi/${media_endpoint}/${mediaResp.data.id}/${field_config.file_field_name}`,
            {
              method: "POST",
              headers: {
                Accept: "application/vnd.api+json",
                "Content-Type": "application/octet-stream",
                "Content-Disposition": `file; filename="${file.name}"`,
                Authorization: `Basic ${creds}`,
              },
              body: fileData,
            }
          );
          if (fileResp.status === 200) {
            return mediaResp.data.id;
          } else {
            throw new Error("Could not upload file blob");
          }

          // let fileResp = await fetchAuthenticatedContent(
          //   dispatch,
          //   `${media_endpoint}/${mediaResp.data.id}/${field_config.file_field_name}`,
          //   "POST",
          //   fileData,
          //   {
          //     Accept: "application/vnd.api+json",
          //     "Content-Type": "application/octet-stream",
          //     "Content-Disposition": `file; filename="${file.name}"`,
          //   }
          // );
          // if (fileResp) {
          //   return mediaResp.data.id;
          // } else {
          //   // return false;
          //   throw new Error("Could not upload file blob");
          // }
        } catch (err) {
          console.error("COULDNT FETCH BLOB", err.message);
          let mediaDeleteResp = await fetchAuthenticatedContent(
            dispatch,
            `${media_endpoint}/${mediaResp.data.id}`,
            "DELETE",
            {
              "Content-Type": "application/vnd.api+json",
            }
          );
          console.warn("deleting media ref", mediaResp.data, mediaDeleteResp);
          return false;
        }
      }
    } catch (error) {
      return false;
    }
  }

  const registerForm = useFormik({
    validationSchema,
    initialValues,
    onSubmit: async (values, { setSubmitting }) => {
      setError(null);
      const valid = await recaptchaSubmit(executeRecaptcha, drupalUrl);
      if (!valid) {
        setError("Failed Recaptcha");
      } else {
        const form_data = method === "POST" ? {} : { attributes: {}, relationships: {} };
        for (const [field, value] of Object.entries(values)) {
          if (mediaFieldNames.includes(field)) {
            if (method !== "POST") {
              const field_config = mediaFieldSchemas[field];
              if (typeof files[field] !== "undefined") {
                let media_uuid = await saveFile(field_config, files[field]);
                if (media_uuid) {
                  form_data.relationships[field] = {
                    data: { type: field_config.media_type, id: media_uuid },
                  };
                }
              }
            }
          } else if (value) {
            if (relationshipFieldNames.includes(field)) {
              if (method === "POST") {
                form_data[field] =
                  relationshipFieldSchemas[field].multiple && Array.isArray(value)
                    ? value.map((a_value) => ({
                        target_id: a_value,
                      }))
                    : { target_id: value };
              } else if (
                Array.isArray(nodeRelationshipsData[field]) &&
                nodeRelationshipsData[field].length > 0
              ) {
                const field_config = relationshipFieldSchemas[field];
                const identifier_num_key = field_config.kind === "node" ? "nid" : "tid";

                const rel_data_item = field_config.multiple
                  ? nodeRelationshipsData[field].filter((item) =>
                      value.includes(parseInt(item[identifier_num_key]))
                    )
                  : nodeRelationshipsData[field].find(
                      (item) => parseInt(item[identifier_num_key]) === parseInt(value)
                    );
                const rel_data =
                  field_config.multiple && rel_data_item.length > 0
                    ? rel_data_item.map((rel_d) => ({ id: rel_d.id, type: rel_d.type }))
                    : typeof rel_data_item !== "undefined"
                    ? { id: rel_data_item.id, type: rel_data_item.type }
                    : null;
                if (rel_data) {
                  form_data.relationships[field] = { data: rel_data };
                }
              }
            } else {
              if (method === "POST") {
                form_data[field] = { value };
              } else if (!unpatchableAttributes.includes(field)) {
                form_data.attributes[field] = value;
              }
            }
          }
        }
        if (method === "POST" && form_data.mail) {
          form_data.name = { value: `${form_data.mail.value}` };
        }
        await onSubmit(form_data, setSubmitting, setError);
      }
    },
  });

  if (!allowRegistration) {
    return <></>;
  }

  if (requestSent) {
    return (
      <div className={classes.login}>
        <Paper className={classes.paper}>
          <Alert severity="success">{requestSent}</Alert>
        </Paper>
      </div>
    );
  }
  return (
    <div className={classes.login}>
      <Paper
        className={classes.paper}
        component="form"
        onSubmit={registerForm.handleSubmit}
      >
        <Typography variant="h3" className={classes.title}>
          {title}
        </Typography>
        {typeof info !== "undefined" && !error && <Alert severity="info">{info}</Alert>}
        {error && <Alert severity="error">{error}</Alert>}
        {method !== "POST" && (
          <Field
            form={registerForm}
            label="Profile picture:"
            name="field_image"
            type="media"
            setFiles={setFiles}
            files={files}
            {...mediaFieldSchemas["field_image"]}
          />
        )}
        <Field
          form={registerForm}
          label="Email Address:"
          name="mail"
          type="email"
          disabled={method !== "POST"}
        />
        <Field form={registerForm} label="Name:" name="field_name" type="text" />
        <Field
          form={registerForm}
          label="Last Name:"
          name="field_last_name"
          type="text"
        />
        <Field
          form={registerForm}
          label="Job Title:"
          name="field_job_title"
          type="text"
        />
        <Field form={registerForm} label="Work Phone:" name="field_phone" type="text" />
        <Field
          form={registerForm}
          label="Agency:"
          name="field_agency"
          options={nodeRelationshipsData.field_agency}
        />
        <Field
          form={registerForm}
          label="Work Group (optional):"
          name="field_work_group"
          options={nodeRelationshipsData.field_work_group}
          multiple
        />
        <Field
          form={registerForm}
          label="Expertise:"
          name="field_expertise"
          options={nodeRelationshipsData.field_expertise}
          multiple
        />
        {registerForm.isSubmitting ? (
          <CircularProgress />
        ) : (
          <Button
            variant="contained"
            color="secondary"
            size="large"
            type="submit"
            disabled={registerForm.isSubmitting}
          >
            {submitLabel}
          </Button>
        )}
      </Paper>
    </div>
  );
}

export default Register;
