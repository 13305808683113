import React from "react";
import { Box, Paper } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
const useStyles = makeStyles((theme) => ({
  column: {
    "&:not(.full-width)": {
      "& > *:not(p)": {
        marginTop: theme.spacing(2),
        "&:last-child": {
          marginBottom: theme.spacing(4),
        },
      },

      "& > .MuiTypography-h2:not(:first-child)": {
        paddingTop: 0,
        marginTop: theme.spacing(4),
      },
      "& > .text.is-sidebar": {
        marginTop: theme.spacing(2),
        "& > *": {
          marginTop: theme.spacing(1),
        },
      },
      "& > p:not(first-child)": {
        marginTop: theme.spacing(1),
      },
      "& > time, & > strong, & > em": {
        display: "block",
      },
      "&.framed": {
        padding: theme.spacing(4),
        [theme.breakpoints.down("md")]: {
          padding: theme.spacing(2),
        },
        "& > *": {
          "&:last-child": {
            marginBottom: 0,
          },
        },
      },
    },
    "&.full-width": {
      "& > *": {
        marginBottom: theme.spacing(4),
      },
    },

    "&.sidebar-col": {
      "& > *:not(p)": {
        marginTop: theme.spacing(2),
      },
      "& .MuiListItem-gutters": {
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        "& > div": {
          "& > *": {
            marginTop: 0,
          },
        },
        "& time": {
          fontSize: "92%",
        },
        "& p": {
          fontSize: "92%",
          marginTop: `${theme.spacing(1)}px !important`,
        },
      },
    },
  },
}));

function Column({ children, framed, className, fullWidth }) {
  const classes = useStyles();
  return (
    <Box
      component={framed ? Paper : "div"}
      elevation={4}
      className={`${classes.column} ${framed ? "framed" : ""} ${className || ""} ${
        fullWidth ? "full-width" : ""
      }`}
    >
      {children}
    </Box>
  );
}

export default Column;
