import React from "react";
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import { WithSearch, SearchProvider, Sorting } from "@elastic/react-search-ui";
import Grid from "@material-ui/core/Grid";
import { grey } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import CloseIcon from "@material-ui/icons/Close";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import makeStyles from "@material-ui/core/styles/makeStyles";
import "@elastic/react-search-ui-views/lib/styles/styles.css";
import Card from "../excerpts/Card.js";
import ListEntities from "../containers/ListEntities.js";
import { Container } from "..";

const useStyles = makeStyles((theme) => ({
  filters: {
    // backgroundColor: grey["300"],
    // overflow: "hidden",
    marginTop: theme.spacing(3),
    padding: theme.spacing(2, 2, 1, 2),
    // paddingLeft: 0,
    // paddingRight: 0,
  },
  formControl: {
    width: "100%",
    margin: 0,
  },
  button: {
    height: "100%",
    width: "100%",
  },
  chip: {
    marginBottom: 3,
    marginTop: 3,
    marginRight: 6,
  },
  list: {
    marginBottom: theme.spacing(4),
    "& li": {
      borderBottom: `${grey["300"]} 1px solid`,
      [theme.breakpoints.down("sm")]: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(3),
      },
      "&:last-child": {
        borderBottom: "none",
      },
    },
  },
  row: {
    alignItems: "center",
    "& > div ": {
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        paddingTop: "0 !important",
        paddingBottom: "0 !important",
      },
    },
  },
  chipLabel: {
    display: "inline-block",
    paddingRight: 10,
  },
  searchResultItem: {
    padding: "20px 40px",
    margin: "20px auto",
  },
  searchResultItemTitle: {
    color: "rgba(0, 0, 0, 0.87)",
    display: "block",
    fontSize: "1.5em",
    marginBlockStart: "0.83em",
    marginBlockEnd: "0.83em",
    marginInlineStart: "0px",
    marginInlineEnd: "0px",
    fontWeight: "bold",
    textDecoration: "none",
  },
}));

function Search({ data, isPrivate }) {
  const classes = useStyles();

  const all_entities_arr =
    data.allEntityRef && data.allEntityRef.edges && data.allEntityRef.edges.length > 0
      ? data.allEntityRef.edges.map(({ node }) => ({ ...node }))
      : [];

  const all_entities = {};
  for (const an_entity of all_entities_arr) {
    all_entities[an_entity.id] = an_entity;
  }

  const engineName = isPrivate
    ? process.env.GATSBY_ELASTIC_KB_ENGINE_NAME
    : process.env.GATSBY_ELASTIC_ENGINE_NAME;

  if (
    !process.env.GATSBY_ELASTIC_SEARCH_KEY ||
    !process.env.GATSBY_ELASTIC_ENDPOINT_BASE ||
    !engineName
  ) {
    return <></>;
  }

  const connector = new AppSearchAPIConnector({
    searchKey: process.env.GATSBY_ELASTIC_SEARCH_KEY,
    engineName,
    endpointBase: process.env.GATSBY_ELASTIC_ENDPOINT_BASE,
    cacheResponses: false,
  });

  const config = {
    apiConnector: connector,
    initialState: {
      resultsPerPage: 1000,
    },
    searchQuery: {
      disjunctiveFacets: [],
      disjunctiveFacetsAnalyticsTags: ["Ignore"],
      search_fields: {
        title: {},
        files: {},
        field_section_main: {},
        field_summary: {},
      },

      result_fields: {
        title: {
          raw: {},
        },
        type: {
          raw: {},
        },
        field_summary: {
          raw: {},
        },
        files: {
          raw: {},
        },
        changed: {
          raw: {},
        },
      },
      facets: {},
    },
    hasA11yNotifications: true,
    a11yNotificationMessages: {
      searchResults: ({ start, end, totalResults, searchTerm }) =>
        `Searching for "${searchTerm}". Showing ${start} to ${end} results out of ${totalResults}.`,
    },
    alwaysSearchOnInitialLoad: true,
  };

  const SortSelect = ({ onChange, options }) => {
    return (
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Sort By</InputLabel>
        <Select id="sort-by" label="Sort By" onChange={(o) => onChange(o.target.value)}>
          {options.map(({ label, value }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };
  /* eslint-disable jsx-a11y/no-autofocus */
  return (
    <SearchProvider config={config}>
      <Container root>
        <WithSearch
          mapContextToProps={({ searchTerm, setSearchTerm, results }) => ({
            searchTerm,
            setSearchTerm,
            results,
          })}
        >
          {({ searchTerm, setSearchTerm, results }) => {
            const nodes = results
              .filter((r) => r?.id?.raw && typeof all_entities[r.id.raw] !== "undefined")
              .map((r) => ({
                ...all_entities[r.id.raw],
                title: r.title.raw,
                summary: r.field_summary ? r.field_summary.raw : "",
                changed: r.changed.raw,
              }));
            return (
              <div>
                <form noValidate autoComplete="off" className={classes.filters}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={8}>
                      <FormControl variant="outlined" className={classes.formControl}>
                        <OutlinedInput
                          placeholder="Search..."
                          type="text"
                          name="q"
                          variant="outlined"
                          autoFocus
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                          endAdornment={
                            <InputAdornment position="end">
                              {searchTerm.length > 0 && (
                                <IconButton
                                  aria-label="clear search query"
                                  onClick={(e) => setSearchTerm("")}
                                >
                                  <CloseIcon />
                                </IconButton>
                              )}
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Sorting
                        view={SortSelect}
                        className={classes.formControl}
                        sortOptions={[
                          {
                            name: "Relevance",
                            value: "",
                            direction: "",
                          },
                          {
                            name: "Title",
                            value: "title",
                            direction: "asc",
                          },
                          {
                            name: "Date Updated",
                            value: "changed",
                            direction: "desc",
                          },
                        ]}
                      />
                    </Grid>
                  </Grid>
                </form>
                <ListEntities
                  className={classes.list}
                  tableMode={true}
                  filters={false}
                  limit={12}
                  paginationMode="numbers"
                  hideNoResultsNotice
                >
                  {nodes.map((node) => {
                    return (
                      <Card
                        key={node.id}
                        id={node.id}
                        title={node.title}
                        slug={node.slug}
                        summary={node.summary}
                        tableMode={true}
                        showLink={true}
                      />
                    );
                  })}
                </ListEntities>
                <br />
              </div>
            );
          }}
        </WithSearch>
      </Container>
    </SearchProvider>
  );
  /* eslint-disable jsx-a11y/no-autofocus */
}

export default Search;
