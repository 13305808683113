import { Button } from "@material-ui/core";
import React from "react";
import { SiteContext } from "../../layouts/Main";
import EmailIcon from "@material-ui/icons/Email";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  contact_button: {
    width: "100%",
  },
}));

function ContactButton({ label }) {
  const classes = useStyles();
  const { setContactModalOpen, setContactModalTitle } = React.useContext(SiteContext);
  return (
    <Button
      variant="contained"
      color="primary"
      size="large"
      className={classes.contact_button}
      onClick={(e) => {
        setContactModalOpen(true);
        setContactModalTitle(label);
      }}
      startIcon={<EmailIcon />}
    >
      {label}
    </Button>
  );
}

export default ContactButton;
