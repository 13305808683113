import React from "react";
import Accordion from "@material-ui/core/Accordion";
import Typography from "@material-ui/core/Typography";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { slugify } from "../../utils";
import { Spacer, Column } from "../";
import makeStyles from "@material-ui/core/styles/makeStyles";
const useStyles = makeStyles((theme) => ({
  accordionHeader: {
    "& p": {
      fontWeight: theme.typography.fontWeight,
      fontSize: "1.3em",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.2em",
      },
    },
  },
  accordionTab: {
    backgroundColor: theme.palette.grey.dark,
    "&.Mui-expanded": {
      marginBottom: 2,
    },
    "& .MuiAccordionDetails-root": {
      paddingTop: 0,
    },
    "& + .MuiAccordion-root": {
      marginTop: 2,
    },
  },
  accordionDetails: {
    display: "block",
    padding: theme.spacing(3, 4),
    backgroundColor: theme.palette.grey.light,
  },
}));

function AccordionTab({ id, title, children }) {
  const classes = useStyles();
  id = id || slugify(title);
  return (
    <Accordion key={id} id={id} className={classes.accordionTab}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${id}a-content`}
        id={`panel${id}a-header`}
        className={classes.accordionHeader}
      >
        <Typography variant="h5">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <Spacer amount={3} />
        <Column>{children}</Column>
      </AccordionDetails>
    </Accordion>
  );
}

export default AccordionTab;
