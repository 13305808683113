module.exports = function getTitleFromEntity(node, config) {
  let title = null;
  if (node.attributes) {
    if (config && config.slugTitleField) {
      title = node.attributes[config.slugTitleField]
        ? node.attributes[config.slugTitleField]
        : null;
    }
    if (!title) {
      title = node.attributes.title
        ? node.attributes.title
        : node.attributes.name
        ? node.attributes.name
        : null;
    }
  } else if (node.title) {
    title = node.title;
  }
  return title;
};
