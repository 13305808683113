import React from "react";
import { Paper } from "@material-ui/core";
import { Heading, MoreButton } from "../";
import { slugify } from "../../utils";
import makeStyles from "@material-ui/core/styles/makeStyles";
import theme from "../../../theme";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 0),
    "& > *": {
      margin: theme.spacing(1, 2),
      "& + *": {
        marginTop: theme.spacing(2),
      },
    },
  },
}));

function Highlight({ title, to, toLabel, children }) {
  const classes = useStyles();
  return (
    <Paper
      component="aside"
      style={{ backgroundColor: theme.palette.grey.dark }}
      className={classes.root}
    >
      <Heading variant="h2" id={slugify(title)}>
        {title}
      </Heading>
      {children}
      {to && <MoreButton to={to} toLabel={toLabel} block variant="contained" />}
    </Paper>
  );
}

export default Highlight;
