import React from "react";
import { Pill, Pills } from "../../components";

function RelationshipsDisplay({
  attributes,
  relationships,
  contentTypes,
  taxonomies,
  isSidebar,
}) {
  const style = attributes?.field_style ? attributes.field_style : "pills";
  const rel_kind =
    typeof relationships?.field_content_type?.data === "string"
      ? "content"
      : typeof relationships?.field_taxonomy?.data === "string"
      ? "taxonomy"
      : null;

  const rel_type =
    rel_kind === "content"
      ? relationships.field_content_type.data
      : rel_kind === "taxonomy"
      ? relationships.field_taxonomy.data
      : null;
  const rel_items =
    rel_kind === "content" && Array.isArray(contentTypes[rel_type])
      ? contentTypes[rel_type]
      : rel_kind === "taxonomy" && Array.isArray(taxonomies[rel_type])
      ? taxonomies[rel_type]
      : [];
  if (rel_items.length > 0) {
    return (
      <Pills style={style}>
        {rel_items.map((r, i) => (
          <Pill
            key={r.id}
            {...r}
            showLink={typeof r.slug === "string"}
            block={isSidebar}
            style={style}
            isLastChild={i === rel_items.length - 1}
          />
        ))}
      </Pills>
    );
  } else {
    return <></>;
  }
}

export default RelationshipsDisplay;
