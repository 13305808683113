module.exports = function findEntityColor(e, entities) {
  let color = null;
  if (e) {
    if (e.attributes && e.attributes.field_color) {
      color = e.attributes.field_color;
    } else if (e.relationships) {
      for (let [rel_field_name, { data }] of Object.entries(e.relationships)) {
        if (rel_field_name.includes("field")) {
          let rel_data = Array.isArray(data) ? data : !!data ? [data] : [];
          if (Array.isArray(rel_data) && rel_data.length > 0) {
            rel_data = rel_data.filter((r) => typeof entities[r.id] !== "undefined");
            if (rel_data.length > 0) {
              for (const r of rel_data) {
                const _e = entities[r.id];
                if (_e && _e.attributes && _e.attributes.field_color) {
                  color = _e.attributes.field_color;
                  break;
                }
              }
            }
          }
        }
      }
      if (
        !color &&
        e.relationships.field_section_main &&
        e.relationships.field_section_main.data
      ) {
        const list_meta = e.relationships.field_section_main.data.find(
          (b) => b.type === "node--entities_list"
        );
        if (list_meta && typeof entities[list_meta.id] !== "undefined") {
          const list = entities[list_meta.id];
          if (
            list.relationships &&
            list.relationships.field_entities_list_by_terms &&
            Array.isArray(list.relationships.field_entities_list_by_terms.data) &&
            list.relationships.field_entities_list_by_terms.data.length > 0
          ) {
            const term_meta = list.relationships.field_entities_list_by_terms.data[0];
            if (typeof entities[term_meta.id] !== "undefined") {
              const term = entities[term_meta.id];
              if (term.attributes && term.attributes.field_color) {
                color = term.attributes.field_color;
              }
            }
          }
        }
      }
    }
  }
  return color;
};
