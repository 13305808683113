import React from "react";
import { Typography } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import theme from "../../../theme";
import { SingleContext } from "../../layouts/SingleBody";

const useStyles = makeStyles((theme) => ({
  underline: {
    display: "block",
    maxWidth: theme.spacing(7),
    height: theme.spacing(0.5),
    backgroundColor: (props) => props.underline,
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
}));

function Heading({ type = "h2", children, variant, id = null, isSidebar }) {
  variant = variant || type;
  const { singleColor } = React.useContext(SingleContext);
  const underline =
    type === "h1" && singleColor && theme.palette[singleColor]
      ? theme.palette[singleColor].main
      : null;
  const classes = useStyles({
    underline,
  });
  function Wrapper({ children }) {
    return type === "h1" ? <header>{children}</header> : <>{children}</>;
  }
  return (
    <Wrapper>
      <Typography
        variant={variant}
        component={type}
        id={id}
        className={isSidebar ? "is-sidebar" : ""}
      >
        {children}
        {underline && <b className={classes.underline}></b>}
      </Typography>
    </Wrapper>
  );
}

export default Heading;
