const GATSBY_BACKEND_URL = process.env.GATSBY_BACKEND_URL;

function getPubMedPublications(term) {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await fetch(
        `${GATSBY_BACKEND_URL}/pubmed/${encodeURIComponent(term)}?_format=json`
      );
      let { data: html } = await response.json();
      resolve(html);
    } catch (e) {
      console.warn(e);
      resolve(false);
    }
  });
}

export default getPubMedPublications;
