import { extractFileData } from "./";

function extractMediaData(node, media_field_name, file_field_name, drupalUrl) {
  if (node && media_field_name && file_field_name && drupalUrl) {
    const media_rel =
      node && node.relationships && node.relationships[media_field_name]
        ? node.relationships[media_field_name].data
        : null;
    const file_data = media_rel
      ? extractFileData(media_rel, file_field_name, drupalUrl)
      : null;
    return file_data;
  } else {
    return null;
  }
}
export default extractMediaData;
