import React from "react";
import { Block, BlockList, BlockRow, RelationshipsDisplay } from "./";
import { Row, TitleBar } from "../../components";

function Blocks({
  nodes,
  fullWidth,
  listedEntities,
  isSidebar,
  contentTypes,
  taxonomies,
  anchorsData,
}) {
  if (!nodes) return <></>;
  return (
    <>
      {nodes.map((n) => {
        switch (n.type) {
          case "node--call_to_action":
          case "node--banner_image":
            return (
              <Block key={n.id} {...n} fullWidth={fullWidth} isSidebar={isSidebar} />
            );

          case "node--heading":
            return fullWidth ? (
              <TitleBar
                pageTitle={n.title}
                align="center"
                headingType={n.attributes.field_heading_type}
                fullWidth={fullWidth}
              />
            ) : (
              <Block key={n.id} {...n} fullWidth={fullWidth} isSidebar={isSidebar} />
            );

          case "node--text":
          case "node--spacer":
          case "node--image":
          case "node--video":
          case "node--gallery":
          case "node--resources":
          case "node--contact_card":
          case "node--buttons":
          case "node--media_upload":
          case "node--accordion_tab":
          case "node--highlight":
          case "node--anchor_links":
          case "node--embed_code":
          case "node--form":
          case "node--contact_button":
          case "node--pubmed":
            let data;
            if (n.type === "node--anchor_links") {
              data = anchorsData;
            }
            return fullWidth ? (
              <Row fullWidth={true}>
                <Block
                  key={n.id}
                  {...n}
                  fullWidth={fullWidth}
                  isSidebar={isSidebar}
                  data={data}
                />
              </Row>
            ) : (
              <Block
                key={n.id}
                {...n}
                fullWidth={fullWidth}
                isSidebar={isSidebar}
                data={data}
              />
            );
          case "node--row":
            return (
              <BlockRow
                fullWidth={fullWidth}
                key={n.id}
                {...n}
                anchorsData={anchorsData}
              />
            );
          case "node--entities_list":
            return listedEntities ? (
              <BlockList
                key={n.id}
                {...n}
                fullWidth={fullWidth}
                listedEntities={listedEntities}
                isSidebar={isSidebar}
              />
            ) : (
              <></>
            );
          case "node--relationships_display":
            return (
              <RelationshipsDisplay
                key={n.id}
                {...n}
                contentTypes={contentTypes}
                taxonomies={taxonomies}
                isSidebar={isSidebar}
              />
            );
          default:
            return <></>;
        }
      })}
    </>
  );
}

export default Blocks;
