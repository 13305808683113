module.exports = function flattenNodes(
  data,
  fields = ["nid", "slug"],
  returnAs = "array"
) {
  const edges = data && typeof data.edges !== "undefined" ? data.edges : null;
  let flatten = returnAs === "array" ? [] : {};
  if (edges && edges.length > 0) {
    for (const { node } of edges) {
      const obj = {};
      for (const field_name of fields) {
        obj[field_name] = node[field_name];
      }
      if (returnAs === "array") {
        flatten.push(obj);
      } else {
        flatten[node.id] = obj;
      }
    }
  }
  return flatten;
};
