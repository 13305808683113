import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { SiteContext } from "../../layouts/Main";
const useStyles = makeStyles((theme) => ({
  text: {
    "& img": {
      "&.align-left": {
        float: "left",
        marginRight: theme.spacing(3),
        marginBottom: theme.spacing(3),
      },
      "&.align-right": {
        float: "right",
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(3),
      },
      "&.align-center": {
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    "& p, & li": {
      fontSize: "1.2em",
      "&.center": {
        textAlign: "center",
      },
    },
    "&.line-height-lg": {
      "& > *": {
        margin: theme.spacing(3, 0, 0, 0),
        "&:first-child": {
          marginTop: 0,
        },
      },
    },
    "&.line-height-sm": {
      "& > *": {
        margin: theme.spacing(1, 0, 0, 0),
        "&:first-child": {
          marginTop: 0,
        },
      },
    },
    "& ul, & ol, & blockquote": {
      "& > *": {
        margin: theme.spacing(1, 0, 0, 0),
        "&:first-child": {
          marginTop: 0,
        },
      },
    },
    "& a": {
      color: theme.palette.link.main,
      "&:hover, &:active, &:focus": {
        color: theme.palette.link.hover,
        textDecoration: "none",
      },
    },
    "& blockquote": {
      fontSize: "1.2em",
      backgroundColor: theme.palette.grey.lighter,
      padding: theme.spacing(4, 5),
      borderLeft: `${theme.palette.grey.light} ${theme.spacing(2)}px solid`,
    },
    "& .table-responsive": {
      overflowX: "auto",
      "-webkit-overflow-scrolling": "touch",
      "& table": {
        width: "100%",
        marginBottom: theme.spacing(1),
        verticalAlign: "middle",
        borderColor: theme.palette.grey["200"],
        borderCollapse: "collapse",
        "& > caption": {
          textAlign: "left",
          fontWeight: theme.typography.fontWeightBold,
          color: theme.palette.grey["500"],
          marginBottom: theme.spacing(1),
        },
        "& > :not(caption) > * > *": {
          padding: theme.spacing(2, 2),
          backgroundColor: "white",
          borderBottomWidth: 2,
          borderBottomColor: theme.palette.grey["200"],
          borderBottomStyle: "solid",
        },
        "& > :not(caption) > * > th": {
          backgroundColor: theme.palette.grey["100"],
        },
        "& > tbody": {
          verticalAlign: "inherit",
        },
        "& > thead": {
          verticalAlign: "bottom",
        },
        "& > :not(:first-child)": {
          borderTop: `(4) solid ${theme.palette.grey["500"]}`,
        },
      },
    },
  },
}));

function Text({ html, lineHeight = "lg", isSidebar }) {
  const classes = useStyles();
  const { drupalUrl } = React.useContext(SiteContext);
  html = html
    .replaceAll("<table>", '<div class="table-responsive"><table>')
    .replaceAll("</table>", "</table></div>")
    .replaceAll("<p><img", `<p class="center"><img`)
    .replaceAll('src="/sites', `src="${drupalUrl}/sites`);
  return (
    <div
      className={`${classes.text} line-height-${lineHeight} text ${
        isSidebar ? "is-sidebar" : ""
      }`}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
}

export default Text;
