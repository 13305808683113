import React from "react";
import { Box } from "@material-ui/core";
import { Container } from "../";
import makeStyles from "@material-ui/core/styles/makeStyles";
const useStyles = makeStyles((theme) => ({
  section: {
    "& + .section": {
      marginTop: theme.spacing(4),
    },
  },
}));

function Section({ fullWidth, children }) {
  const classes = useStyles();
  let attrs = {};

  function Wrapper({ children }) {
    if (fullWidth) {
      return <>{children}</>;
    } else {
      return <Container root>{children}</Container>;
    }
  }

  return (
    <Box className={`${classes.section} section`} {...attrs}>
      <Wrapper>{children}</Wrapper>
    </Box>
  );
}

export default Section;
