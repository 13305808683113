import React, { useEffect, useState, useRef } from "react";
import { CircularProgress } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { getPubMedPublications } from "../../utils";

function PubMed({ searchTerm }) {
  const [publications, setPublications] = useState("<div/>");
  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    setLoading(true);
    
    let publications = await getPubMedPublications(searchTerm);
    debugger;
    if (typeof(publications) == "string") {
      setPublications(publications);
    } else {
      console.warn(publications);
      setPublications('<p style="text-align:center">There was an error fetching the publications.</p>');
    }

    setLoading(false);
  }, []);

  if (!searchTerm) return <></>;

  if (loading) return <CircularProgress />;

  return <div dangerouslySetInnerHTML={{ __html: publications }} />;
}

export default PubMed;
