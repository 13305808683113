import React, { useContext } from "react";
import { Avatar, Grid, makeStyles, Typography } from "@material-ui/core";
import {
  Section,
  Spacer,
  Column,
  Heading,
  Breadcrumbs,
  NextPrevNav,
  Seo,
  ContactModal,
} from "../components";
import { HeadingSection, Blocks, SidebarSection } from "./containers";
import { extractDatesLabel, extractAnchorsData, extractFileData } from "../utils";
import { SiteContext } from "./Main";

const useStyles = makeStyles((theme) => ({
  single_title: {
    fontSize: "1rem",
    "&.has-avatar": {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
    },
  },
  avatar: {
    width: theme.spacing(16),
    height: theme.spacing(16),
    marginRight: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2),
    },
    "&.pic-format-square": {
      borderRadius: 0,
    },
  },
}));

export const SingleContext = React.createContext();

function SingleBody(props) {
  const {
    id,
    attributes,
    title,
    title_append,
    image,
    parent,
    relationships,
    listedEntities,
    slug,
    content_types,
    taxonomies,
    type,
    next,
    prev,
    doSEO,
    isPrivate,
    color,
    pic_format = "rounded",
  } = props;
  const nid = attributes.drupal_internal__nid;
  const classes = useStyles();
  const isHome = slug === "/";
  const { drupalUrl, nodesConfig } = useContext(SiteContext);
  const config = nodesConfig.find((cn) => cn.type === type);
  let seo_data;
  if (doSEO) {
    seo_data = {
      title,
      article: type !== "node--page",
    };
    if (attributes.field_summary) {
      seo_data.description = attributes.field_summary;
    }
    if (image && image.src) {
      seo_data.image = image.src;
    }
  }

  const hasMainSection =
    relationships &&
    relationships.field_section_main &&
    relationships.field_section_main.data.length > 0;

  const hasClosingContent =
    relationships &&
    relationships.field_section_closing &&
    relationships.field_section_closing.data.length > 0;

  let anchorsData = [];
  if (hasMainSection) {
    anchorsData = extractAnchorsData(relationships.field_section_main.data);
  }
  if (hasClosingContent) {
    anchorsData = anchorsData.concat(
      extractAnchorsData(relationships.field_section_closing.data)
    );
  }

  let breadcrumbs = [];
  if (parent) {
    function getCrumb(_p) {
      if (_p.parent) {
        breadcrumbs.push(getCrumb(_p.parent));
      }
      return {
        id: _p.id,
        slug: _p.slug,
        title: _p.title,
      };
    }
    breadcrumbs.push(getCrumb(parent));
  }

  if (!isPrivate) {
    breadcrumbs.unshift({
      id: "home",
      slug: "/",
      title: "Home",
    });
  }

  const hasSidebar =
    relationships &&
    relationships.field_section_sidebar &&
    ((!Array.isArray(relationships.field_section_sidebar.data) &&
      relationships.field_section_sidebar.data !== null) ||
      (Array.isArray(relationships.field_section_sidebar.data) &&
        relationships.field_section_sidebar.data.length > 0));

  const sidebarSize =
    attributes && attributes.field_section_sidebar_size
      ? attributes.field_section_sidebar_size
      : "small";

  let sidebarCols;
  switch (sidebarSize) {
    case "large":
      sidebarCols = 5;
      break;
    case "medium":
      sidebarCols = 4;
      break;
    default:
      sidebarCols = 3;
      break;
  }

  let mainCols;
  switch (sidebarSize) {
    case "large":
      mainCols = 7;
      break;
    case "medium":
      mainCols = 8;
      break;
    default:
      mainCols = 9;
      break;
  }

  let dates = attributes && config && config.showDate ? [attributes.created] : null;
  if (dates && attributes.field_start_date) {
    dates = [attributes.field_start_date];
    if (attributes.field_end_date) {
      dates.push(attributes.field_end_date);
    }
  }
  const dates_label = dates ? extractDatesLabel(dates) : "";

  const has_avatar_config = config?.options?.single_page?.field_image === "avatar";
  const image_data =
    config?.options?.single_page?.field_image && relationships?.field_image?.data
      ? extractFileData(relationships.field_image.data, "field_media_image", drupalUrl)
      : null;

  const lead_email =
    attributes && attributes.field_lead_email ? attributes.field_lead_email : null;

  const bannerNode =
    relationships && relationships.field_heading_banner
      ? relationships.field_heading_banner.data
      : null;

  return (
    <SingleContext.Provider
      value={{
        singleId: id,
        singleNid: nid,
        singleType: type,
        singleTitle: title,
        singleSlug: slug,
        singleLeadEmail: lead_email,
        singleColor: color,
        mainRelationships: relationships,
      }}
    >
      {seo_data && <Seo {...seo_data} />}
      {/* Heading Section */}
      {(!isHome || (isHome && bannerNode !== null)) && (
        <>
          <HeadingSection
            title={title}
            parent={parent}
            bannerNode={bannerNode}
            drupalUrl={drupalUrl}
          />
        </>
      )}
      {/* Main Section */}
      {(hasMainSection || hasSidebar) && (
        <>
          <Spacer />
          <Section>
            <Grid container spacing={hasSidebar ? 4 : 0}>
              {hasMainSection && (
                <Grid item lg={hasSidebar ? mainCols : 12}>
                  <Column framed>
                    {breadcrumbs && breadcrumbs.length > 0 && (
                      <Breadcrumbs current={title} ancestors={breadcrumbs} />
                    )}
                    {(parent || has_avatar_config) && (
                      <div
                        className={`${classes.single_title} ${
                          has_avatar_config ? "has-avatar" : ""
                        }`}
                      >
                        {has_avatar_config && (
                          <Avatar
                            src={image_data ? image_data.src : null}
                            className={`${classes.avatar} pic-format-${pic_format}`}
                          />
                        )}
                        <div>
                          <Heading type="h1">
                            {title}
                            {title_append && <span>{`, ${title_append}`}</span>}
                          </Heading>
                          {attributes.field_subtitle && (
                            <Typography component="strong">
                              {attributes.field_subtitle}
                            </Typography>
                          )}
                        </div>
                      </div>
                    )}
                    {dates_label.length > 0 && (
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        component="time"
                      >
                        {dates_label}
                      </Typography>
                    )}
                    <Blocks
                      nodes={
                        relationships && relationships.field_section_main
                          ? relationships.field_section_main.data
                          : null
                      }
                      listedEntities={listedEntities}
                      contentTypes={content_types}
                      taxonomies={taxonomies}
                      anchorsData={anchorsData}
                    />
                    <NextPrevNav prev={prev} next={next} />
                  </Column>
                </Grid>
              )}
              {hasSidebar && (
                <Grid item lg={sidebarCols}>
                  <Column className="sidebar-col">
                    <SidebarSection
                      nodes={relationships.field_section_sidebar.data}
                      contentTypes={content_types}
                      taxonomies={taxonomies}
                      listedEntities={listedEntities}
                    />
                  </Column>
                </Grid>
              )}
            </Grid>
          </Section>
          <Spacer />
        </>
      )}

      {/* Closing Section */}
      {hasClosingContent && (
        <>
          <Section fullWidth>
            <Column fullWidth>
              <Blocks
                fullWidth
                nodes={relationships.field_section_closing.data}
                listedEntities={listedEntities}
                contentTypes={content_types}
                taxonomies={taxonomies}
              />
            </Column>
          </Section>
        </>
      )}
      <ContactModal />
    </SingleContext.Provider>
  );
}

export default SingleBody;
