function extractLinkData(field_data) {
  const to = field_data?.to ? field_data.to : field_data?.uri ? field_data.uri : null;
  if (to) {
    const label =
      field_data?.title &&
      typeof field_data.title === "string" &&
      field_data.title.length > 0
        ? field_data.title
        : "Learn more";
    return {
      label,
      to,
    };
  } else {
    return null;
  }
}
export default extractLinkData;
