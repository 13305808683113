import React from "react";
import { Link } from "gatsby";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { excerptify } from "../../utils";
import makeStyles from "@material-ui/core/styles/makeStyles";
const useStyles = makeStyles((theme) => ({
  nextPrev: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    "& > *": {
      width: "48%",
      "&:last-child": {
        justifyContent: "flex-end",
      },
    },
    "& a": {
      display: "flex",
      alignItems: "center",
      color: theme.palette.link.main,
      "& span": {
        display: "block",
        padding: theme.spacing(0, 2),
      },
      "&:hover, &:active, &:focus": {
        color: theme.palette.link.hover,
        textDecoration: "none",
      },
    },
  },
}));

function NextPrevNav({ prev, next }) {
  const classes = useStyles();
  if (!prev && !next) return <></>;
  return (
    <nav className={classes.nextPrev}>
      {prev ? (
        <Link to={prev.slug}>
          <ArrowBackIcon /> <span>{excerptify(prev.title, 40)}</span>
        </Link>
      ) : (
        <span></span>
      )}
      {next ? (
        <Link to={next.slug}>
          <span>{excerptify(next.title, 40)}</span> <ArrowForwardIcon />
        </Link>
      ) : (
        <span></span>
      )}
    </nav>
  );
}

export default NextPrevNav;
